// client\src\components\2 - Components\ProjectsViewTaskView\ProjectsTaskView.js
import React, { useContext, useState, useCallback } from "react";
import {
  Box,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Grid,
  Button,
  HStack,
  IconButton,
  Spinner,
  Tooltip,
  useEditableControls,
  Input,
} from "@chakra-ui/react";
import TaskViewDetails from "./ProjectsTaskViewDetails";
import TaskViewTabs from "./ProjectsTaskViewTabs";
import CreateTaskModal from "../../3 - Modal/3 - Projects Modals/ProjectsCreateTaskModal";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import TagMenu from "../Reusable/TagMenu";
import { PiCheckBold, PiPlus, PiXBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const TaskView = () => {
  const { currentTaskId, tasks, editTask } = useContext(ProjectContext);
  const [isCreateSubtaskModalOpen, setIsCreateSubtaskModalOpen] = useState(false);
  const task = tasks.find((task) => task._id === currentTaskId);
  const navigate = useNavigate();

  // **Handle Subtask Click**
  const handleSubtaskClick = useCallback(
    (subtask) => {
      navigate(`/dashboard/projects/${task.projectId}/${task._id}/${subtask._id}`);
    },
    [navigate, task]
  );

  // **Handle Add Subtask**
  const handleAddSubtask = useCallback(() => {
    setIsCreateSubtaskModalOpen(true);
  }, []);

  // **Handle Close Subtask Modal**
  const handleCreateSubtaskModalClose = useCallback(() => {
    setIsCreateSubtaskModalOpen(false);
  }, []);

  // **Handle Task Field Change**
  const handleTaskChange = useCallback(
    (field, value) => {
      if (field && value) {
        editTask(task._id, { [field]: value });
      }
    },
    [task, editTask]
  );

  const onStatusChange = (taskId, field, value) => {
    editTask(taskId, { [field]: value });
  };

  const onPriorityChange = (taskId, field, value) => {
    editTask(taskId, { [field]: value });
  };

  const statusOptions = [
    { value: "Not Started", label: "Not Started", color: "gray" },
    { value: "In Progress", label: "In Progress", color: "blue" },
    { value: "Completed", label: "Completed", color: "green" },
    { value: "On Hold", label: "On Hold", color: "orange" },
  ];

  const priorityOptions = [
    { value: "High", label: "High", color: "red" },
    { value: "Medium", label: "Medium", color: "yellow" },
    { value: "Low", label: "Low", color: "green" },
  ];

  function EditableControls() {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="end" size="sm" spacing={2}>
        <IconButton icon={<PiCheckBold fontSize="18px" />} {...getSubmitButtonProps()} />
        <IconButton icon={<PiXBold fontSize="18px" />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : null;
  }

  if (!task) {
    return (
      <Flex justify="center" align="center" w="100%" height="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <>
      <Box width="100%" h="100%" display="flex" flexDirection="column" overflow="hidden">
        <Grid h="100%" templateColumns={"3fr 1fr"} gap={6} overflow="hidden">
          {/* Left Section: Task Details and Tabs */}
          <Flex borderRight="1px solid" borderColor="gray.200" flexDir="column" height="100%" overflow="hidden">
            <HStack m={6} minH="60px" spacing={4} alignItems="center" justify="space-between">
              <Editable
                defaultValue={task.name}
                isPreviewFocusable={true}
                selectAllOnFocus={false}
                onSubmit={(nextValue) => handleTaskChange("name", nextValue)}
              >
                <Tooltip label="Click to edit" shouldWrapChildren={true}>
                  <Flex align="center">
                    <EditablePreview
                      py={2}
                      px={4}
                      borderRadius="xl"
                      fontSize="3xl"
                      fontWeight="700"
                      _hover={{
                        background: "gray.100",
                      }}
                    />
                    <Flex h="fit-content" align="center">
                      <Input py={2} px={4} mr={2} as={EditableInput} fontSize="3xl" fontWeight="700" />
                      <EditableControls />
                    </Flex>
                  </Flex>
                </Tooltip>
              </Editable>
              <HStack spacing={4}>
                {/* Status */}
                <Box>
                  <TagMenu options={statusOptions} selectedValue={task.status} field="status" handleBlurSave={onStatusChange} taskId={task._id} />
                </Box>

                {/* Priority */}
                <Box>
                  <TagMenu
                    options={priorityOptions}
                    selectedValue={task.priority}
                    field="priority"
                    handleBlurSave={onPriorityChange}
                    taskId={task._id}
                  />
                </Box>
                <Box p={4} display="flex" justifyContent="flex-end">
                  <Button leftIcon={<PiPlus fontSize="18px" />} colorScheme="primary" variant="outline" onClick={handleAddSubtask}>
                    Create Subtask
                  </Button>
                </Box>
              </HStack>
            </HStack>
            <Box flex="1" overflow="hidden">
              <TaskViewTabs task={task} onSubtaskClick={handleSubtaskClick} onAddSubtask={handleAddSubtask} />
            </Box>
          </Flex>

          {/* Right Section: Task Statistics */}
          <Box overflowY="auto">
            <TaskViewDetails task={task} handleTaskChange={handleTaskChange} />
          </Box>
        </Grid>
      </Box>

      {/* Create Subtask Modal */}
      <CreateTaskModal isOpen={isCreateSubtaskModalOpen} onClose={handleCreateSubtaskModalClose} projectId={task.projectId} parentTaskId={task._id} />
    </>
  );
};

export default TaskView;
