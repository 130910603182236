// client/src/components/Files/Dashboard.jsx

import {
  Box,
  SimpleGrid,
  Heading,
  Text,
  Flex,
  Button,
  Spinner,
  useColorModeValue,
  AspectRatio,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  StatHelpText,
  Icon,
} from "@chakra-ui/react";
import { FaProjectDiagram, FaChevronRight, FaEnvelope, FaFileAlt, FaFileContract, FaMoneyBillWave, FaTasks, FaCalendarAlt } from "react-icons/fa";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";
import { useContext } from "react";

const Dashboard = () => {
  const { loadingWorkspaces, currentUser, themeColor } = useContext(WorkspaceContext);
  const statistics = {
    projects: 0,
    messages: 0,
    files: 0,
  };
  const recentActivities = [
    {
      type: "project",
      title: "New Project Created",
      description: "Project Alpha has been created successfully.",
      timestamp: "2 hours ago",
    },
    {
      type: "message",
      title: "New Message Received",
      description: "You have received a new message from John Doe.",
      timestamp: "4 hours ago",
    },
    {
      type: "file",
      title: "New File Uploaded",
      description: "Document.pdf has been uploaded to the Files section.",
      timestamp: "6 hours ago",
    },
    {
      type: "contract",
      title: "New Contract Signed",
      description: "Contract #123 has been signed by the client.",
      timestamp: "8 hours ago",
    },
    {
      type: "finance",
      title: "Payment Received",
      description: "Payment of $500 received from client.",
      timestamp: "10 hours ago",
    },
    {
      type: "task",
      title: "New Task Assigned",
      description: "Task #1 has been assigned to you by the project manager.",
      timestamp: "12 hours ago",
    },
  ];
  const upcomingDeadlines = [
    {
      title: "Project Beta Deadline",
      date: "12th August 2024",
      description: "Final submission of Project Beta.",
    },
    {
      title: "Client Meeting",
      date: "15th August 2024",
      description: "Meeting with client to discuss project progress.",
    },
    {
      title: "Contract Renewal",
      date: "20th August 2024",
      description: "Renewal of contract with client.",
    },
    {
      title: "Project Gamma Deadline",
      date: "25th August 2024",
      description: "Final submission of Project Gamma.",
    },
  ];

  const borderColor = useColorModeValue("gray.200", "gray.700");
  const lightTextColor = useColorModeValue("gray.600", "gray.400");
  const headerBg = useColorModeValue("gray.50", "gray.900");
  const statsBg = useColorModeValue("white", "gray.700");
  const cardBg = useColorModeValue("white", "gray.700");
  const iconColor = useColorModeValue(`${themeColor}.500`, `${themeColor}.300`);
  const textColor = useColorModeValue("gray.800", "whiteAlpha.900");
  const subTextColor = useColorModeValue("gray.600", "gray.400");
  const bgGradient = useColorModeValue(`linear(to-r, gray.50, ${themeColor}.100)`, `linear(to-r, gray.800, ${themeColor}.900)`);
  const borderQuickActionColor = useColorModeValue("gray.400", "gray.500");

  const quickActionButtonHeight = "40px";

  if (loadingWorkspaces) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box height="100vh" overflowY="hidden" bgGradient={bgGradient}>
      {/* Fixed Header */}
      <Box position="fixed" top="0" w="100%" h="80px" px={6} borderBottom="1px solid" borderColor={borderColor} zIndex="1000" bg={headerBg}>
        <Text fontSize={{ base: "xl", md: "lg" }} pl={{ base: "40px", md: "0px" }} fontWeight="medium" color={textColor} lineHeight="80px">
          Welcome, {currentUser?.firstName}!
        </Text>
      </Box>

      {/* Scrollable Content */}
      <Box mt="80px" py={8} overflowY="auto" height="calc(100vh - 80px)" px={{ base: 4, md: 8 }}>
        {/* Introduction Section */}
        <Flex justify="start" align="center" mb={8} px={4}>
          <Box>
            <Heading size={{ base: "md", md: "lg" }} mb={1} color={textColor}>
              Welcome to FG Consultant!
            </Heading>
            <Text fontSize={{ base: "sm", md: "md" }} color={subTextColor}>
              Organize your work and improve your performance with us here!
            </Text>
          </Box>
        </Flex>

        {/* Quick Actions Section */}
        <Box px={4} mb={8}>
          <Heading size={{ base: "sm", md: "md" }} mb={4} color={textColor}>
            Quick Actions
          </Heading>
          <SimpleGrid columns={{ base: 2, md: 4 }} spacing={4}>
            <Button
              h={quickActionButtonHeight}
              leftIcon={<FaEnvelope />}
              colorScheme="gray"
              variant="outline"
              borderColor={borderQuickActionColor}
              onClick={() => (window.location.href = "/dashboard/messages")}
            >
              New Message
            </Button>
            <Button
              leftIcon={<FaProjectDiagram />}
              colorScheme="gray"
              variant="outline"
              onClick={() => (window.location.href = "/dashboard/projects")}
              borderColor={borderQuickActionColor}
              h={quickActionButtonHeight}
            >
              New Project
            </Button>
            <Button
              leftIcon={<FaFileContract />}
              colorScheme="gray"
              variant="outline"
              onClick={() => (window.location.href = "/dashboard/contracts")}
              borderColor={borderQuickActionColor}
              h={quickActionButtonHeight}
            >
              New Contract
            </Button>
            <Button
              leftIcon={<FaTasks />}
              h={quickActionButtonHeight}
              colorScheme="gray"
              borderColor={borderQuickActionColor}
              variant="outline"
              onClick={() => (window.location.href = "/dashboard/tasks")}
            >
              New Task
            </Button>
          </SimpleGrid>
        </Box>

        {/* Quick Statistics */}
        <Box px={4} mb={8}>
          <Heading size={{ base: "sm", md: "md" }} mb={4} color={textColor}>
            Statistics
          </Heading>
          <StatGroup>
            <Stat mr={4} px={4} py={6} bg={statsBg} borderRadius="md" boxShadow="base" border="1px solid" borderColor={borderColor}>
              <StatLabel color={subTextColor}>Total Projects</StatLabel>
              <StatNumber color={textColor}>{statistics.projects}</StatNumber>
              <StatHelpText color={subTextColor}>Active Projects</StatHelpText>
            </Stat>
            <Stat px={4} py={6} bg={statsBg} borderRadius="md" boxShadow="base" border="1px solid" borderColor={borderColor}>
              <StatLabel color={subTextColor}>Messages</StatLabel>
              <StatNumber color={textColor}>{statistics.messages}</StatNumber>
              <StatHelpText color={subTextColor}>Unread Messages</StatHelpText>
            </Stat>
            <Stat ml={4} px={4} py={6} bg={statsBg} borderRadius="md" boxShadow="base" border="1px solid" borderColor={borderColor}>
              <StatLabel color={subTextColor}>Files</StatLabel>
              <StatNumber color={textColor}>{statistics.files}</StatNumber>
              <StatHelpText color={subTextColor}>Total Storage Used</StatHelpText>
            </Stat>
          </StatGroup>
        </Box>

        {/* Action Cards */}
        <Box px={4} mb={8}>
          <Heading size={{ base: "sm", md: "md" }} mb={4} color={textColor}>
            Try things out!
          </Heading>
          <SimpleGrid w="100%" columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={4}>
            <DashboardCard
              title="What's New"
              description="Stay up to date with the latest features and updates."
              icon={FaMoneyBillWave}
              buttonText="Learn more"
              imageSrc="https://images.pexels.com/photos/1089440/pexels-photo-1089440.jpeg"
              onButtonClick={() => (window.location.href = "/dashboard")}
              button2Text="Go to Updates"
              onButton2Click={() => (window.location.href = "/dashboard/updates")}
              themeColor={themeColor}
            />
            <DashboardCard
              title="Messages"
              description="Communicate securely with your clients and team members."
              icon={FaEnvelope}
              buttonText="Learn more"
              imageSrc="https://images.pexels.com/photos/5053765/pexels-photo-5053765.jpeg"
              onButtonClick={() => (window.location.href = "/dashboard/settings/messages")}
              button2Text="Go to Messages"
              onButton2Click={() => (window.location.href = "/dashboard/messages")}
              themeColor={themeColor}
            />
            <DashboardCard
              title="Files"
              description="Manage all your important documents in one place."
              icon={FaFileAlt}
              buttonText="Learn more"
              imageSrc="https://images.pexels.com/photos/27396198/pexels-photo-27396198.jpeg"
              onButtonClick={() => (window.location.href = "/dashboard/settings/files")}
              button2Text="Go to Files"
              onButton2Click={() => (window.location.href = "/dashboard/files")}
              themeColor={themeColor}
            />
            <DashboardCard
              title="Projects"
              description="Keep track of your ongoing projects and deadlines."
              icon={FaProjectDiagram}
              buttonText="Learn more"
              imageSrc="https://images.pexels.com/photos/416405/pexels-photo-416405.jpeg"
              onButtonClick={() => (window.location.href = "/dashboard/settings/projects")}
              button2Text="Go to Projects"
              onButton2Click={() => (window.location.href = "/dashboard/projects")}
              themeColor={themeColor}
            />
            <DashboardCard
              title="Contracts"
              description="Create and manage client contracts efficiently."
              icon={FaFileContract}
              buttonText="Learn more"
              imageSrc="https://images.pexels.com/photos/48148/document-agreement-documents-sign-48148.jpeg"
              onButtonClick={() => (window.location.href = "/dashboard/settings/contracts")}
              button2Text="Go to Contracts"
              onButton2Click={() => (window.location.href = "/dashboard/contracts")}
              themeColor={themeColor}
            />
          </SimpleGrid>
        </Box>

        {/* Recent Activities */}
        <Box px={4} mb={8}>
          <Heading size={{ base: "sm", md: "md" }} mb={4} color={textColor}>
            Recent Activities
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            {recentActivities.map((activity, index) => (
              <Box key={index} bg={cardBg} p={4} borderRadius="md" boxShadow="base" border="1px solid" borderColor={borderColor}>
                <Flex align="center" mb={2}>
                  <IconComponent activityType={activity.type} iconColor={iconColor} />
                  <Text fontWeight="bold" ml={2} color={textColor}>
                    {activity.title}
                  </Text>
                </Flex>
                <Text fontSize="sm" color={subTextColor}>
                  {activity.description}
                </Text>
                <Text fontSize="xs" color={lightTextColor} mt={2}>
                  {activity.timestamp}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>

        {/* Upcoming Deadlines */}
        <Box px={4} mb={8}>
          <Heading size={{ base: "sm", md: "md" }} mb={4} color={textColor}>
            Upcoming Deadlines
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            {upcomingDeadlines.map((deadline, index) => (
              <Box key={index} bg={cardBg} p={4} borderRadius="md" boxShadow="base" border="1px solid" borderColor={borderColor}>
                <Flex align="center" mb={2}>
                  <Icon as={FaCalendarAlt} w={5} h={5} mr={2} color={iconColor} />
                  <Text fontWeight="bold" ml={2} color={textColor}>
                    {deadline.title}
                  </Text>
                </Flex>
                <Text fontSize="sm" color={subTextColor}>
                  Due Date: {deadline.date}
                </Text>
                <Text fontSize="xs" color={lightTextColor} mt={2}>
                  {deadline.description}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>

        {/* Footer */}
        <Text fontSize={{ base: "sm", md: "xs" }} color={subTextColor} textAlign="center" mt={8} px={{ base: 8, md: 0 }} width="100%">
          © 2024 Fab Innov. All rights reserved. <br /> Made by Fabien Gaudreau
        </Text>
      </Box>
    </Box>
  );
};

// IconComponent to render appropriate icons based on activity type
const IconComponent = ({ activityType, iconColor }) => {
  const iconProps = { color: iconColor, w: 5, h: 5, mr: 2 };
  switch (activityType) {
    case "project":
      return <Icon as={FaProjectDiagram} {...iconProps} />;
    case "message":
      return <Icon as={FaEnvelope} {...iconProps} />;
    case "file":
      return <Icon as={FaFileAlt} {...iconProps} />;
    case "contract":
      return <Icon as={FaFileContract} {...iconProps} />;
    case "finance":
      return <Icon as={FaMoneyBillWave} {...iconProps} />;
    default:
      return <Icon as={FaTasks} {...iconProps} />;
  }
};

const DashboardCard = ({ title, description, buttonText, imageSrc, onButtonClick, onButton2Click, button2Text, themeColor }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.750");
  const cardTextColor = useColorModeValue("gray.800", "whiteAlpha.900");
  const subTextColor = useColorModeValue("gray.600", "gray.400");

  return (
    <Box maxW={{ base: "100%", sm: "300px" }} bg={bgColor} boxShadow="base" border="1px solid" borderColor={borderColor} borderRadius="md" p={5}>
      {imageSrc && (
        <AspectRatio ratio={2 / 1} mb={4}>
          <img src={imageSrc} alt={title} style={{ borderRadius: "8px", objectFit: "cover" }} />
        </AspectRatio>
      )}
      <Heading fontWeight="bold" fontSize={{ base: "xl", md: "lg" }} mb={2} color={cardTextColor}>
        {title}
      </Heading>
      <Text fontSize={{ base: "sm", md: "sm" }} color={subTextColor} mb={4} minH="60px">
        {description}
      </Text>
      <Flex
        w="100%"
        direction={{ base: "column", sm: "row" }}
        justifyContent="space-between"
        align="center"
        borderTop="1px solid"
        borderColor={borderColor}
        pt={4}
      >
        <Button
          fontWeight="500"
          w='45%'
          size={{ base: "sm", md: "xs" }}
          rightIcon={<FaChevronRight />}
          colorScheme='gray'
          variant="outline"
          onClick={onButtonClick}
          mb={{ base: 2, sm: 0 }}
        >
          {buttonText}
        </Button>
        <Button fontWeight="400" w='45%' variant="solid" colorScheme={themeColor} onClick={onButton2Click} size={{ base: "sm", md: "xs" }}>
          {button2Text}
        </Button>
      </Flex>
    </Box>
  );
};

export default Dashboard;
