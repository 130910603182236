// client/src/components/ProjectCards.js

import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  HStack,
  Flex,
  Box,
  Text,
  IconButton,
  Button,
  Menu,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  InputGroup,
  InputLeftElement,
  Input,
  useDisclosure,
  Spinner,
  useColorModeValue,
  Tag,
  Progress,
  MenuDivider,
} from "@chakra-ui/react";
import { PiPencilSimpleLine, PiTrash, PiDotsThreeBold, PiFunnel, PiArchive, PiMagnifyingGlass } from "react-icons/pi";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import EditProjectModal from "../3 - Modal/3 - Projects Modals/ProjectsEditProjectModal";
import useCustomToast from "../5 - General/Utils/UtilsNotification";
import CreateProjectModal from "../3 - Modal/3 - Projects Modals/ProjectsCreateProjectModal";
import UserAvatar from "../2 - Components/Reusable/UserAvatar";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";
import { formatTimestamp, formatReadableDate } from "../5 - General/Utils/UtilsFormatData";
import ReusableModal from "../3 - Modal/9 - General Modals/ReusableModal";
import { useNavigate } from "react-router-dom";

const ProjectCards = () => {
  const {
    activeProjects,
    archivedProjects,
    isLoading,
    hasError,
    deleteProject,
    toggleArchiveProject,
    setCurrentProjectId,
  } = useContext(ProjectContext);
  const { currentWorkspace, themeColor } = useContext(WorkspaceContext);

  // States
  const [selectedProject, setSelectedProject] = useState(null);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  // Hooks
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const customToast = useCustomToast();
  const navigate = useNavigate();


  // Colors
  const bgCardColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.750");
  const menuBgColor = useColorModeValue("white", "gray.700");

  // Check if there are any projects
  const hasProjects = activeProjects.length + archivedProjects.length > 0;

  const handleProjectClick = (project) => {
    if (!project.archived) {
      setCurrentProjectId(project._id);
      navigate(`/dashboard/projects/${project._id}`); // Navigate to project URL
    }
  };

  useEffect(() => {
    setFilteredProjects(showArchived ? [...activeProjects, ...archivedProjects] : activeProjects);
  }, [activeProjects, archivedProjects, showArchived]);

  const toggleArchived = () => setShowArchived((prev) => !prev);

  const confirmDeleteProject = async () => {
    if (!selectedProject) return;
    try {
      await deleteProject(selectedProject._id);
      customToast({
        title: "Success",
        description: "Project deleted successfully.",
        status: "success",
      });
    } catch (error) {
      customToast({
        title: "Error",
        description: error.message || "Failed to delete project.",
        status: "error",
      });
    }
    onDeleteClose();
  };

  const handleArchiveProject = async (project) => {
    try {
      await toggleArchiveProject(project._id);
      customToast({
        title: "Success",
        description: project.archived ? "Project unarchived successfully." : "Project archived successfully.",
        status: "success",
      });
    } catch (error) {
      customToast({
        title: "Error",
        description: "Failed to update project archive status.",
        status: "error",
      });
    }
  };

  // Filter projects based on search term and status filter
  const filteredList = useMemo(() => {
    return filteredProjects.filter((project) => {
      const matchesSearchTerm = project.name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesStatus = filterStatus ? project.status === filterStatus : true;
      return matchesSearchTerm && matchesStatus;
    });
  }, [filteredProjects, searchTerm, filterStatus]);

  const statusColorScheme = (status) => {
    switch (status) {
      case "Not Started":
        return "gray";
      case "In Progress":
        return "blue";
      case "Completed":
        return "green";
      case "On Hold":
        return "yellow";
      default:
        return "gray";
    }
  };

  if (isLoading) {
    return (
      <Flex justify="center" align="center" w="100%" height="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (hasError) {
    return (
      <Flex justify="center" align="center" height="100%" width="100%">
        <Text fontSize="xl" color="red.500">
          An error occurred while fetching projects.
        </Text>
      </Flex>
    );
  }

  return (
    <Box m={4} mx={8} position="relative" flexGrow="1" width="auto" overflow="hidden">
      {/* Always display filters and search inputs */}
      <Flex p={1} mb={4} justify="space-between" align="center" flexWrap="wrap">
        <HStack spacing={4}>
          <InputGroup maxW="300px">
            <InputLeftElement pointerEvents="none">
              <PiMagnifyingGlass fontSize="18px" color="gray.400" />
            </InputLeftElement>
            <Input placeholder="Search projects..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </InputGroup>

          <Menu>
            <MenuButton as={Button} h="32px" variant="outline" rightIcon={<PiFunnel fontSize="18px" />} flexShrink={0}>
              Filters
            </MenuButton>
            <Portal>
              <MenuList>
                <MenuItem onClick={() => setFilterStatus("")}>
                  <HStack>
                    <Text ml={2} fontSize="md">
                      Reset Filters
                    </Text>
                  </HStack>
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => setFilterStatus("Not Started")}>
                  <HStack>
                    <Tag colorScheme="gray" size="sm" borderRadius="full" px={2}>
                      Not Started
                    </Tag>
                  </HStack>
                </MenuItem>
                <MenuItem onClick={() => setFilterStatus("In Progress")}>
                  <HStack>
                    <Tag colorScheme="blue" size="sm" borderRadius="full" px={2}>
                      In Progress
                    </Tag>
                  </HStack>
                </MenuItem>
                <MenuItem onClick={() => setFilterStatus("Completed")}>
                  <HStack>
                    <Tag colorScheme="green" size="sm" borderRadius="full" px={2}>
                      Completed
                    </Tag>
                  </HStack>
                </MenuItem>
                <MenuItem onClick={() => setFilterStatus("On Hold")}>
                  <HStack>
                    <Tag colorScheme="yellow" size="sm" borderRadius="full" px={2}>
                      On Hold
                    </Tag>
                  </HStack>
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </HStack>
        <HStack spacing={2}>
          <Button onClick={toggleArchived} size="xs" variant="outline" flexShrink={0}>
            {showArchived ? "Show Active Projects" : "Show Archived Projects"}
          </Button>
        </HStack>
      </Flex>

      {/* Conditional rendering based on whether there are projects */}
      {filteredList.length === 0 ? (
        hasProjects ? (
          // There are projects, but none match the filters
          <Flex justify="center" align="center" height="100%">
            <Text fontSize="xl" color="gray.500" mb="500px">
              No projects match your search or filters.
            </Text>
          </Flex>
        ) : (
          // No projects at all for the company
          <Flex p={1} justify="center" width="100%">
            <Box
              p={8}
              border="1px solid"
              bg="gray.50"
              borderColor={borderColor}
              borderRadius="xl"
              textAlign="center"
              boxShadow="md"
              h="fit-content"
              width={["90%", "70%", "50%"]}
              mt={12}
            >
              <Text fontSize="2xl" fontWeight="bold">
                No projects found for {currentWorkspace?.companyName || "this company"}.
              </Text>
              <Text mt={2} color="gray.500">
                Create a new project to get started.
              </Text>
              <Button mt={4} colorScheme={themeColor} onClick={onOpen}>
                Create Project
              </Button>
            </Box>
            {/* Create Project Modal */}
            <CreateProjectModal isOpen={isOpen} onClose={onClose} />
          </Flex>
        )
      ) : (
        <>
          <Flex
            justify="flex-start"
            align="flex-start"
            pb={8}
            height="100%"
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "rgba(0, 0, 0, 0.3)",
                borderRadius: "10px",
              },
            }}
          >
            <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(350px, 1fr))" gap={6} width="100%">
              {filteredList.map((project) => {
                const isArchived = project.archived;
                const completedTasks = project.completedTasks || 0;
                const totalTasks = project.totalTasks || 0;
                const progress = totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;

                return (
                  <Box
                    p={6}
                    py={4}
                    border="1px solid"
                    bg={isArchived ? "gray.200" : bgCardColor}
                    borderColor={isArchived ? "gray.400" : borderColor}
                    borderRadius="lg"
                    key={project._id + "grid"}
                    boxShadow="base"
                    _hover={{
                      boxShadow: "md",
                      cursor: isArchived ? "not-allowed" : "pointer",
                    }}
                    transition={"all 0.3s"}
                    onClick={() => handleProjectClick(project)}
                  >
                    <Flex justify="space-between" align="start" pb={3}>
                      {/* Content with conditional opacity */}
                      <Box opacity={isArchived ? 0.6 : 1}>
                        <Text fontWeight="600" isTruncated maxWidth="240px" fontSize="lg" color={isArchived ? "gray.500" : "inherit"}>
                          {project.name || "Unnamed Project"}
                        </Text>
                        <Tag size="sm" colorScheme={isArchived ? "gray" : statusColorScheme(project.status)} borderRadius="full" px={2} py={1} mt={2}>
                          {project.status || "No Status"}
                        </Tag>
                        <Text fontSize="sm" color="gray.500" mt={2}>
                          Last Updated: {formatTimestamp(project.updatedAt)}
                        </Text>
                        <Text fontSize="sm" color="gray.500" mt={1}>
                          End Date: {formatReadableDate(project.endDate) || "No deadline"}
                        </Text>
                      </Box>

                      {/* Action Menu without affected opacity */}
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<PiDotsThreeBold fontSize="18px" />}
                          variant="ghost"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          aria-label="Options"
                        />
                        <MenuList bg={menuBgColor}>
                          <MenuItem
                            icon={<PiPencilSimpleLine fontSize="18px" />}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProject(project);
                              onEditOpen();
                            }}
                            isDisabled={isArchived}
                            fontSize="sm"
                          >
                            Edit Project
                          </MenuItem>
                          <MenuItem
                            icon={<PiArchive fontSize="18px" />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleArchiveProject(project);
                            }}
                            fontSize="sm"
                          >
                            {isArchived ? "Unarchive Project" : "Archive Project"}
                          </MenuItem>
                          <MenuItem
                            icon={<PiTrash fontSize="18px" />}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProject(project);
                              onDeleteOpen();
                            }}
                            fontSize="sm"
                            color="red"
                          >
                            Delete Project
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>

                    {/* Project Info */}
                    <Box opacity={isArchived ? 0.6 : 1}>
                      <Progress value={progress} size="sm" colorScheme={themeColor} borderRadius="lg" bg={isArchived ? "gray.300" : undefined} />
                      <Flex mt={2} justify="space-between" align="center">
                        <Text fontSize="sm" color="gray.600">
                          Progress: {progress}%
                        </Text>
                        <UserAvatar userIds={project.members} size="sm" useGroup={true} maxAvatars={3} spacing={-5} opacity={isArchived ? 0.5 : 1} />
                      </Flex>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Flex>
        </>
      )}

      {/* Create Project Modal */}
      <CreateProjectModal isOpen={isOpen} onClose={onClose} />

      {/* Edit Project Modal */}
      {selectedProject && <EditProjectModal isOpen={isEditOpen} onClose={onEditClose} project={selectedProject} />}

      {/* Delete Confirmation Modal */}
      <ReusableModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        title="Delete Project"
        footerButtons={
          <>
            <Button onClick={onDeleteClose}>Cancel</Button>
            <Button colorScheme="red" onClick={confirmDeleteProject} ml={3}>
              Delete Project
            </Button>
          </>
        }
      >
        <Text fontSize="md">Are you sure you want to delete {selectedProject?.name || "this project"}?</Text>
        <Text fontSize="md">This action cannot be undone.</Text>
      </ReusableModal>
    </Box>
  );
};

export default ProjectCards;
