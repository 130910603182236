// client/src/components/ProjectsTasksList.jsx

import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  HStack,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  Button,
  Tag,
  useColorModeValue,
  Text,
  InputGroup,
  InputLeftElement,
  Input,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { PiCaretDownBold, PiPlus, PiMagnifyingGlass, PiCaretUpBold } from "react-icons/pi";
import UserAvatar from "../2 - Components/Reusable/UserAvatar";
import { formatDueDate } from "../5 - General/Utils/UtilsFormatData";
import TagMenu from "./Reusable/TagMenu";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import { debounce } from "lodash";

const columnWidths = ["4fr", "3fr", "2fr", "2fr", "2fr", "2fr"];

const TaskRow = ({ task, onTaskClick, onStatusChange, onPriorityChange, statusOptions, priorityOptions, py }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.750");
  const taskColor = useColorModeValue("primary.500", "primary.700");
  const taskFlagColor = task.color || taskColor;
  const subtaskCount = task.subtasks?.length || 0;

  const getDueDateColorScheme = (dueDate) => {
    if (!dueDate) return "gray"; // Undefined date
    const now = new Date();
    const due = new Date(dueDate);
    const diffDays = Math.ceil((due - now) / (1000 * 60 * 60 * 24));

    if (diffDays < 0) return "red"; // Overdue
    if (diffDays < 3) return "yellow"; // Due in less than 3 days
    if (diffDays < 10) return "green"; // Due in less than 10 days
    return "gray"; // Due in 10 or more days
  };

  if (!task) {
    console.error("TaskRow received an undefined task.");
    return null;
  }

  const assignedTo = task.assignedTo.map((user) => user._id);

  return (
    <Box
      display="grid"
      gridTemplateColumns={columnWidths.join(" ")}
      bg={bgColor}
      _hover={{ backgroundColor: hoverBg }}
      transition="background-color 0.2s"
      alignItems="center"
      cursor="pointer"
      borderBottom="1px solid"
      borderColor={borderColor}
      onClick={() => onTaskClick(task)}
      py={py}
    >
      {/* Task Name */}
      <Box w={columnWidths[0]} display="flex" alignItems="center">
        <Box w="10px" h="30px" bg={taskFlagColor} mr={4} borderRadius="md" />
        <Text fontWeight="400" fontSize="lg" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
          {task.name}
        </Text>
      </Box>

      {/* Due Date */}
      <Box w={columnWidths[1]}>
        <Tag size="lg" borderRadius="full" colorScheme={getDueDateColorScheme(task.dueDate)}>
          {task.dueDate
            ? new Date(task.dueDate) < new Date()
              ? `Overdue by ${Math.abs(Math.ceil((new Date() - new Date(task.dueDate)) / (1000 * 60 * 60 * 24)))} day${
                  Math.abs(Math.ceil((new Date() - new Date(task.dueDate)) / (1000 * 60 * 60 * 24))) > 1 ? "s" : ""
                }`
              : formatDueDate(task.dueDate)
            : "Undefined"}
        </Tag>
      </Box>

      {/* Subtasks Count */}
      <Box w={columnWidths[2]}>
        {subtaskCount > 0 ? (
          <Tag colorScheme="gray" fontSize="14px">
            {subtaskCount} {subtaskCount === 1 ? "Subtask" : "Subtasks"}
          </Tag>
        ) : (
          <Text fontSize="14px" color="gray.500">
            No Subtasks
          </Text>
        )}
      </Box>

      {/* Status */}
      <Box w={columnWidths[3]}>
        <TagMenu options={statusOptions} selectedValue={task.status} field="status" handleBlurSave={onStatusChange} taskId={task._id} />
      </Box>

      {/* Priority */}
      <Box w={columnWidths[4]}>
        <TagMenu
          options={priorityOptions}
          selectedValue={task.priority}
          field="priority"
          handleBlurSave={onPriorityChange} // Pass the function directly
          taskId={task._id} // Pass the taskId
        />
      </Box>

      {/* Assigned To */}
      <Box w={columnWidths[5]}>
        {Array.isArray(assignedTo) && assignedTo.length > 0 ? (
          <UserAvatar userIds={assignedTo} size="sm" useGroup={true} maxAvatars={3} spacing={-1} />
        ) : (
          <Text fontSize="14px" color="gray.500">
            Unassigned
          </Text>
        )}
      </Box>
    </Box>
  );
};

const ProjectsTasksList = ({ onTaskClick, onAddTask }) => {
  const { tasks, editTask } = useContext(ProjectContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("dueDate");
  const [sortDirection, setSortDirection] = useState("asc");
  const [filters, setFilters] = useState({
    status: [],
    priority: [],
  });

  const thStyles = {
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
    backgroundColor: useColorModeValue("white", "gray.800"),
  };

  const statusOptions = useMemo(
    () => [
      { value: "Not Started", label: "Not Started", color: "gray" },
      { value: "In Progress", label: "In Progress", color: "primary" },
      { value: "Completed", label: "Completed", color: "green" },
      { value: "On Hold", label: "On Hold", color: "orange" },
    ],
    []
  );

  const priorityOptions = useMemo(
    () => [
      { value: "High", label: "High", color: "red" },
      { value: "Medium", label: "Medium", color: "yellow" },
      { value: "Low", label: "Low", color: "green" },
    ],
    []
  );

  const onStatusChange = useCallback(
    async (taskId, field, value) => {
      try {
        await editTask(taskId, { [field]: value });
      } catch (error) {
        console.error(`Error updating task ${field}:`, error);
      }
    },
    [editTask]
  );

  const onPriorityChange = useCallback(
    async (taskId, field, value) => {
      try {
        await editTask(taskId, { [field]: value });
      } catch (error) {
        console.error(`Error updating task ${field}:`, error);
      }
    },
    [editTask]
  );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const renderSortIcon = (field) => {
    if (sortField !== field) return null;
    return sortDirection === "asc" ? <PiCaretUpBold /> : <PiCaretDownBold transform="rotate(180deg)" />;
  };

  const debouncedSetSearchQuery = useMemo(
    () =>
      debounce((value) => {
        setSearchQuery(value);
      }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSetSearchQuery.cancel();
    };
  }, [debouncedSetSearchQuery]);

  const filterAndSortTasks = useCallback(
    (tasks) => {
      const safeSearchQuery = searchQuery || "";

      return tasks
        .filter((task) => {
          const matchesSearchTerm = task?.name?.toLowerCase().includes(safeSearchQuery.toLowerCase()) || false;
          const matchesStatus = filters.status.length === 0 || filters.status.includes(task?.status || "");
          const matchesPriority = filters.priority.length === 0 || filters.priority.includes(task?.priority || "");
          return matchesSearchTerm && matchesStatus && matchesPriority;
        })
        .sort((a, b) => {
          let aValue = a[sortField];
          let bValue = b[sortField];

          if (sortField === "dueDate" || sortField === "startDate" || sortField === "createdAt" || sortField === "updatedAt") {
            aValue = aValue ? new Date(aValue).getTime() : 0;
            bValue = bValue ? new Date(bValue).getTime() : 0;
          } else if (sortField === "name" || sortField === "status" || sortField === "priority") {
            aValue = aValue ? aValue.toString().toLowerCase() : "";
            bValue = bValue ? bValue.toString().toLowerCase() : "";
          } else if (sortField === "assignedTo") {
            aValue = aValue ? aValue.length : 0;
            bValue = bValue ? bValue.length : 0;
          }

          if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
          if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
          return 0;
        });
    },
    [searchQuery, filters, sortField, sortDirection]
  );

  const displayedTasks = useMemo(() => filterAndSortTasks(tasks), [tasks, filterAndSortTasks]);

  return (
    <>
      {/* Header Section with Search and Filters */}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        bg={useColorModeValue("white", "gray.800")}
        pt={4}
        boxShadow="sm"
        borderRadius="md"
        position="sticky"
        top={0}
        zIndex={2}
        flexDirection="column"
        px={4}
      >
        <Flex w="100%" justifyContent="space-between" mb={2}>
          {/* Search Bar */}
          <InputGroup width={["100%", "300px"]}>
            <InputLeftElement pointerEvents="none">
              <PiMagnifyingGlass color="gray.400" />
            </InputLeftElement>
            <Input
              placeholder="Search tasks..."
              onChange={(e) => debouncedSetSearchQuery(e.target.value)}
              bg={useColorModeValue("gray.50", "gray.700")}
              borderRadius="md"
              _focus={{ borderColor: "primary.500" }}
            />
          </InputGroup>

          <HStack spacing={4}>
            {/* Filters Menu */}
            <Menu closeOnSelect={false}>
              <MenuButton as={Button} rightIcon={<PiCaretDownBold fontSize="16px" />} colorScheme="primary" variant="outline">
                Filters
              </MenuButton>
              <MenuList>
                <MenuOptionGroup
                  title="Status"
                  type="checkbox"
                  value={filters.status}
                  onChange={(values) => setFilters((prev) => ({ ...prev, status: values }))}
                >
                  {statusOptions.map((option) => (
                    <MenuItemOption key={option.value} value={option.value}>
                      {option.label}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>

                <MenuOptionGroup
                  title="Priority"
                  type="checkbox"
                  value={filters.priority}
                  onChange={(values) => setFilters((prev) => ({ ...prev, priority: values }))}
                >
                  {priorityOptions.map((option) => (
                    <MenuItemOption key={option.value} value={option.value}>
                      {option.label}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>

            <Button leftIcon={<PiPlus fontSize="18px" />} colorScheme="primary" onClick={onAddTask} variant="solid">
              Add Task
            </Button>
          </HStack>
        </Flex>

        {/* Table Header */}
        <Box
          display="grid"
          gridTemplateColumns={columnWidths.join(" ")}
          bg={useColorModeValue("gray.100", "gray.600")}
          style={thStyles}
          p={2}
          w="100%"
        >
          <Box onClick={() => handleSort("name")}>
            <Flex alignItems="center">Main tasks {renderSortIcon("name")}</Flex>
          </Box>
          <Box onClick={() => handleSort("dueDate")}>
            <Flex alignItems="center">Due Date {renderSortIcon("dueDate")}</Flex>
          </Box>
          <Box onClick={() => handleSort("subtaskCount")}>
            <Flex alignItems="center">Subtasks {renderSortIcon("subtaskCount")}</Flex>
          </Box>
          <Box onClick={() => handleSort("status")}>
            <Flex alignItems="center">Status {renderSortIcon("status")}</Flex>
          </Box>
          <Box onClick={() => handleSort("priority")}>
            <Flex alignItems="center">Priority {renderSortIcon("priority")}</Flex>
          </Box>
          <Box onClick={() => handleSort("assignedTo")}>
            <Flex alignItems="center">Assigned To {renderSortIcon("assignedTo")}</Flex>
          </Box>
        </Box>
      </Flex>

      {/* Tasks Table */}
      <Box overflowX="auto" minH="400px">
        {/* Table Body */}
        {displayedTasks.length > 0 ? (
          displayedTasks.map((task) => (
            <TaskRow
              key={task._id}
              task={task}
              onTaskClick={(task) => {
                onTaskClick(task);
              }}
              onStatusChange={onStatusChange}
              onPriorityChange={onPriorityChange}
              statusOptions={statusOptions}
              priorityOptions={priorityOptions}
              py="10px"
            />
          ))
        ) : (
          <Flex justify="center" align="center" height="100%">
            <Text my={4} color="gray.500">
              No tasks found.
            </Text>
          </Flex>
        )}

        {/* Add Task Row */}
        <Box
          onClick={onAddTask}
          cursor="pointer"
          _hover={{
            backgroundColor: useColorModeValue("gray.100", "gray.600"),
            transition: "background-color 0.2s ease",
          }}
          paddingY="8px"
          borderColor={useColorModeValue("gray.200", "gray.600")}
        >
          <Flex justify="start" pl="50px" py={2} alignItems="center">
            <Text color={useColorModeValue("gray.500", "gray.300")} fontStyle="italic">
              Add a New Task...
            </Text>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default ProjectsTasksList;
