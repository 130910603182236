// src/components/3 - Modal/2 - Messages Modals/CreateMessageModal.js

import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  VStack,
  Button,
  Input,
  FormLabel,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useColorModeValue,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { MessagesContext } from "../../5 - General/Context/MessagesContext";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";

const CreateMessageModal = ({ isOpen, onClose }) => {
  const { currentUser, availableUsers, isAvailableUsersLoading, availableUsersError, handleCreateChannel } = useContext(MessagesContext);
  const { themeColor } = useContext(WorkspaceContext);

  const [newChannelName, setNewChannelName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const maxChannelNameLength = 30;

  const selectTextColor = useColorModeValue("black", "white");
  const selectBgColor = useColorModeValue("white", "gray.900");
  const selectHoverBgColor = useColorModeValue("gray.100", "gray.600");
  const selectLightTextColor = useColorModeValue("gray.500", "gray.500");
  const selectDropdownIndicatorBgColor = useColorModeValue("gray.00", "gray.600");
  const selectMenuBgColor = useColorModeValue("white", "gray.700");

  const chakraStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : selectTextColor,
      backgroundColor: state.isSelected ? `${themeColor}.500` : selectMenuBgColor,
      "&:hover": { backgroundColor: state.isSelected ? `${themeColor}.500` : selectHoverBgColor },
      padding: "8px",
    }),
    container: (provided) => ({
      ...provided,
      backgroundColor: selectBgColor,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: selectTextColor,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: selectMenuBgColor,
      zIndex: 9999, // Ensure the menu is above the modal
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: selectTextColor,
      padding: "8px 12px",
      backgroundColor: selectDropdownIndicatorBgColor,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: selectLightTextColor,
      fontSize: "md",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: selectLightTextColor,
      backgroundColor: selectMenuBgColor,
    }),
    multiValue: (provided) => ({
      ...provided,
      color: selectTextColor,
      borderRadius: "full",
      fontSize: "sm",
      padding: "6px 12px",
      margin: "2px",
    }),
  };

  useEffect(() => {
    if (!isOpen) {
      setNewChannelName("");
      setSelectedUsers([]);
    }
  }, [isOpen]);

  const footerButtons = (
    <>
      <Button variant="outline" colorScheme="gray" onClick={onClose}>
        Cancel
      </Button>
      <Button
        colorScheme={themeColor}
        onClick={() => {
          if (newChannelName.trim() && selectedUsers.length >= 2) {
            const memberIds = selectedUsers.map((user) => user.value);
            handleCreateChannel(newChannelName, memberIds, false);
            onClose();
          }
        }}
        disabled={!newChannelName.trim() || selectedUsers.length < 2}
      >
        Create
      </Button>
    </>
  );

  const options = availableUsers
    .filter((user) => user.value !== currentUser._id)
    .map((user) => ({
      value: user.value,
      label: user.label,
    }));

  const renderContent = () => {
    if (isAvailableUsersLoading) {
      return (
        <VStack spacing={4} pt={4}>
          <Spinner />
          <Text>Loading users...</Text>
        </VStack>
      );
    }

    if (availableUsersError) {
      return (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Failed to load users.</AlertTitle>
          <AlertDescription>{availableUsersError.message}</AlertDescription>
        </Alert>
      );
    }

    return (
      <VStack spacing={4} pt={4}>
        <Box width="100%" pb={2}>
          <FormLabel>Conversation Name</FormLabel>
          <Input
            type="text"
            size="lg"
            fontSize="md"
            bg={selectBgColor}
            value={newChannelName}
            onChange={(e) => e.target.value.length <= maxChannelNameLength && setNewChannelName(e.target.value)}
            placeholder="Conversation Name"
          />
        </Box>
        <Box width="100%">
          <FormLabel>Add users to the conversation</FormLabel>
          <Select
            isMulti
            options={options}
            value={selectedUsers}
            onChange={(selected) => setSelectedUsers(selected)}
            placeholder="Search Users..."
            chakraStyles={chakraStyles}
            closeMenuOnSelect={false}
            menuPortalTarget={document.body} // Added portal
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
          />
        </Box>
      </VStack>
    );
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Create a new group conversation" footerButtons={footerButtons}>
      {renderContent()}
    </ReusableModal>
  );
};

export default CreateMessageModal;
