// TableConfigTeamSettings.js

import React from "react";
import { Button, Grid, GridItem, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Tag, Text, Tooltip } from "@chakra-ui/react";
import { PiCaretDownBold, PiTrash } from "react-icons/pi";
import { formatReadableDate, formatTimestamp } from "../../5 - General/Utils/UtilsFormatData";
import UserAvatar from "./UserAvatar";
import { Select } from "chakra-react-select";

const teamSettingsColumns = ({ handleChangeRole, currentUserRole, canModifyUser }) => {
  const desktopColumns = [
    {
      accessor: "userDetails",
      label: "User Details",
      render: (value, user) => {
        const textColor = "gray.700";
        const textLightColor = "gray.500";

        return (
          <HStack spacing={4}>
            <UserAvatar userIds={[user.id || user._id]} size="md" showInfo={false} />
            <div>
              <Text fontWeight="600" color={textColor}>
                {user.firstName} {user.lastName}
              </Text>
              <Text fontSize="xs" color={textLightColor}>
                {user.email}
              </Text>
            </div>
          </HStack>
        );
      },
      defaultVisible: true,
    },
    {
      accessor: "createdAt",
      label: "Date Joined",
      render: (value) => (
        <Button size="sm" variant="ghost" _hover={{ bg: null }}>
          {formatReadableDate(value)}
        </Button>
      ),
      defaultVisible: true,
    },
    {
      accessor: "role",
      label: "User Role",
      render: (value, user) => {
        if (currentUserRole === "Customer") {
          return (
            <Button size="xs" variant="outline" _hover={{ bg: null }}>
              {user.role}
            </Button>
          );
        }
        const roleOptions = [
          { value: "Admin", label: "Admin" },
          { value: "Editor", label: "Editor" },
          { value: "Customer", label: "Customer" },
        ];
        const selectedRole = roleOptions.find((option) => option.value === user.role);
        const isDisabled = !canModifyUser(currentUserRole, user.role) && user.role !== "Admin";

        return (
          <Select
            size="sm"
            value={selectedRole}
            onChange={(option) => handleChangeRole(user._id, option.value)}
            options={roleOptions}
            placeholder="Select role"
            isClearable={false}
            isSearchable={false}
            isDisabled={isDisabled}
            menuPortalTarget={document.body}
          />
        );
      },
      defaultVisible: true,
    },
    {
      accessor: "moreInfoMenu",
      label: "More Info",
      render: (value, user) => {
        return (
          <Menu>
            <MenuButton as={Button} variant="outline" aria-label="User Info" rightIcon={<PiCaretDownBold />} size="xs">
              See more
            </MenuButton>
            <MenuList>
              <MenuItem _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
                <Grid templateColumns="100px 1fr" gap={2}>
                  <GridItem>
                    <Text fontSize="md">Last Login:</Text>
                  </GridItem>
                  <GridItem>
                    <Tag size="xs" colorScheme={user.lastLoggedIn ? "gray" : "red"}>
                      {user.lastLoggedIn ? formatTimestamp(user.lastLoggedIn) : "Never"}
                    </Tag>
                  </GridItem>
                </Grid>
              </MenuItem>
              <MenuItem _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
                <Grid templateColumns="100px 1fr" gap={2}>
                  <GridItem>
                    <Text fontSize="md">Status:</Text>
                  </GridItem>
                  <GridItem>
                    <Tag size="xs" colorScheme={user.status === "Deactivated" ? "red" : "green"}>
                      {user.status === "Deactivated" ? "Deactivated" : "Active"}
                    </Tag>
                  </GridItem>
                </Grid>
              </MenuItem>
              <MenuItem _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
                <Grid templateColumns="100px 1fr" gap={2}>
                  <GridItem>
                    <Text fontSize="md">Email:</Text>
                  </GridItem>
                  <GridItem>
                    <Tag size="xs" colorScheme={user.isVerified ? "green" : "red"}>
                      {user.isVerified ? "Verified" : "Not Verified"}
                    </Tag>
                  </GridItem>
                </Grid>
              </MenuItem>
              <MenuItem _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
                <Grid templateColumns="100px 1fr" gap={2}>
                  <GridItem>
                    <Text fontSize="md">Profile:</Text>
                  </GridItem>
                  <GridItem>
                    <Tag size="xs" colorScheme={user.profileCompleted ? "green" : "red"}>
                      {user.profileCompleted ? "Complete" : "Incomplete Profile"}
                    </Tag>
                  </GridItem>
                </Grid>
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
      defaultVisible: true,
    },
  ];

  const mobileColumns = [
    {
      accessor: "userDetails",
      label: "User Details",
      render: (value, user) => {
        const textColor = "gray.700";
        const textLightColor = "gray.500";

        return (
          <HStack spacing={4}>
            <UserAvatar userIds={[user.id || user._id]} size="md" showInfo={false} />
            <div>
              <Text fontWeight="600" color={textColor}>
                {user.firstName} {user.lastName}
              </Text>
              <Text fontSize="xs" color={textLightColor}>
                {user.email}
              </Text>
            </div>
          </HStack>
        );
      },
      defaultVisible: true,
    },
  ];

  return { desktopColumns, mobileColumns };
};

const actionColumn = ({
  handleModifyRights,
  handleDeactivateUser,
  handleResendInvitation,
  handleSendPasswordReset,
  handleActivateUser,
  handleDeleteUser,
  currentUserRole,
  canModifyUser,
  isMobile,
  onOpenModal,
}) => {
  if (isMobile) {
    return {
      label: "Actions",
      render: (user) => (
        <HStack w="100%" justify="end" spacing={2}>
          <Button size="xs" variant="outline" onClick={() => onOpenModal(user)}>
            Actions
          </Button>
        </HStack>
      ),
    };
  } else {
    return {
      label: "Actions",
      render: (user) => {
        const isDisabled = !canModifyUser(currentUserRole, user.role);
        const isDisabledForAdmins = user.role === "Admin";

        return (
          <HStack w="100%" justify="end" spacing={2}>
            {!user.isVerified && (
              <Tooltip label="User must verify email to access the platform. This will resend the invitation email to the user.">
                <Button
                  size="xs"
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => handleResendInvitation(user)}
                  aria-label={`Resend invitation to ${user.firstName}`}
                  isDisabled={isDisabled}
                >
                  Resend Invitation
                </Button>
              </Tooltip>
            )}
            {user.isVerified && (
              <Button
                size="xs"
                variant="outline"
                colorScheme="gray"
                onClick={() => handleSendPasswordReset(user)}
                aria-label={`Resend invitation to ${user.firstName}`}
                isDisabled={isDisabled}
              >
                Send Password Reset
              </Button>
            )}
            <Tooltip label="Modify this user's rights to individual modules">
              <Button
                size="xs"
                variant="outline"
                onClick={() => handleModifyRights(user)}
                aria-label={`Modify rights for ${user.firstName}`}
                isDisabled={isDisabled}
              >
                Rights
              </Button>
            </Tooltip>
            {user.status !== "Deactivated" ? (
              <Tooltip label="Suspend user account">
                <Button
                  size="xs"
                  colorScheme="gray"
                  variant="outline"
                  onClick={() => handleDeactivateUser(user)}
                  aria-label={`Deactivate ${user.firstName}`}
                  isDisabled={isDisabled || isDisabledForAdmins}
                >
                  Suspend
                </Button>
              </Tooltip>
            ) : (
              <Tooltip label="Reactivate User Account">
                <Button
                  size="xs"
                  variant="outline"
                  colorScheme="green"
                  onClick={() => handleActivateUser(user)}
                  aria-label={`Reactivate ${user.firstName}`}
                  isDisabled={isDisabled}
                >
                  Reactivate
                </Button>
              </Tooltip>
            )}
            <Tooltip label={user.status === "Deactivated" ? "Delete user account" : "User account must be suspended to delete"}>
              <IconButton
                icon={<PiTrash fontSize="20px" />}
                size="xs"
                variant="outline"
                colorScheme="red"
                onClick={() => handleDeleteUser(user)}
                aria-label={`Delete ${user.firstName}`}
                isDisabled={isDisabled || user.status !== "Deactivated"}
              />
            </Tooltip>
          </HStack>
        );
      },
    };
  }
};

export const tableConfigs = {
  teamSettings: ({
    handleModifyRights,
    handleChangeRole,
    handleDeactivateUser,
    handleResendInvitation,
    handleSendPasswordReset,
    handleActivateUser,
    handleDeleteUser,
    currentUserRole,
    canModifyUser,
    isMobile,
    handleOpenModal,
  }) => {
    // Call teamSettingsColumns once, passing needed arguments
    const { desktopColumns, mobileColumns } = teamSettingsColumns({
      handleChangeRole,
      currentUserRole,
      canModifyUser,
    });

    return {
      desktopColumns,
      mobileColumns,
      actionColumn: actionColumn({
        handleModifyRights,
        handleChangeRole,
        handleDeactivateUser,
        handleResendInvitation,
        handleSendPasswordReset,
        handleActivateUser,
        handleDeleteUser,
        currentUserRole,
        canModifyUser,
        isMobile,
        onOpenModal: handleOpenModal,
      }),
      headerStyles: {
        h: "50px",
        bg: "white",
      },
      headerCellStyles: {
        fontWeight: "bold",
        fontSize: "14px",
        textTransform: "capitalize",
        _hover: { bg: "gray.50" },
        borderBottom: "1px solid",
        borderColor: "gray.300",
        transition: "background-color 0.2s",
      },
      rowStyles: {
        _hover: {
          bg: "gray.50",
        },
        transition: "background-color 0.2s",
      },
      cellStyles: {
        color: "gray.800",
        fontSize: "14px",
      },
    };
  },
};

export default tableConfigs;
