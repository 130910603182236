// client/src/components/4 - API/API-Projects.js

import { fetchData } from "./FetchData";
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

/**
 * Fetch all projects for a company
 * GET /api/v1/projects/:companyId/projects
 */
export const getProjectIds = async (companyId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${companyId}/projects`);
  return data.projectIds;
};

/**
 * Fetch archived projects for a company
 * GET /api/v1/projects/:companyId/projects/archived
 */
export const fetchArchivedProjects = async (companyId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${companyId}/projects/archived`);
  return data.archivedProjectIds;
};

/**
 * Fetch a specific project by ID
 * GET /api/v1/projects/:companyId/projects/:projectId
 */
export const getProjectById = async (companyId, projectId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${companyId}/projects/${projectId}`);
  return data.project;
};

/**
 * Fetch a specific task by ID
 * GET /api/v1/projects/:projectId/tasks/:taskId
 */
export const getTaskById = async (projectId, taskId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${projectId}/tasks/${taskId}`);
  return data.task;
};

/**
 * Fetch all available users and their companies for a specific user
 * GET /api/v1/projects/:userId/get-options-for-user
 */
export const getAvailableUserAndCompanyOptions = async (userId) => {
  if (!userId) throw new Error("User ID is required to fetch available options.");
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${userId}/get-options-for-user`);
  return data; // Ensure that backend sends the array directly
};

/**
 * Upload files to a specific task
 * POST /api/v1/projects/:projectId/tasks/:taskId/files
 */
export const uploadFilesToTask = async (projectId, taskId, acceptedFiles) => {
  const formData = new FormData();
  acceptedFiles.forEach((file) => {
    formData.append("files", file);
  });

  const response = await fetch(`${API_BASE_URL}/api/v1/projects/${projectId}/tasks/${taskId}/files`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: formData,
  });

  if (!response.ok) throw new Error("Error uploading files");

  const data = await response.json();
  return data.files; // Adjust based on backend response
};

/**
 * Download a file from a task using a presigned URL
 * GET /api/v1/projects/:projectId/tasks/:taskId/files/download?fileName=...
 */
export const downloadFileFromTask = async (projectId, taskId, fileName) => {
  const data = await fetchData(
    `${API_BASE_URL}/api/v1/projects/${projectId}/tasks/${taskId}/files/download?fileName=${encodeURIComponent(fileName)}`
  );
  const { downloadUrl } = data;

  const response = await fetch(downloadUrl);
  if (!response.ok) throw new Error("Error downloading file");

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
