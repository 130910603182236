// client/src/components/Files/Modals/CreateFolderModal.jsx

import React, { useContext, useState } from "react";
import { Button, Input, FormControl, FormLabel, useColorModeValue } from "@chakra-ui/react";
import { FilesContext } from "../../5 - General/Context/FilesContext";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext"; // Imported WorkspaceContext

const CreateFolderModal = () => {
  const { onCloseCreateFolderModal, isCreateFolderModalOpen, handleCreateFolder } = useContext(FilesContext);
  const { themeColor } = useContext(WorkspaceContext); // Accessed themeColor

  const [folderName, setFolderName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!folderName.trim()) return;
    setIsSubmitting(true);
    try {
      await handleCreateFolder(folderName);
      setFolderName("");
      onCloseCreateFolderModal();
    } catch (error) {
      console.error("Error creating folder:", error);
      // Optionally, you can use a toast or another method to notify the user
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    onCloseCreateFolderModal();
    setFolderName(""); // Reset input on close
  };

  const footerButtons = (
    <>
      <Button
        variant="outline"
        color={useColorModeValue("gray.600", "gray.300")}
        _hover={{
          color: useColorModeValue("black", "white"),
          bg: useColorModeValue("gray.100", "gray.500"),
        }}
        mr={3}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button colorScheme={themeColor} onClick={handleSubmit} isLoading={isSubmitting} disabled={!folderName.trim()}>
        Create
      </Button>
    </>
  );

  return (
    <ReusableModal isOpen={isCreateFolderModalOpen} onClose={handleClose} title="Create a Folder" footerButtons={footerButtons}>
      <FormControl isRequired>
        <FormLabel>Folder Name</FormLabel>
        <Input
          bg={useColorModeValue("white", "gray.700")}
          boxShadow="base"
          placeholder="Enter folder name"
          px={4}
          _focus={{ boxShadow: "md", border: `1px solid`, boxSizing: "border-box", borderColor: `${themeColor}.500` }} // Updated with themeColor
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}
          autoFocus
        />
      </FormControl>
    </ReusableModal>
  );
};

export default CreateFolderModal;
