// client/src/components/Files/Modals/DeleteConfirmationModal.jsx

import React, { useContext } from "react";
import { Button, Text, useColorModeValue } from "@chakra-ui/react";
import { FilesContext } from "../../5 - General/Context/FilesContext";
import ReusableModal from "../9 - General Modals/ReusableModal";

const DeleteConfirmationModal = () => {
  const { isDeleteConfirmationModalOpen, onCloseDeleteConfirmationModal, handleDeleteConfirm, filesToDelete } = useContext(FilesContext);

  const handleClose = () => {
    onCloseDeleteConfirmationModal();
  };

  const getDeleteDescription = () => {
    if (filesToDelete.length === 1) {
      const file = filesToDelete[0];
      if (!file) {
        return "Are you sure you want to delete this item? This action cannot be undone.";
      }

      if (file.type === "folder") {
        // Single folder
        return "Are you sure you want to delete this folder and all of its contents? This action cannot be undone.";
      } else if (file.originalFileName) {
        // Single file
        return `Are you sure you want to delete "${file.originalFileName}"? This action cannot be undone.`;
      } else {
        // Single item (fallback)
        return "Are you sure you want to delete this item? This action cannot be undone.";
      }
    } else {
      const folderNames = filesToDelete.filter((file) => file.type === "folder").map((folder) => folder.originalFileName);

      if (folderNames.length > 0) {
        const foldersList = folderNames.join(", ");
        const folderWord = folderNames.length > 1 ? "folders" : "folder";
        return `Are you sure you want to delete ${filesToDelete.length} items, including ${folderWord} "${foldersList}" and their contents? This action cannot be undone.`;
      } else {
        return `Are you sure you want to delete ${filesToDelete.length} items? This action cannot be undone.`;
      }
    }
  };

  const footerButtons = (
    <>
      <Button
        variant="outline"
        color={useColorModeValue("gray.600", "gray.300")}
        _hover={{
          color: useColorModeValue("black", "white"),
          bg: useColorModeValue("gray.100", "gray.500"),
        }}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button colorScheme="red" onClick={handleDeleteConfirm}>
        Delete
      </Button>
    </>
  );

  return (
    <ReusableModal isOpen={isDeleteConfirmationModalOpen} onClose={handleClose} title="Confirm Deletion" footerButtons={footerButtons}>
      <Text fontSize="md">{getDeleteDescription()}</Text>
    </ReusableModal>
  );
};

export default DeleteConfirmationModal;
