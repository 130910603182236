// UserAvatar.js
import React, { useContext } from "react";
import { Avatar, AvatarBadge, Box, Alert, AlertIcon, Flex, Text, Tooltip, Spinner } from "@chakra-ui/react";
import { PiUser } from "react-icons/pi";
import PropTypes from "prop-types";
import { useUserOnlineStatusSocketQuery } from "../../5 - General/Hooks/useUserOnlineStatusSocketQuery";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";

const UserAvatar = ({
  userIds = [],
  size = "md",
  showInfo = true,
  maxAvatars = 2,
  borderRadius = "full",
  bg = "transparent",
  spacing = -5,
  withTooltip = true,
  alignSelf = "",
  mr = 0,
  ml = 0,
}) => {
  const sortedUserIds = [...userIds].sort();
  const { data: usersData, isLoading, error } = useUserOnlineStatusSocketQuery(sortedUserIds);
  const { themeColor } = useContext(WorkspaceContext);

  if (!userIds || userIds.length === 0) {
    return null;
  }

  if (isLoading) {
    return <Spinner size={size} />;
  }

  if (error) {
    return (
      <Alert status="error" mt={4}>
        <AlertIcon />
        {error.message || "An error occurred while fetching the user data."}
      </Alert>
    );
  }

  // Function to render individual avatars
  const renderAvatar = (userId) => {
    const user = usersData[userId];

    if (!user) {
      // User data not available yet
      return <Avatar size={size} borderRadius={borderRadius} key={userId} />;
    }

    const { firstName, lastName, profilePictureUrl, companyName, isOnline } = user;
    const avatarSrc = profilePictureUrl || "";

    const avatarElement = (
      <Avatar
        mr={`${mr}px`}
        ml={`${ml}px`}
        bg={`${themeColor}.200`}
        color={`${themeColor}.700`}
        alignSelf={alignSelf}
        size={size}
        name={`${firstName} ${lastName}`}
        src={avatarSrc}
        borderRadius={borderRadius}
        key={userId}
      >
        {isOnline ? <AvatarBadge boxSize="0.90em" bg="green.400" /> : <AvatarBadge boxSize="0.90em" bg="gray.300" />}
      </Avatar>
    );

    return withTooltip ? (
      <Tooltip
        hasArrow
        label={`${firstName} ${lastName} - ${companyName || "No Company"} - ${isOnline ? "Online" : "Offline"}`}
        aria-label={`${firstName} ${lastName} Tooltip`}
        key={userId}
      >
        {avatarElement}
      </Tooltip>
    ) : (
      avatarElement
    );
  };

  // Determine how many avatars to show and how many extra
  const displayUsers = userIds.slice(0, maxAvatars);
  const extraUsers = userIds.length - maxAvatars;

  // Function to render the "+X" avatar with tooltip listing all users
  const renderExtraAvatar = () => {
    if (extraUsers <= 0) return null;

    const avatarLabel = `+${extraUsers}`;

    // Collect all users to display in the tooltip
    const allUsers = userIds.map((userId) => {
      const user = usersData[userId] || {};
      const { firstName, lastName, companyName, isOnline } = user;
      return `${firstName} ${lastName} - ${companyName || "No Company"} - ${isOnline ? "Online" : "Offline"}`;
    });

    return (
      <Tooltip
        hasArrow
        label={
          <Box>
            {allUsers.map((userInfo, index) => (
              <Text key={index} fontSize="sm">
                {userInfo}
              </Text>
            ))}
          </Box>
        }
        aria-label="Extra Users Tooltip"
        key="extra-users-tooltip"
      >
        <Avatar
          size={size}
          bg="gray.200"
          borderRadius={borderRadius}
          cursor="pointer"
          overflow="hidden"
          icon={<PiUser fontSize="0" />}
        >
          <Text fontSize={size} color="gray.700">
            {avatarLabel}
          </Text>
        </Avatar>
      </Tooltip>
    );
  };

  return (
    <Box>
      <Flex align="center" mr={1} bg={bg}>
        <Box position="relative" display="flex">
          {/* Give the first displayed user the highest z-index, next one lower, etc. */}
          {displayUsers.map((userId, index) => (
            <Box
              key={`${userId}-avatar`}
              position="relative"
              marginLeft={index === 0 ? 0 : `${spacing}px`}
              zIndex={displayUsers.length - index}
            >
              {renderAvatar(userId)}
            </Box>
          ))}

          {/* Give the +X avatar the lowest z-index */}
          {extraUsers > 0 && (
            <Box position="relative" marginLeft={`${spacing}px`} zIndex={0}> {/* ADDED */}
              {renderExtraAvatar()}
            </Box>
          )}
        </Box>

        {showInfo && userIds.length === 1 && (
          <Box ml={3}>
            <Text fontWeight="600" fontSize="md" whiteSpace="nowrap" overflow="hidden">
              {usersData[userIds[0]]?.firstName} {usersData[userIds[0]]?.lastName}
            </Text>
            {usersData[userIds[0]]?.companyName && (
              <Text fontSize="xs" color="gray.500" whiteSpace="nowrap" overflow="hidden">
                {usersData[userIds[0]]?.companyName}
              </Text>
            )}
          </Box>
        )}
      </Flex>
    </Box>
  );
};

UserAvatar.propTypes = {
  userIds: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.string,
  showInfo: PropTypes.bool,
  maxAvatars: PropTypes.number,
  borderRadius: PropTypes.string,
  bg: PropTypes.string,
  spacing: PropTypes.number,
  withTooltip: PropTypes.bool,
  alignSelf: PropTypes.string,
  mr: PropTypes.number,
  ml: PropTypes.number,
};

export default UserAvatar;
