// client/src/components/5 - General/Socket/SocketContext.js

import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { io } from "socket.io-client";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { queryKeys } from "../../../queryKeys"; // Adjust the path as needed
import useCustomToast from "../Utils/UtilsNotification";
import { updateMessageInPaginatedData, addMessageToPaginatedData, removeMessageInPaginatedData } from "../Hooks/useUpdateMessages";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [connected, setConnected] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [perChannelUnreadCounts, setPerChannelUnreadCounts] = useState({});
  const [globalUnreadCount, setGlobalUnreadCount] = useState(0);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  /**
   * Function to establish a socket connection.
   * It checks if a socket is already connected to prevent duplicate connections.
   */
  const connectSocket = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (socket && socket.connected) {
        resolve(socket);
        return;
      }

      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");
      if (!token || !userId) {
        reject(new Error("Missing credentials"));
        return;
      }

      const newSocket = io(apiUrl, {
        auth: { token, userId },
        transports: ["websocket"],
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      });

      newSocket.on("connect", () => {
        setSocket(newSocket);
        resolve(newSocket);
        setConnected(true);
      });

      newSocket.on("connect_error", (error) => {
        setConnected(false);
        if (error.message === "Duplicate connection") {
          newSocket.disconnect();
        }
        reject(error);
      });

      newSocket.on("disconnect", (reason) => {
        setConnected(false);
        if (reason !== "io server disconnect") {
          setSocket(null);
        }
      });
    });
  }, [socket, apiUrl]);

  /**
   * Effect to establish the socket connection on component mount.
   * It also handles cleanup by disconnecting the socket on unmount.
   */
  useEffect(() => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    if (token && userId && (!socket || !socket.connected)) {
      connectSocket().catch((error) => {
        console.error("Failed to connect socket:", error);
      });
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket, connectSocket]);

  const customToast = useCustomToast();
  const location = useLocation(); // Get the current location

  // Determine if the user is on the "Messages" tab
  const isMessagesTabActive = location.pathname.startsWith("/dashboard/messages");
  // Check if the user is logged in and not on the login page
  const isUserLoggedIn = !!localStorage.getItem("userId") && !location.pathname.startsWith("/login");

  /**
   * Function to handle incoming new messages from the socket.
   * It manages both UI notifications and cache updates.
   */
  const handleNewMessage = useCallback(
    (newMessage) => {
      const userId = localStorage.getItem("userId");
      if (!isUserLoggedIn) {
        return;
      }

      // Show a toast notification if the user is not on the Messages tab and the message is from another user
      if (!isMessagesTabActive && newMessage.sender && newMessage.sender._id !== userId) {
        customToast({
          status: "message",
          title: `${newMessage.sender.firstName}`,
          description: newMessage.content,
          buttonTitle: "View Message",
          buttonLink: `/dashboard/messages/${newMessage.channelId}`,
          senderId: newMessage.sender._id,
        });
      }

      // Check if the channel is in cache
      const channelInCache = queryClient.getQueryData(queryKeys.channel(newMessage.channelId));
      if (!channelInCache) {
        return;
      }

      /**
       * Update the React Query cache for messages:
       * - Check if the message already exists to prevent duplicates.
       * - Prepend the new message to the cache.
       */
      queryClient.setQueryData(queryKeys.messages(newMessage.channelId), (oldData) => {
        // Prepend the new message (allowing the first page to exceed the limit)
        return addMessageToPaginatedData(oldData, newMessage);
      });

      /**
       * Update the React Query cache for the channel's lastMessage:
       * - Fetch the current channel data.
       * - Update the lastMessage field with the new message details.
       */
      queryClient.setQueryData(queryKeys.channel(newMessage.channelId), (oldChannelData) => {
        if (!oldChannelData) return oldChannelData;

        // Format the new lastMessage as per your channel's lastMessage structure
        const formattedLastMessage = {
          content: newMessage.content,
          timestamp: newMessage.timestamp,
          senderName: newMessage.sender ? `${newMessage.sender.firstName} ${newMessage.sender.lastName}` : "System Message",
          senderFirstName: newMessage.sender ? newMessage.sender.firstName : "System",
          includesFile: newMessage.includesFile || false,
          originalFileName: newMessage.originalFileName || "",
          backendFileName: newMessage.backendFileName || "",
        };

        return {
          ...oldChannelData,
          lastMessage: formattedLastMessage,
        };
      });
    },
    [isUserLoggedIn, isMessagesTabActive, customToast, queryClient]
  );

  /**
   * Effect to handle various socket events and update the React Query cache accordingly.
   * It ensures that all relevant events are listened to and properly managed.
   */
  useEffect(() => {
    if (socket) {
      // ---------------------- General Events ----------------------

      // Handle permissions updates
      socket.on("permissions", (receivedPermissions) => {
        setPermissions(receivedPermissions);
      });

      // ---------------------- User Events ----------------------

      // Handle user updates
      socket.on("userUpdated", ({ updates, userId }) => {
        const userInCache = queryClient.getQueryData(queryKeys.user(userId));
        if (userInCache) {
          queryClient.setQueryData(queryKeys.user(userId), (oldData) => ({
            ...oldData,
            ...updates,
          }));
        }

        // Update user avatars if necessary
        const userAvatarQueries = queryClient.getQueriesData({ queryKey: queryKeys.userAvatar(userId) });
        userAvatarQueries.forEach(([queryKey]) => {
          const userIds = queryKey[1];
          if (Array.isArray(userIds) && userIds.includes(userId)) {
            queryClient.setQueryData(queryKey, (oldCacheData) => ({
              ...oldCacheData,
              [userId]: {
                ...oldCacheData?.[userId],
                firstName: updates.firstName,
                lastName: updates.lastName,
              },
            }));
          }
        });
      });

      // Handle profile picture updates
      socket.on("profilePictureUpdated", ({ userId, profilePictureUrl }) => {
        const userAvatarQueries = queryClient.getQueriesData({ queryKey: queryKeys.userAvatar(userId) });
        if (userAvatarQueries.length === 0) {
          return;
        }
        queryClient.setQueryData(queryKeys.userAvatar(userId), (oldData) => ({
          ...oldData,
          profilePictureUrl,
        }));
      });

      // Handle theme updates
      socket.on("themeUpdated", ({ userId, theme }) => {
        queryClient.setQueryData(queryKeys.user(userId), (oldData) => ({ ...oldData, theme }));
      });

      // Handle user deletion
      socket.on("userDeleted", (data) => {
        queryClient.invalidateQueries(queryKeys.user(data.userId));
      });

      // Handle user online status
      socket.on("userOnline", ({ userId }) => {
        const userAvatarQueries = queryClient.getQueriesData({ queryKey: queryKeys.userAvatar(userId) });
        userAvatarQueries.forEach(([queryKey]) => {
          const userIds = queryKey[1];
          if (Array.isArray(userIds) && userIds.includes(userId)) {
            queryClient.invalidateQueries(queryKey);
          }
        });
      });

      // Handle user offline status
      socket.on("userOffline", ({ userId }) => {
        const userAvatarQueries = queryClient.getQueriesData({ queryKey: queryKeys.userAvatar(userId) });
        userAvatarQueries.forEach(([queryKey]) => {
          const userIds = queryKey[1];
          if (Array.isArray(userIds) && userIds.includes(userId)) {
            queryClient.invalidateQueries(queryKey);
          }
        });
      });

      // ---------------------- Company/Team Events ----------------------

      // Handle company settings updates
      socket.on("companySettingsUpdated", ({ companyId, company }) => {
        queryClient.setQueryData(queryKeys.company(companyId), (oldData) => ({
          ...oldData,
          ...company,
        }));
        queryClient.invalidateQueries(queryKeys.companyAvatar(companyId));
      });

      // Handle color logo updates
      socket.on("colorLogoUpdated", ({ companyId, logoColorPath }) => {
        queryClient.setQueryData(queryKeys.companyAvatar(companyId), (oldData) => ({
          ...oldData,
          logoColorPath,
        }));
      });

      // Handle white logo updates
      socket.on("whiteLogoUpdated", ({ companyId, logoWhitePath }) => {
        queryClient.setQueryData(queryKeys.companyAvatar(companyId), (oldData) => ({
          ...oldData,
          logoWhitePath,
        }));
      });

      // Handle user invitations
      socket.on("userInvited", ({ companyId, newUser }) => {
        queryClient.invalidateQueries(queryKeys.companyUsers(companyId));
      });

      // Handle user deactivation
      socket.on("userDeactivated", ({ userId, status }) => {
        queryClient.setQueryData(queryKeys.user(userId), (oldData) => ({
          ...oldData,
          status,
        }));
        if (localStorage.getItem("userId") === userId) {
          setPermissions({});
        }
      });

      // Handle account deletion
      socket.on("accountDeleted", ({ message }) => {
        queryClient.clear();
        localStorage.clear();
        setPermissions({});
        navigate("/dashboard");
      });

      // ---------------------- File Events ----------------------

      socket.on("fileUploaded", ({ uploadedFile }) => {
        if (!uploadedFile || !uploadedFile.companyId) {
          console.error("Invalid uploadedFile data received:", uploadedFile);
          return;
        }
        const { companyId, parentFolder } = uploadedFile;
        const folderId = parentFolder?._id || null;

        queryClient.setQueryData(queryKeys.files(companyId, folderId), (oldData) => {
          if (!oldData) {
            return {
              filesAndFolders: [uploadedFile],
              folderPath: [],
            };
          }
          return {
            ...oldData,
            filesAndFolders: [...oldData.filesAndFolders, uploadedFile],
          };
        });
      });

      socket.on("folderCreated", ({ folder }) => {
        const parentFolderId = folder.parentFolder ? folder.parentFolder._id : null;

        queryClient.setQueryData(queryKeys.files(folder.companyId, parentFolderId), (oldData) => {
          if (!oldData) {
            return {
              filesAndFolders: [folder],
              folderPath: [], // or however you want to track the path
            };
          }

          return {
            ...oldData,
            filesAndFolders: [...oldData.filesAndFolders, folder],
          };
        });
      });

      // Handle file renaming
      socket.on("fileRenamed", ({ renamedFile }) => {
        // Figure out the folder ID from the renamedFile's parentFolder if needed:
        const folderId = renamedFile.parentFolder?._id || null;

        queryClient.setQueryData(queryKeys.files(renamedFile.companyId, folderId), (oldData) => {
          if (!oldData) {
            return {
              filesAndFolders: [renamedFile],
              folderPath: [],
            };
          }

          return {
            ...oldData,
            filesAndFolders: oldData.filesAndFolders.map((f) =>
              f._id === renamedFile._id ? { ...f, originalFileName: renamedFile.originalFileName } : f
            ),
          };
        });
      });

      // Handle file deletion
      socket.on("fileDeleted", ({ deletedFile }) => {
        const parentFolderId = deletedFile.parentFolderId || null;

        queryClient.setQueryData(queryKeys.files(deletedFile.companyId, parentFolderId), (oldData) => {
          if (!oldData) {
            return {
              filesAndFolders: [],
              folderPath: [],
            };
          }

          return {
            ...oldData,
            filesAndFolders: oldData.filesAndFolders.filter((f) => f._id !== deletedFile._id),
          };
        });
      });

      // Handle folder deletion
      socket.on("folderDeleted", ({ deletedFolder }) => {
        // If `deletedFolder.parentFolder` is an object, get its _id (or null)
        const parentFolderId = deletedFolder.parentFolder ? deletedFolder.parentFolder._id : null;

        queryClient.setQueryData(queryKeys.files(deletedFolder.companyId, parentFolderId), (oldData) => {
          if (!oldData) {
            return {
              filesAndFolders: [],
              folderPath: [],
            };
          }

          return {
            ...oldData,
            filesAndFolders: oldData.filesAndFolders.filter((f) => f._id !== deletedFolder._id),
          };
        });
      });

      socket.on("filesMoved", ({ movedFiles }) => {
        if (!movedFiles || movedFiles.length === 0) {
          return;
        }

        movedFiles.forEach((file) => {
          const { _id, oldParentFolderId, parentFolder, companyId } = file;
          const newParentFolderId = parentFolder?._id || null;

          // 1. Remove from the old folder's data
          queryClient.setQueryData(queryKeys.files(companyId, oldParentFolderId || null), (oldData) => {
            // If it's not loaded yet, create a default structure
            if (!oldData) {
              return {
                filesAndFolders: [],
                folderPath: [],
              };
            }

            return {
              ...oldData,
              filesAndFolders: oldData.filesAndFolders.filter((f) => f._id !== _id),
            };
          });

          // 2. Add to the new folder's data ONLY if that cache is loaded
          const newParentCache = queryClient.getQueryData(queryKeys.files(companyId, newParentFolderId));

          if (newParentCache) {
            queryClient.setQueryData(queryKeys.files(companyId, newParentFolderId), (oldData) => {
              if (!oldData) {
                return {
                  filesAndFolders: [file],
                  folderPath: [],
                };
              }

              // Avoid duplicates
              if (oldData.filesAndFolders.some((f) => f._id === _id)) {
                return oldData; // Already in there
              }

              return {
                ...oldData,
                filesAndFolders: [...oldData.filesAndFolders, file],
              };
            });
          }
        });
      });

      // ---------------------- Message Events ----------------------

      // Handle receiving new messages
      socket.on("receiveMessage", (newMessage) => {
        handleNewMessage(newMessage);
        socket.emit("getUnreadCount", {});
      });

      // Handle message edits
      socket.on("messageEdited", ({ channelId, messageId, newContent, editedAt }) => {
        queryClient.setQueryData(queryKeys.messages(channelId), (oldData) =>
          updateMessageInPaginatedData(oldData, messageId, (msg) => ({
            ...msg,
            content: newContent,
            editedAt,
          }))
        );
      });

      // Handle message deletions
      socket.on("messageDeleted", ({ channelId, messageId }) => {
        queryClient.setQueryData(queryKeys.messages(channelId), (oldData) => removeMessageInPaginatedData(oldData, messageId));
      });

      // Handle new channel creation
      socket.on("channelCreated", (newChannel) => {
        // Update the list of channel IDs
        queryClient.setQueryData(queryKeys.channelIds(), (oldData = []) => {
          if (oldData.includes(newChannel._id)) {
            return oldData;
          }
          return [...oldData, newChannel._id];
        });

        // Set the new channel data in its own cache
        queryClient.setQueryData(queryKeys.channel(newChannel._id), newChannel);
      });

      // Handle channel deletion
      socket.on("channelDeleted", ({ channelId }) => {
        queryClient.setQueryData(queryKeys.channels, (oldData = []) => {
          return oldData.filter((channel) => channel._id !== channelId);
        });
        queryClient.removeQueries(queryKeys.channel(channelId));
      });

      // Handle channel name updates
      socket.on("updateChannelName", ({ channelId, newTitle }) => {
        queryClient.setQueryData(queryKeys.channel(channelId), (oldData) => {
          if (!oldData) return oldData;
          return { ...oldData, name: newTitle };
        });
      });

      // Handle users being added to a channel
      socket.on("usersAdded", ({ channelId, userIds }) => {
        queryClient.setQueryData(queryKeys.channel(channelId), (oldChannelData) => {
          if (!oldChannelData || !oldChannelData.members) return oldChannelData;
          const updatedMembers = [...oldChannelData.members, ...userIds.map((id) => ({ _id: id, firstName: "Unknown", lastName: "User" }))];

          return {
            ...oldChannelData,
            members: updatedMembers,
            lastMessage: {
              content: `${userIds.length === 1 ? "A user has" : `${userIds.length} users have`} been added to the conversation by System.`,
              timestamp: new Date().toISOString(),
              senderName: "System Message",
              senderFirstName: "System",
              includesFile: false,
              originalFileName: "",
              backendFileName: "",
            },
          };
        });
      });

      socket.on("usersRemoved", ({ channelId, userIds }) => {
        // Update the 'channel' cache by removing the users from the 'members' array
        queryClient.setQueryData(queryKeys.channel(channelId), (oldChannelData) => {
          if (!oldChannelData || !oldChannelData.members) return oldChannelData;

          const updatedMembers = oldChannelData.members.filter((member) => !userIds.includes(member._id));

          return {
            ...oldChannelData,
            members: updatedMembers,
            lastMessage: {
              content: `${userIds.length === 1 ? "A user has" : `${userIds.length} users have`} been removed from the conversation by System.`,
              timestamp: new Date().toISOString(),
              senderName: "System Message",
              senderFirstName: "System",
              includesFile: false,
              originalFileName: "",
              backendFileName: "",
            },
          };
        });
      });

      // ---------------------- Unread Count Events ----------------------

      // Handle total unread count updates
      socket.on("updateTotalUnreadCount", (data) => {
        let count;
        if (typeof data.totalUnread === "object" && data.totalUnread !== null) {
          count = data.totalUnread.count || 0;
        } else {
          count = data.totalUnread || 0;
        }
        setGlobalUnreadCount(count);
      });

      // Handle per-channel unread count updates
      socket.on("updateChannelUnreadCount", ({ channelId, unreadCount }) => {
        setPerChannelUnreadCounts((prev) => ({
          ...prev,
          [channelId]: unreadCount,
        }));
      });

      // ---------------------- Project Events ----------------------

      socket.on("projectCreated", ({ data }) => {
        const newProject = data.project;
        const assignedToCompanies = newProject.assignedToCompanies || [];
        for (const companyId of assignedToCompanies) {
          queryClient.setQueryData(queryKeys.projectIds(companyId), (oldData) => [...oldData, newProject._id]);
        }
        queryClient.setQueryData(queryKeys.project(newProject._id), newProject);
      });

      socket.on("taskUpdated", ({ data }) => {
        console.log("Received 'taskUpdated' event:", data);
        const updatedTask = data;
        queryClient.setQueryData(queryKeys.task(updatedTask._id), updatedTask);
      });

      socket.on("commentAddedToTask", ({ data }) => {
        console.log("Received commentAddedToTask event:", data);
        const { taskId, comment } = data;
        queryClient.setQueryData(queryKeys.task(taskId), (oldData) => {
          if (!oldData) {
            return oldData;
          }
          const updatedData = {
            ...oldData,
            comments: [...(oldData.comments || []), comment],
          };
          return updatedData;
        });
      });

      socket.on("taskCommentDeleted", ({ data }) => {
        console.log("Received taskCommentDeleted event:", data);
        const { taskId, commentId } = data;
        queryClient.setQueryData(queryKeys.task(taskId), (oldData) => {
          if (!oldData) {
            return oldData;
          }
          const updatedData = {
            ...oldData,
            comments: oldData.comments.filter((comment) => comment._id !== commentId),
          };
          return updatedData;
        });
      });
      // taskCommentEdited
      socket.on("taskCommentEdited", ({ data }) => {
        console.log("Received taskCommentEdited event:", data);
        const { taskId, editedComment } = data;
        queryClient.setQueryData(queryKeys.task(taskId), (oldData) => {
          if (!oldData) {
            return oldData;
          }
          const updatedData = {
            ...oldData,
            comments: oldData.comments.map((c) => (c._id === editedComment._id ? editedComment : c)),
          };
          return updatedData;
        });
      });

      socket.on("taskCommentLiked", ({ data }) => {
        const { taskId, commentId, likedBy } = data;

        // Debugging logs to ensure the received data is correct
        console.log("Received likeCommentInTask event:", data);

        queryClient.setQueryData(queryKeys.task(taskId), (oldData) => {
          if (!oldData) {
            console.error(`Task with ID ${taskId} not found in cache.`);
            return oldData; // If no data is found, do nothing
          }

          // Log the old data for debugging
          console.log("Existing task data before update:", oldData);

          const updatedComments = oldData.comments.map((comment) =>
            comment._id === commentId
              ? { ...comment, likedBy } // Update the likedBy array if the comment matches
              : comment
          );

          const updatedTask = {
            ...oldData,
            comments: updatedComments, // Replace comments with updated comments
          };

          // Log the updated task data for debugging
          console.log("Updated task data after likeCommentInTask:", updatedTask);

          return updatedTask;
        });

        // Verify cache update
        const updatedCache = queryClient.getQueryData(queryKeys.task(taskId));
        console.log("Cache after update:", updatedCache);
      });

      socket.on("taskOrderUpdated", ({ data }) => {
        console.log("Received taskOrderUpdated event:", data);
        const { tasks } = data;
        if (!tasks || !Array.isArray(tasks)) return;
        tasks.forEach((task) => {
          queryClient.setQueryData(queryKeys.task(task._id), (oldTask) => {
            if (!oldTask) return oldTask;
            return { ...oldTask, status: task.status, order: task.order };
          });
        });
      });

      /**
       * Cleanup function to remove all socket event listeners when the component unmounts or the socket disconnects.
       * This prevents memory leaks and ensures that event handlers are not duplicated.
       */
      return () => {
        // ---------------------- General Events ----------------------
        socket.off("permissions");
        socket.off("userOnline");
        socket.off("userOffline");

        // ---------------------- User Events ----------------------
        socket.off("userUpdated");
        socket.off("profilePictureUpdated");
        socket.off("themeUpdated");
        socket.off("userDeleted");

        // ---------------------- Company/Team Events ----------------------
        socket.off("companySettingsUpdated");
        socket.off("colorLogoUpdated");
        socket.off("whiteLogoUpdated");
        socket.off("userInvited");
        socket.off("userDeactivated");
        socket.off("accountDeleted");

        // ---------------------- File Events ----------------------
        socket.off("fileUploaded");
        socket.off("fileDeleted");
        socket.off("folderCreated");
        socket.off("folderDeleted");
        socket.off("fileRenamed");
        socket.off("filesMoved");
        socket.off("massDownloadReady");

        // ---------------------- Message Events ----------------------
        socket.off("receiveMessage");
        socket.off("messageEdited");
        socket.off("messageDeleted");

        // ---------------------- Channel Events ----------------------
        socket.off("channelCreated");
        socket.off("channelDeleted");
        socket.off("updateChannelName");
        socket.off("usersAdded");
        socket.off("usersRemoved");

        // ---------------------- Unread Count Events ----------------------
        socket.off("updateTotalUnreadCount");
        socket.off("updateChannelUnreadCount");

        // ---------------------- Project Events ----------------------
        socket.off("projectCreated");
        socket.off("taskUpdated");
        socket.off("commentAddedToTask");
        socket.off("taskCommentDeleted");
        socket.off("taskCommentEdited");
        socket.off("likeCommentInTask");
      };
    }
  }, [socket, queryClient, navigate, customToast, isMessagesTabActive, isUserLoggedIn, handleNewMessage]);

  return (
    <SocketContext.Provider value={{ socket, connected, connectSocket, permissions, perChannelUnreadCounts, globalUnreadCount }}>
      {children}
    </SocketContext.Provider>
  );
};

/**
 * Custom hook to access the socket context.
 * @returns {Object} Socket context value.
 */
export const useSocket = () => {
  return useContext(SocketContext);
};
