// src/components/MessagesItem.js

import React, { useState, useContext } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  IconButton,
  Button,
  Textarea,
  Alert,
  AlertIcon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { PiPencilSimpleLine } from "react-icons/pi";
import UserAvatar from "./Reusable/UserAvatar";
import ReusableModal from "../3 - Modal/9 - General Modals/ReusableModal";
import useCustomToast from "../5 - General/Utils/UtilsNotification";
import { MessagesContext } from "../5 - General/Context/MessagesContext";
import PropTypes from "prop-types";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext"; // Import WorkspaceContext

const MessagesItem = React.memo(({ msg, renderMessageContent, formatTimestamp }) => {
  const { handleEditMessage, handleDeleteMessage, currentUser } = useContext(MessagesContext);
  const { themeColor } = useContext(WorkspaceContext); // Destructure themeColor

  const [isHovered, setIsHovered] = useState(false);
  const [newContent, setNewContent] = useState(msg.content);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editError, setEditError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);

  const customToast = useCustomToast();

  const messageBgColor = useColorModeValue("gray.200", "gray.600");
  const messageTextColor = useColorModeValue("black", "white");
  const messageBgColorActiveUser = useColorModeValue(`${themeColor}.400`, `${themeColor}.700`); // Dynamic background
  const messageTextColorActiveUser = useColorModeValue("white", "white");
  const editMessageButtonColor = useColorModeValue("gray", "gray");

  const isUserMessage = msg.sender?._id === currentUser?._id;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const confirmDelete = async () => {
    try {
      await handleDeleteMessage(msg._id);
      setIsDeleteModalOpen(false);
      customToast({
        status: "success",
        title: "Message Deleted",
        description: "Your message has been deleted.",
      });
    } catch (error) {
      customToast({
        status: "error",
        title: "Delete Failed",
        description: "Failed to delete the message.",
      });
      setDeleteError("Failed to delete the message.");
    }
  };

  const handleSaveEdit = async () => {
    try {
      await handleEditMessage(msg._id, newContent);
      setIsEditModalOpen(false);
    } catch (error) {
      setEditError("Failed to edit the message.");
    }
  };

  return (
    <Flex
      align="center"
      justifyContent={msg.isSystemMessage ? "center" : isUserMessage ? "flex-end" : "flex-start"}
      mb="1px"
      className="message-container"
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {msg.isSystemMessage ? (
        <Box p={4} bg="transparent" textAlign="center" fontSize="md" color="gray.500">
          {msg.content}
        </Box>
      ) : (
        <>
          {!isUserMessage && msg.sender && msg.showAvatarOther ? (
            <Box display={{ base: "none", md: "block" }}>
              <UserAvatar userIds={[msg.sender._id]} size="sm" showInfo={false} />
            </Box>
          ) : (
            !isUserMessage && <Box mr={{ base: 0, md: "36px" }} />
          )}

          <VStack
            align={isUserMessage ? "end" : "start"}
            spacing={1}
            flex="1"
            textAlign="left"
            mr={isUserMessage ? { base: 0, md: "15px" } : "0"}
            ml={!isUserMessage ? { base: 0, md: "15px" } : "0"}
          >
            {msg.showName && (
              <Text fontWeight="bold" fontSize="md" justifySelf="center">
                {msg.sender?.firstName} {msg.sender?.lastName}
              </Text>
            )}

            <HStack spacing={1} alignItems="flex-start" position="relative">
              {isUserMessage && !msg.isDeleted && (
                <IconButton
                  icon={<PiPencilSimpleLine fontSize={isMobile ? "10px" : "14px"} />}
                  aria-label="Edit message"
                  size="xs"
                  variant="ghost"
                  onClick={() => setIsEditModalOpen(true)}
                  opacity={isHovered || isMobile ? "1" : "0"}
                  transition="opacity 0.2s"
                  colorScheme={editMessageButtonColor} 
                />
              )}

              <Box
                p={2}
                bg={isUserMessage ? messageBgColorActiveUser : messageBgColor} 
                color={isUserMessage ? messageTextColorActiveUser : messageTextColor} 
                borderRadius="xl"
                maxW={{ base: "250px", md: "650px" }}
                w="fit-content"
              >
                {renderMessageContent(msg)}
              </Box>
            </HStack>

            {msg.showDate && (
              <Text fontSize="xs" color="gray.500">
                {formatTimestamp(msg.timestamp)}
              </Text>
            )}
            {deleteError && (
              <Text fontSize="xs" color="red.500">
                {deleteError}
              </Text>
            )}
          </VStack>

          {isUserMessage && msg.sender && msg.showAvatarUser ? (
            <Box display={{ base: "none", md: "block" }}>
              <UserAvatar userIds={[msg.sender._id]} size="sm" showInfo={false} alignSelf="flex-start" withTooltip={false} />
            </Box>
          ) : (
            isUserMessage && <Box ml={{ base: 0, md: "36px" }} />
          )}
        </>
      )}

      {/* Edit Modal */}
      <ReusableModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        title="Edit Message"
        footerButtons={
          <>
            <Button variant='outline' onClick={() => setIsEditModalOpen(false)}>
              Cancel
            </Button>
            <HStack spacing={2}>
              <Button colorScheme="red" variant='outline' onClick={() => setIsDeleteModalOpen(true)}>
                Delete Message
              </Button>
              <Button colorScheme={themeColor} onClick={handleSaveEdit}>
                Save Changes
              </Button>
            </HStack>
          </>
        }
      >
        <Textarea value={newContent} onChange={(e) => setNewContent(e.target.value)} placeholder="Edit your message..." />
        {editError && (
          <Text color="red.500" mt={2}>
            {editError}
          </Text>
        )}
      </ReusableModal>

      {/* Confirmation Modal */}
      <ReusableModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Confirm Deletion"
        footerButtons={
          <>
            <Button onClick={() => setIsDeleteModalOpen(false)} colorScheme={themeColor}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={confirmDelete}>
              Delete
            </Button>
          </>
        }
      >
        <Text>Are you sure you want to delete this message?</Text>
        {msg.includesFile || msg.backendFileName ? (
          <Alert status="warning" mt={4} borderRadius="md">
            <AlertIcon />
            <Text>This message contains an attached file. Deleting the message will also remove the attached file and cannot be undone.</Text>
          </Alert>
        ) : null}
        {deleteError && (
          <Text color="red.500" mt={2}>
            {deleteError}
          </Text>
        )}
      </ReusableModal>
    </Flex>
  );
});

MessagesItem.propTypes = {
  msg: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    sender: PropTypes.shape({
      _id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    content: PropTypes.string,
    isSystemMessage: PropTypes.bool,
    isDeleted: PropTypes.bool,
    showName: PropTypes.bool,
    showAvatarOther: PropTypes.bool,
    showAvatarUser: PropTypes.bool,
    showDate: PropTypes.bool,
    includesFile: PropTypes.bool,
    backendFileName: PropTypes.string,
    originalFileName: PropTypes.string,
    fileSize: PropTypes.number,
    editedAt: PropTypes.string,
    timestamp: PropTypes.string,
  }).isRequired,
  renderMessageContent: PropTypes.func.isRequired,
  formatTimestamp: PropTypes.func.isRequired,
};

export default MessagesItem;
