// client/src/components/3 - Modal/3 - Projects Modals/CreateProjectModal.jsx

import React, { useState, useEffect, useContext } from "react";
import { Button, Spinner, IconButton, HStack, Box, FormControl, FormLabel, Input, Checkbox, useColorModeValue, Text } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import { PiArrowRightBold, PiXBold } from "react-icons/pi";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import TagMenu from "../../2 - Components/Reusable/TagMenu";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import { useQuery } from "@tanstack/react-query";
import { getAvailableUserAndCompanyOptions } from "../../4 - API/API-Projects";
import isEqual from "lodash/isEqual"; // Import lodash's isEqual

const CreateProjectModal = ({ isOpen, onClose }) => {
  const { currentUser } = useContext(WorkspaceContext);
  const { createProject, setCurrentProjectId } = useContext(ProjectContext);

  const [name, setName] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [status, setStatus] = useState("Not Started");
  const [priority, setPriority] = useState("Medium");

  // State variables for companies and members
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectAllUsers, setSelectAllUsers] = useState(false);

  const arrowColor = useColorModeValue("gray.500", "gray.300");

  const customToast = useCustomToast();

  // Fetch available users and companies only when the modal is open
  const { data: availableUserAndCompanyOptions = [], isLoading: isAvailableUserAndCompanyOptionsLoading } = useQuery({
    queryKey: ["availableUsers", currentUser?._id],
    queryFn: () => getAvailableUserAndCompanyOptions(currentUser?._id),
    enabled: isOpen && Boolean(currentUser),
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
  });

  // Populate company options based on fetched users
  useEffect(() => {
    if (availableUserAndCompanyOptions && availableUserAndCompanyOptions.length > 0 && isOpen) {
      const uniqueCompanies = {};
      availableUserAndCompanyOptions.forEach((user) => {
        if (user.companyId && !uniqueCompanies[user.companyId]) {
          uniqueCompanies[user.companyId] = {
            value: user.companyId,
            label: user.companyName,
          };
        }
      });
      const newCompanyOptions = Object.values(uniqueCompanies);
      if (!isEqual(companyOptions, newCompanyOptions)) {
        setCompanyOptions(newCompanyOptions);
      }
    } else {
      if (companyOptions.length !== 0) {
        setCompanyOptions([]);
      }
    }
  }, [availableUserAndCompanyOptions, companyOptions, isOpen]);

  // Update member options based on selected companies
  useEffect(() => {
    if (selectedCompanies.length > 0 && isOpen) {
      const selectedCompanyIds = selectedCompanies.map((company) => company.value);
      const filteredUsers = availableUserAndCompanyOptions.filter((user) => selectedCompanyIds.includes(user.companyId));
      const formattedUsers = filteredUsers.map((user) => ({
        value: user.value,
        label: `${user.firstName} ${user.lastName}`,
        user,
      }));

      if (!isEqual(memberOptions, formattedUsers)) {
        setMemberOptions(formattedUsers);
      }

      // If not selecting all, select the current user by default if they are in the filtered list
      if (!selectAllUsers) {
        const currentUserOption = formattedUsers.find((user) => user.value === currentUser?._id);
        const newSelectedMembers = currentUserOption ? [currentUserOption] : [];
        if (!isEqual(selectedMembers, newSelectedMembers)) {
          setSelectedMembers(newSelectedMembers);
        }
      }
    } else {
      if (!isEqual(memberOptions, []) && isOpen) {
        setMemberOptions([]);
      }
      if (!isEqual(selectedMembers, []) && isOpen) {
        setSelectedMembers([]);
      }
    }
  }, [selectedCompanies, availableUserAndCompanyOptions, currentUser, selectAllUsers, memberOptions, selectedMembers, isOpen]);

  // Handle "Select All Users" checkbox
  useEffect(() => {
    if (selectAllUsers && isOpen) {
      if (!isEqual(selectedMembers, memberOptions)) {
        setSelectedMembers(memberOptions);
      }
    } else {
      const currentUserOption = memberOptions.find((user) => user.value === currentUser?._id);
      const newSelectedMembers = currentUserOption ? [currentUserOption] : [];
      if (!isEqual(selectedMembers, newSelectedMembers)) {
        setSelectedMembers(newSelectedMembers);
      }
    }
  }, [selectAllUsers, memberOptions, currentUser, selectedMembers, isOpen]);

  const handleSubmit = async () => {
    if (!name.trim()) {
      customToast({
        title: "Error",
        description: "Project name is required.",
        status: "error",
      });
      return;
    }

    if (selectedCompanies.length === 0) {
      customToast({
        title: "Error",
        description: "Please select at least one company.",
        status: "error",
      });
      return;
    }

    const projectData = {
      name,
      startDate,
      endDate,
      status,
      priority,
      members: selectAllUsers ? memberOptions.map((user) => user.value) : selectedMembers.map((user) => user.value),
      assignedToCompanies: selectedCompanies.map((company) => company.value),
    };

    try {
      const createdProject = await createProject(projectData);

      // Set currentProject to the entire project object
      setCurrentProjectId(createdProject._id);

      customToast({
        title: "Project Created",
        description: "The project has been successfully created.",
        status: "success",
      });

      onClose();
      resetForm();
    } catch (error) {
      console.error("Error creating project:", error);
      customToast({
        title: "Error Creating Project",
        description: error.message || "An error occurred.",
        status: "error",
      });
    }
  };

  const resetForm = () => {
    setName("");
    setStartDate(null);
    setEndDate(null);
    setSelectedMembers([]);
    setSelectedCompanies([]);
    setStatus("Not Started");
    setPriority("Medium");
    setSelectAllUsers(false);
  };

  // Status and Priority options
  const statusOptions = [
    { value: "Not Started", label: "Not Started", color: "gray" },
    { value: "In Progress", label: "In Progress", color: "blue" },
    { value: "Completed", label: "Completed", color: "green" },
    { value: "On Hold", label: "On Hold", color: "orange" },
  ];

  const priorityOptions = [
    { value: "High", label: "High", color: "red" },
    { value: "Medium", label: "Medium", color: "yellow" },
    { value: "Low", label: "Low", color: "green" },
  ];

  // Custom Option Component for Users
  const CustomUserOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Box ref={innerRef} {...innerProps} _hover={{ bg: "gray.100" }}>
        <HStack spacing={3} px={6} py={1}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
          <Text>{data.label}</Text> {/* Added Text to display label */}
        </HStack>
      </Box>
    );
  };

  // Custom MultiValue Component for Users
  const CustomUserMultiValue = (props) => {
    const { data, innerRef, innerProps, removeProps } = props;
    return (
      <Box
        ref={innerRef}
        {...innerProps}
        mb={1}
        bg="gray.50"
        borderRadius="xl"
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={1}
      >
        <HStack spacing={1}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
          <Text>{data.label}</Text> {/* Added Text to display label */}
        </HStack>
        <IconButton
          icon={<PiXBold fontSize="16px" />}
          colorScheme="gray"
          variant="ghost"
          size="sm"
          onClick={removeProps.onClick}
          aria-label="Remove"
        />
      </Box>
    );
  };

  // Define the footer buttons
  const footerButtons = (
    <HStack ml="auto" spacing={3}>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
      <Button colorScheme="primary" onClick={handleSubmit}>
        Create Project
      </Button>
    </HStack>
  );

  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title="Create a New Project"
      footerButtons={footerButtons}
      size="lg"
      isCentered
      scrollBehavior="inside" // Added scrollBehavior to handle long content
    >
      <FormControl isRequired p={4} pt={2}>
        <FormLabel fontSize="md" fontWeight="500">
          Project Name
        </FormLabel>
        <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter project name..." />
      </FormControl>

      {/* Start and End Dates Side by Side */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Project Duration
        </FormLabel>
        <HStack spacing={2}>
          <UtilsDatePicker value={startDate} placeholder="Start Date" onChange={setStartDate} inputVariant="default" />
          <PiArrowRightBold color={arrowColor} />
          <UtilsDatePicker value={endDate} placeholder="End Date" onChange={setEndDate} inputVariant="default" />
        </HStack>
      </FormControl>

      {/* Status and Priority */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Initial Project Status and Priority
        </FormLabel>
        <HStack spacing={4}>
          <TagMenu options={statusOptions} selectedValue={status} field="status" handleBlurSave={(id, field, value) => setStatus(value)} />
          <TagMenu options={priorityOptions} selectedValue={priority} field="priority" handleBlurSave={(id, field, value) => setPriority(value)} />
        </HStack>
      </FormControl>

      {/* Companies Selection */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Select Companies
        </FormLabel>
        <Select
          isMulti
          placeholder="Select companies..."
          options={companyOptions}
          value={selectedCompanies}
          onChange={(companies) => setSelectedCompanies(companies)}
          components={chakraComponents}
          closeMenuOnSelect={false}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </FormControl>

      {/* Members Selection */}
      <FormControl isRequired p={4}>
        {!selectAllUsers && (
          <>
            <FormLabel fontSize="md" fontWeight="500">
              Select Members
            </FormLabel>
            <Select
              isMulti
              placeholder="Select members..."
              options={memberOptions}
              value={selectedMembers}
              onChange={setSelectedMembers}
              components={{
                Option: CustomUserOption,
                MultiValue: CustomUserMultiValue,
              }}
              closeMenuOnSelect={false}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({ ...base, zIndex: 9999 }),
              }}
              isDisabled={memberOptions.length === 0}
            />
          </>
        )}
        <Checkbox isChecked={selectAllUsers} onChange={(e) => setSelectAllUsers(e.target.checked)} mt={2}>
          Select all users from selected companies
        </Checkbox>
      </FormControl>

      {/* Loading State for Members */}
      {isAvailableUserAndCompanyOptionsLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" py={4}>
          <Spinner />
        </Box>
      )}
    </ReusableModal>
  );
};

export default CreateProjectModal;
