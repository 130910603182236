// client/src/components/Files/Modals/FileCreateLinkModal.jsx

import React, { useState, useContext } from "react";
import { Button, FormControl, FormLabel, Input, useColorModeValue, InputGroup } from "@chakra-ui/react";
import { FilesContext } from "../../5 - General/Context/FilesContext";
import ReusableModal from "../9 - General Modals/ReusableModal";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext"; // Imported WorkspaceContext

const FileCreateLinkModal = () => {
  const { onCloseCreateLinkModal, isCreateLinkModalOpen, handleCreateLink } = useContext(FilesContext);
  const { themeColor } = useContext(WorkspaceContext); // Accessed themeColor
  const [linkName, setLinkName] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const customToast = useCustomToast();

  const handleSubmit = async () => {
    if (!linkName.trim() || !linkUrl.trim()) {
      customToast({
        title: "Invalid input",
        description: "Please provide both a name and a URL for the link.",
        status: "warning",
      });
      return;
    }
    setIsSubmitting(true);
    try {
      await handleCreateLink(linkName, linkUrl);
      setLinkName("");
      setLinkUrl("");
      onCloseCreateLinkModal();
    } catch (error) {
      console.error("Error creating link:", error);
      customToast({
        title: "Error",
        description: "There was an error creating the link. Please try again.",
        status: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    onCloseCreateLinkModal();
    setLinkName("");
    setLinkUrl("");
  };

  const footerButtons = (
    <>
      <Button
        variant="outline"
        color={useColorModeValue("gray.600", "gray.300")}
        _hover={{
          color: useColorModeValue("black", "white"),
          bg: useColorModeValue("gray.100", "gray.500"),
        }}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        colorScheme={themeColor} // Replaced "blue" with themeColor
        onClick={handleSubmit}
        isLoading={isSubmitting}
        disabled={!linkName.trim() || !linkUrl.trim()}
      >
        Create Link
      </Button>
    </>
  );

  return (
    <ReusableModal isOpen={isCreateLinkModalOpen} onClose={handleClose} title="Create a New Link" footerButtons={footerButtons}>
      <FormControl id="linkName" isRequired>
        <FormLabel>Name</FormLabel>
        <Input
          bg={useColorModeValue("white", "gray.700")}
          boxShadow="base"
          placeholder="Enter a name for the link"
          px={4}
          _focus={{
            boxShadow: "md",
            border: "1px solid",
            boxSizing: "border-box",
            borderColor: `${themeColor}.500`,
          }}
          value={linkName}
          onChange={(e) => setLinkName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}
          autoFocus
        />
      </FormControl>
      <FormControl id="linkUrl" mt={4} isRequired>
        <FormLabel>URL</FormLabel>
        <InputGroup>
          <Input
            bg={useColorModeValue("white", "gray.700")}
            boxShadow="base"
            placeholder="Enter the URL"
            px={4}
            _focus={{
              boxShadow: "md",
              border: "1px solid",
              boxSizing: "border-box",
              borderColor: `${themeColor}.500`,
            }}
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />
        </InputGroup>
      </FormControl>
    </ReusableModal>
  );
};

export default FileCreateLinkModal;
