// src/components/SideBarLayout.js

import React, { useState, memo, useMemo, useEffect, useCallback, useContext, useLayoutEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Flex,
  Icon,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  HStack,
  IconButton,
  Link,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorMode,
  useColorModeValue,
  Spinner,
  Button,
  MenuDivider,
  ButtonGroup,
  AlertIcon,
  Alert,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  PiAlignLeft,
  PiChartLineUp,
  PiChat,
  PiInvoice,
  PiSignature,
  PiFiles,
  PiSignOut,
  PiUsers,
  PiGear,
  PiMagnifyingGlass,
  PiCaretDownBold,
  PiVideoConference,
  PiDotsThreeVerticalBold,
  PiListBold,
} from "react-icons/pi";
import { LuLayoutDashboard } from "react-icons/lu";
import { BsLayoutSidebarInset, BsLayoutSidebarInsetReverse } from "react-icons/bs";
import { CgDarkMode } from "react-icons/cg";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import CompanyAvatar from "../../2 - Components/Reusable/CompanyAvatar";
import { WorkspaceContext } from "../Context/WorkspaceContext";
import { useFilterStore } from "../Stores/FilterStore";
import { debounce } from "lodash";
import { useSocket } from "../Socket/SocketContext";
import ProgressCard from "./ProgressCard";

const SideBarLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { toggleColorMode, colorMode } = useColorMode();
  const { socket, globalUnreadCount } = useSocket();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: false });
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const {
    workspaces,
    currentWorkspace,
    setCurrentWorkspace,
    setStoredWorkspaceId,
    storedWorkspaceId,
    permissions,
    canAccessModule,
    currentUserId,
    currentUserCompany,
    adminCompanyId,
    currentUser,
    currentUserCompanyId,
    isLoading,
    isError,
    error,
    themeColor,
  } = useContext(WorkspaceContext);

  // Using the updated filter store
  const FILTER_KEY = "workspaceSidebar";
  const { filters, setFilterValue, setCurrentPage } = useFilterStore();
  const filterValue = filters[FILTER_KEY]?.filterValue || "";
  const currentPage = filters[FILTER_KEY]?.currentPage || 1;
  const itemsPerPage = filters[FILTER_KEY]?.itemsPerPage || 6;

  const [filteredWorkspaces, setFilteredWorkspaces] = useState([]);

  useLayoutEffect(() => {
    if (!isMobile) {
      setCollapsed(false); // Ensure sidebar is expanded on desktop
    } else {
      setCollapsed(false); // Optionally collapse on mobile
    }
  }, [isMobile]);

  // Chakra UI color mode values
  const menuButtonBgColor = useColorModeValue("gray.50", "gray.700");
  const sidebarBgColor = useColorModeValue("gray.50", "gray.900");
  const mainContentBgColor = useColorModeValue("white", "gray.800");
  const collapseIconColor = useColorModeValue("gray.400", "gray.400");
  const borderColor = useColorModeValue("gray.200", "gray.750");
  const menuDividerColor = useColorModeValue("gray.200", "gray.600");
  const profileSectionHoverBg = useColorModeValue("gray.100", "gray.700");
  const textCategoryColor = useColorModeValue("gray.500", "gray.300");
  const mobileMenuButtonBg = useColorModeValue("gray.100", "gray.600");
  const mobileMenuButtonIcon = useColorModeValue("primary.900", "gray.300");
  const switchModeText = useColorModeValue("Switch to Dark Mode", "Switch to Light Mode");
  const switchModeIcon = useMemo(
    () => (colorMode === "light" ? <CgDarkMode fontSize="14px" /> : <CgDarkMode fontSize="14px" style={{ transform: "rotate(180deg)" }} />),
    [colorMode]
  );
  const toggleCollapse = () => setCollapsed(!collapsed);

  // Debounce search input
  const debouncedFilter = useMemo(
    () =>
      debounce((value) => {
        setFilterValue(FILTER_KEY, value);
        setCurrentPage(FILTER_KEY, 1); // Reset to the first page on new search
      }, 300),
    [setFilterValue, setCurrentPage]
  );

  useEffect(() => {
    if (storedWorkspaceId) {
      const workspace = workspaces?.find((w) => w._id === storedWorkspaceId);
      if (workspace) {
        localStorage.setItem("storedWorkspaceId", workspace._id);
        setStoredWorkspaceId(workspace._id);
        setCurrentWorkspace(workspace);
      }
    }
  }, [storedWorkspaceId, workspaces, setCurrentWorkspace, setStoredWorkspaceId, currentWorkspace]);

  useEffect(() => {
    if (currentWorkspace && !storedWorkspaceId) {
      localStorage.setItem("storedWorkspaceId", currentWorkspace._id);
      setStoredWorkspaceId(currentWorkspace._id);
    }
  }, [currentWorkspace, setStoredWorkspaceId, storedWorkspaceId]);

  useEffect(() => {
    return () => {
      debouncedFilter.cancel();
    };
  }, [debouncedFilter]);

  // Filter workspaces based on search input
  useEffect(() => {
    const filtered = filterValue
      ? (workspaces || []).filter((workspace) => workspace.companyName.toLowerCase().includes(filterValue.toLowerCase()))
      : workspaces || [];
    setFilteredWorkspaces(filtered);
  }, [filterValue, workspaces]);

  // Calculate total pages
  const totalPages = useMemo(() => Math.ceil(filteredWorkspaces?.length / itemsPerPage), [filteredWorkspaces?.length, itemsPerPage]);

  // Get workspaces to display on the current page
  const currentWorkspaces = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredWorkspaces?.slice(indexOfFirstItem, indexOfLastItem);
  }, [filteredWorkspaces, currentPage, itemsPerPage]);

  const handleSelectWorkspace = useCallback(
    (workspace) => {
      if (workspace._id !== currentWorkspace?._id) {
        setStoredWorkspaceId(workspace._id);
        setCurrentWorkspace(workspace);
        setCurrentPage(FILTER_KEY, 1);
        if (isMobile) onDrawerClose(); // Close the drawer on mobile when selecting a workspace
      }
    },
    [currentWorkspace, setCurrentWorkspace, setCurrentPage, isMobile, onDrawerClose, setStoredWorkspaceId]
  );

  const handleToggleColorMode = useCallback(() => {
    const newTheme = colorMode === "light" ? "dark" : "light";
    toggleColorMode();
    if (socket && typeof socket.emit === "function") {
      socket.emit("updateTheme", { theme: newTheme }, (response) => {
        if (response.status === "ok") {
          console.log("Theme updated successfully via socket.");
        } else {
          console.error("Error updating theme via socket:", response.message);
        }
      });
    } else {
      console.error("Socket is not connected.");
    }
  }, [toggleColorMode, colorMode, socket]);

  const profileSection = useMemo(
    () => (
      <Menu>
        <MenuButton
          as={Flex}
          alignItems="center"
          cursor="pointer"
          _hover={{ bg: profileSectionHoverBg }}
          transition="background 0.3s"
          variant="ghost"
          p={1}
        >
          <HStack spacing={0}>
            <UserAvatar userIds={[currentUserId]} size="md" showInfo={!collapsed} />
            <Icon as={PiDotsThreeVerticalBold} ml="auto" boxSize="18px" display={collapsed ? "none" : "block"} />
          </HStack>
        </MenuButton>
        {/* Keep menu list in same portal: */}
        <MenuList zIndex="1500">
          <MenuItem fontSize="md" as={RouterLink} to="/logout" icon={<PiSignOut fontSize="14px" />}>
            Logout
          </MenuItem>
          <MenuItem fontSize="md" as={RouterLink} to="/dashboard/settings" icon={<PiGear fontSize="14px" />}>
            Settings
          </MenuItem>
          <MenuItem fontSize="md" onClick={handleToggleColorMode} icon={switchModeIcon}>
            {switchModeText}
          </MenuItem>
        </MenuList>
      </Menu>
    ),
    [currentUserId, handleToggleColorMode, switchModeText, profileSectionHoverBg, switchModeIcon, collapsed]
  );

  if (isLoading || !permissions || Object.keys(permissions)?.length === 0) {
    return (
      <Flex flexDir="column" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" mb={4} />
        <Text fontSize="lg">Loading Workspace...</Text>
      </Flex>
    );
  }

  if (isError) {
    return (
      <Alert status="error" mt={4}>
        <AlertIcon />
        {error.message || "An error occurred while fetching workspace data."}
      </Alert>
    );
  }

  const SidebarContent = (
    <>
      <Flex borderBottom="1px solid" h="80px" borderColor={borderColor} justifyContent="space-between" alignItems="center" p={4}>
        <HStack spacing={2} justifyContent={collapsed ? "center" : "flex-start"} w="100%">
          <CompanyAvatar companyIds={[adminCompanyId ? adminCompanyId : currentUserCompanyId]} size="md" showInfo={!collapsed} />
        </HStack>
        {!isMobile && !collapsed && (
          <IconButton
            aria-label={collapsed ? "Expand Sidebar" : "Collapse Sidebar"}
            icon={<BsLayoutSidebarInset size="16px" />}
            size="xs"
            onClick={toggleCollapse}
            variant="ghost"
            sx={{
              svg: {
                color: collapseIconColor,
              },
            }}
          />
        )}
      </Flex>

      {/* Workspaces Section */}
      <Flex m={4} flexDir="column" justifyContent={collapsed ? "center" : "flex-start"}>
        {collapsed && !isMobile ? (
          <Tooltip label={"Expand Sidebar"} fontSize="sm" placement="right">
            <IconButton
              aria-label="Expand Sidebar"
              icon={<BsLayoutSidebarInsetReverse fontSize="16px" />}
              size="sm"
              variant="outline"
              onClick={toggleCollapse}
              sx={{ svg: { color: collapseIconColor } }}
            />
          </Tooltip>
        ) : (
          <>
            {currentUserCompany?.companyRole === "Admin Company" && currentUser?.role === "Admin" && (
              <Menu>
                <MenuButton
                  as={Button}
                  variant="outline"
                  leftIcon={<CompanyAvatar companyIds={[currentWorkspace?._id]} size="sm" showInfo={true} maxW="100px" fontWeight="500" />}
                  rightIcon={<PiCaretDownBold fontSize="14px" />}
                  size="sm"
                  w="100%"
                  py={5}
                  px={2}
                  bg={menuButtonBgColor}
                  boxShadow="base"
                />
                <MenuList>
                  <Box px={2} py={2}>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <PiMagnifyingGlass color="gray.300" />
                      </InputLeftElement>
                      <Input placeholder="Search workspaces..." onChange={(e) => debouncedFilter(e.target.value)} />
                    </InputGroup>
                  </Box>
                  {currentWorkspaces?.map((workspace) => (
                    <MenuItem key={workspace._id} onClick={() => handleSelectWorkspace(workspace)}>
                      <CompanyAvatar companyIds={[workspace._id]} fontWeight="500" size="sm" showInfo={true} />
                    </MenuItem>
                  ))}
                  <MenuDivider bg={menuDividerColor} />
                  {/* Pagination Controls */}
                  {totalPages > 1 && (
                    <Box px={2} py={2}>
                      <Flex justify="center" w="100%">
                        <ButtonGroup size="xs" variant="outline" isAttached mt={2}>
                          <Button onClick={() => setCurrentPage(FILTER_KEY, Math.max(currentPage - 1, 1))} isDisabled={currentPage === 1}>
                            Prev
                          </Button>
                          <Button cursor="default" _hover={{ bg: "transparent" }}>
                            Page {currentPage} of {totalPages}
                          </Button>
                          <Button
                            onClick={() => setCurrentPage(FILTER_KEY, Math.min(currentPage + 1, totalPages))}
                            isDisabled={currentPage === totalPages}
                          >
                            Next
                          </Button>
                        </ButtonGroup>
                      </Flex>
                    </Box>
                  )}
                </MenuList>
              </Menu>
            )}
          </>
        )}
      </Flex>

      {/* Dashboard Section */}
      <Flex p={4} pt={2} flexDirection="column">
        <Text fontSize="xs" fontWeight="600" color={textCategoryColor} pb={2}>
          Main
        </Text>
        <VStack spacing={1} align="stretch">
          <NavItem
            to="/dashboard"
            icon={LuLayoutDashboard}
            label="Dashboard"
            themeColor={themeColor}
            isCollapsed={collapsed}
            onCloseDrawer={onDrawerClose}
            isMobile={isMobile}
          />
          {canAccessModule("customers") && (
            <NavItem
              to="/dashboard/customers"
              themeColor={themeColor}
              icon={PiUsers}
              label="Customers"
              isCollapsed={collapsed}
              onCloseDrawer={onDrawerClose}
              isMobile={isMobile}
            />
          )}
        </VStack>
      </Flex>

      {/* Modules Section */}
      <Flex p={4} pt={2} flexDirection="column">
        <Text fontSize="xs" fontWeight="bold" color={textCategoryColor} pb={2}>
          Modules
        </Text>
        <VStack spacing={2} align="stretch">
          {canAccessModule("messages") && (
            <NavItem
              to="/dashboard/messages"
              icon={PiChat}
              themeColor={themeColor}
              label="Messages"
              isCollapsed={collapsed}
              badge={globalUnreadCount}
              onCloseDrawer={onDrawerClose}
              isMobile={isMobile}
            />
          )}
          {canAccessModule("files") && (
            <NavItem
              to="/dashboard/files"
              themeColor={themeColor}
              icon={PiFiles}
              label="Files"
              isCollapsed={collapsed}
              onCloseDrawer={onDrawerClose}
              isMobile={isMobile}
            />
          )}
          {canAccessModule("projects") && (
            <NavItem
              to="/dashboard/projects"
              themeColor={themeColor}
              icon={PiAlignLeft}
              label="Projects"
              isCollapsed={collapsed}
              onCloseDrawer={onDrawerClose}
              isMobile={isMobile}
            />
          )}
          {canAccessModule("contracts") && (
            <NavItem
              to="/dashboard/contracts"
              themeColor={themeColor}
              icon={PiSignature}
              label="Contracts"
              isCollapsed={collapsed}
              onCloseDrawer={onDrawerClose}
              isMobile={isMobile}
            />
          )}
          {canAccessModule("billing") && (
            <NavItem
              to="/dashboard/billing"
              themeColor={themeColor}
              icon={PiInvoice}
              label="Billing"
              isCollapsed={collapsed}
              onCloseDrawer={onDrawerClose}
              isMobile={isMobile}
            />
          )}
          {canAccessModule("reports") && (
            <NavItem
              to="/dashboard/reports"
              themeColor={themeColor}
              icon={PiChartLineUp}
              label="Reports"
              isCollapsed={collapsed}
              onCloseDrawer={onDrawerClose}
              isMobile={isMobile}
            />
          )}
          {canAccessModule("meetings") && (
            <NavItem
              to="/dashboard/meetings"
              themeColor={themeColor}
              icon={PiVideoConference}
              label="Meetings"
              isCollapsed={collapsed}
              onCloseDrawer={onDrawerClose}
              isMobile={isMobile}
            />
          )}
        </VStack>
      </Flex>

      {!collapsed && (
        <Box p={4} position="absolute" w="100%" bottom="80px" left="0px">
          <ProgressCard
            themeColor={themeColor}
            bgLight="gray.50"
            bgDark="gray.800"
            title="Complete Your Profile"
            percentage={75}
            description="Just a few more steps to finalize your account setup."
            onNextSteps={() => console.log("Go to next steps")}
          />
        </Box>
      )}

      {/* Bottom Section */}
      <Box position="absolute" w="100%" bottom="0px" left="0px" borderTop="1px solid" borderColor={borderColor}>
        <Box p={3} _hover={{ bg: profileSectionHoverBg }} transition="all 0.2s">
          {profileSection}
        </Box>
      </Box>
    </>
  );

  return (
    <Flex height="100vh" flexDirection="column" overflow="hidden">
      {/* Floating Hamburger Button on Mobile */}
      {isMobile && !isDrawerOpen && (
        <IconButton
          icon={<Icon as={PiListBold} fontSize="18px" color={mobileMenuButtonIcon} />}
          variant="solid"
          onClick={onDrawerOpen}
          border="1px solid"
          borderColor={borderColor}
          aria-label="Open Sidebar"
          position="fixed"
          size="sm"
          h="50px"
          top="40px"
          left="5px"
          transform="translate(0, -50%)"
          bg={mobileMenuButtonBg}
          zIndex={2000}
        />
      )}

      <Flex flex="1" overflow="hidden">
        {/* Desktop Sidebar */}
        {!isMobile && (
          <Box
            as="aside"
            overflow="hidden"
            w={collapsed ? "90px" : "260px"}
            bg={sidebarBgColor}
            display="flex"
            flexDirection="column"
            position="fixed"
            top="0"
            bottom="0"
            zIndex="100"
            transition="width 0.3s ease"
            overflowX="hidden"
            borderRight="1px solid"
            borderColor={borderColor}
          >
            {SidebarContent}
          </Box>
        )}

        {/* Mobile Drawer Sidebar */}
        {isMobile && (
          <Drawer isOpen={isDrawerOpen} placement="left" onClose={onDrawerClose}>
            <DrawerOverlay />
            <DrawerContent p={0} m={0} maxW="260px" bg={sidebarBgColor} minH="100vh" display="flex" flexDirection="column">
              <DrawerCloseButton />
              <DrawerBody p={0} display="flex" flexDirection="column" height="100%">
                <Box flex="1" overflowY="auto">
                  {SidebarContent}
                </Box>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        )}

        {/* Main Content */}
        <Box
          as="main"
          flex="1"
          ml={!isMobile ? (collapsed ? "90px" : "260px") : "0"}
          bg={mainContentBgColor}
          transition="margin-left 0.3s ease"
          overflow="hidden"
        >
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};

const NavItem = memo(({ to, icon, label, isCollapsed, badge, themeColor, onCloseDrawer, isMobile }) => {
  const { pathname } = useLocation();
  const activeBg = useColorModeValue(`${themeColor}.100`, `${themeColor}.800`);
  const activeNotifBg = useColorModeValue(`${themeColor}.200`, `${themeColor}.700`);
  const activeColor = useColorModeValue("black", "gray.100");
  const inactiveColor = useColorModeValue("gray.700", "gray.100");
  const hoverBg = useColorModeValue("gray.100", "gray.600");
  const hoverColor = useColorModeValue("black", "gray.100");

  return (
    <Flex
      direction="column"
      alignItems={isCollapsed ? "center" : "flex-start"}
      justifyContent={isCollapsed ? "center" : "flex-start"}
      _hover={pathname === to ? {} : { bg: hoverBg, color: hoverColor }}
      bg={pathname === to ? activeBg : "transparent"}
      transition="all 0.2s"
      color={pathname === to ? activeColor : inactiveColor}
      borderRadius="xl"
      cursor="pointer"
      w="100%"
      pr={2}
    >
      <Tooltip label={label} fontSize="sm" placement="right" isDisabled={!isCollapsed}>
        <Link
          as={RouterLink}
          cursor="pointer"
          to={to}
          style={{ textDecoration: "none" }}
          display="flex"
          justifyContent={isCollapsed ? "center" : "flex-start"}
          alignItems="center"
          p="5px"
          onClick={isMobile ? onCloseDrawer : null}
          h="30px"
          borderRadius="md"
          w="100%"
        >
          <Icon as={icon} boxSize="18px" ml={isCollapsed ? 0 : 2} />
          {!isCollapsed && (
            <>
              <Text ml={2} fontSize="sm" fontWeight="medium">
                {label}
              </Text>
              {badge !== undefined && (
                <Text bg={activeNotifBg} borderRadius="full" fontSize="sm" px={2} alignContent="center" ml="auto">
                  {badge}
                </Text>
              )}
            </>
          )}
        </Link>
      </Tooltip>
    </Flex>
  );
});

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  badge: PropTypes.number,
  themeColor: PropTypes.string.isRequired,
  onCloseDrawer: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default React.memo(SideBarLayout);
