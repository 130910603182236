// client/src/components/5 - General/Context/ProjectProvider.jsx

import React, { createContext, useState, useContext } from "react";
import {
  useCreateProject,
  useEditProject,
  useDeleteProject,
  useToggleArchiveProject,
  useCreateTask,
  useEditTask,
  useDeleteTask,
  useAssignMembers,
  useAssignUsersToTask,
  useAddComment,
  useEditComment,
  useDeleteComment,
  useUpdateTaskOrder,
  useLikeComment,
} from "../Hooks/useUpdateProject";
import { getProjectIds, getProjectById, fetchArchivedProjects, getTaskById } from "../../4 - API/API-Projects";
import { WorkspaceContext } from "./WorkspaceContext";
import { useQueries, useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../queryKeys";

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const { currentWorkspace } = useContext(WorkspaceContext);

  // Socket.io-based mutations
  const createProjectMutation = useCreateProject();
  const editProjectMutation = useEditProject();
  const deleteProjectMutation = useDeleteProject();
  const toggleArchiveProjectMutation = useToggleArchiveProject();
  const createTaskMutation = useCreateTask();
  const editTaskMutation = useEditTask();
  const deleteTaskMutation = useDeleteTask();
  const assignMembersMutation = useAssignMembers();
  const assignUsersToTaskMutation = useAssignUsersToTask();
  const addCommentMutation = useAddComment();
  const editCommentMutation = useEditComment();
  const deleteCommentMutation = useDeleteComment();
  const likeCommentMutation = useLikeComment();
  const updateTaskOrderMutation = useUpdateTaskOrder();

  // State variables
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [currentSubtaskId, setCurrentSubtaskId] = useState(null);

  // Fetch active project IDs
  const {
    data: projectIds = [],
    isLoading: isProjectIdsLoading,
    error: projectIdsError,
  } = useQuery({
    queryKey: queryKeys.projectIds(currentWorkspace?._id),
    queryFn: () => getProjectIds(currentWorkspace._id),
    enabled: !!currentWorkspace,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });

  // Fetch multiple projects based on projectIds
  const activeProjectQueries = useQueries({
    queries: projectIds.map((id) => ({
      queryKey: queryKeys.project(id),
      queryFn: () => getProjectById(currentWorkspace._id, id),
      enabled: !!id && !!currentWorkspace,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    })),
  });

  const isActiveProjectsLoading = activeProjectQueries.some((query) => query.isLoading);
  const activeProjectsError = activeProjectQueries.find((query) => query.isError)?.error;
  const activeProjects = activeProjectQueries.map((q) => q.data).filter(Boolean);

  // Fetch archived projects
  const {
    data: archivedProjects = [],
    isLoading: isArchivedProjectsLoading,
    error: archivedProjectsError,
  } = useQuery({
    queryKey: queryKeys.archivedProject(currentWorkspace?._id),
    queryFn: () => fetchArchivedProjects(currentWorkspace._id),
    enabled: !!currentWorkspace,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });

  // Fetch tasks associated with the current project
  const taskIds = currentProjectId ? (activeProjects.find((p) => p._id === currentProjectId)?.tasks || []).map((task) => task._id) : [];
  const taskQueries = useQueries({
    queries: taskIds.map((taskId) => ({
      queryKey: queryKeys.task(taskId, currentProjectId),
      queryFn: () => getTaskById(currentProjectId, taskId),
      enabled: !!taskId && !!currentProjectId,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    })),
  });

  const isTasksLoading = taskQueries.some((query) => query.isLoading);
  const tasksError = taskQueries.find((query) => query.isError)?.error;
  const tasks = taskQueries.map((q) => q.data).filter(Boolean);

  // Fetch subtasks associated with the current task or project
  // Corrected to properly extract subtask IDs
  const subTaskIds = currentTaskId ? (tasks.find((t) => t._id === currentTaskId)?.subtasks || []).map((subtask) => subtask._id) : [];

  const subtasksQueries = useQueries({
    queries: subTaskIds.map((id) => ({
      queryKey: queryKeys.subtask(id, currentProjectId),
      queryFn: () => getTaskById(currentProjectId, id), // Fetching subtask details
      enabled: !!id && !!currentProjectId,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    })),
  });

  const isSubtasksLoading = subtasksQueries.some((query) => query.isLoading);
  const subtasksError = subtasksQueries.find((query) => query.isError)?.error;
  const subtasks = subtasksQueries.map((q) => q.data).filter(Boolean);

  // Consolidate errors and loading states for unified error handling
  const hasError = projectIdsError || activeProjectsError || archivedProjectsError || tasksError || subtasksError;
  const isLoading = isProjectIdsLoading || isActiveProjectsLoading || isArchivedProjectsLoading || isTasksLoading || isSubtasksLoading;

  // Project Management Functions
  const createProject = async (projectData) => {
    try {
      await createProjectMutation.mutateAsync(projectData);
    } catch (error) {
      console.error("Error creating project in context:", error);
    }
  };

  const editProject = async (projectId, updates) => {
    try {
      await editProjectMutation.mutateAsync({ projectId, updates });
    } catch (error) {
      console.error("Error editing project in context:", error);
    }
  };

  const deleteProject = async (projectId) => {
    try {
      await deleteProjectMutation.mutateAsync({ projectId });
    } catch (error) {
      console.error("Error deleting project in context:", error);
    }
  };

  const toggleArchiveProject = async (projectId) => {
    try {
      await toggleArchiveProjectMutation.mutateAsync({ projectId });
    } catch (error) {
      console.error("Error toggling archive status in context:", error);
    }
  };

  // Task Management Functions
  const createTask = async (taskData) => {
    try {
      await createTaskMutation.mutateAsync(taskData);
    } catch (error) {
      console.error("Error creating task in context:", error);
    }
  };

  const editTask = async (taskId, updates) => {
    try {
      await editTaskMutation.mutateAsync({ taskId, updates });
    } catch (error) {
      console.error("Error editing task in context:", error);
    }
  };

  const deleteTask = async (taskId) => {
    try {
      await deleteTaskMutation.mutateAsync({ taskId });
    } catch (error) {
      console.error("Error deleting task in context:", error);
    }
  };

  // Member Management Function
  const assignMembersToProject = async ({ projectId, memberIds }) => {
    try {
      await assignMembersMutation.mutateAsync({ projectId, memberIds });
    } catch (error) {
      console.error("Error assigning members to project in context:", error);
    }
  };

  // New Member Management Function: Assign Users to Task
  const assignUsersToTask = async ({ taskId, userIds }) => {
    try {
      await assignUsersToTaskMutation.mutateAsync({ taskId, userIds });
    } catch (error) {
      console.error("Error assigning users to task in context:", error);
    }
  };

  // Comments
  const addComment = async (taskId, commentText) => {
    try {
      await addCommentMutation.mutateAsync({ taskId, commentText });
    } catch (error) {
      console.error("Error adding comment in context:", error);
    }
  };

  const editComment = async (taskId, commentId, editedCommentText) => {
    try {
      await editCommentMutation.mutateAsync({ taskId, commentId, editedCommentText });
    } catch (error) {
      console.error("Error editing comment in context:", error);
    }
  };

  const deleteComment = async (taskId, commentId) => {
    try {
      await deleteCommentMutation.mutateAsync({ taskId, commentId });
    } catch (error) {
      console.error("Error deleting comment in context:", error);
    }
  };

  const likeComment = async (taskId, commentId) => {
    try {
      await likeCommentMutation.mutateAsync({ taskId, commentId });
    } catch (error) {
      console.error("Error liking comment in context:", error);
    }
  };

  const updateTaskOrder = async (tasks) => {
    try {
      await updateTaskOrderMutation.mutateAsync(tasks);
    } catch (error) {
      // Error handling is already managed within the hook
      console.error("Error updating task order in context:", error);
    }
  };

  const contextValue = {
    // States
    archivedProjects,
    activeProjects,
    tasks,
    subtasks,
    currentTaskId,
    setCurrentTaskId,
    currentProjectId,
    setCurrentProjectId,
    currentSubtaskId,
    setCurrentSubtaskId,

    // Loading and error states
    isLoading,
    hasError,
    projectIdsError,
    activeProjectsError,
    archivedProjectsError,
    tasksError,
    subtasksError,

    // Project Management
    createProject,
    editProject,
    deleteProject,
    toggleArchiveProject,

    // Task Management
    createTask,
    editTask,
    deleteTask,
    updateTaskOrder,

    // Comments
    addComment,
    editComment,
    deleteComment,
    likeComment,

    // Member Management
    assignMembersToProject,
    assignUsersToTask, // Newly added function
  };

  return <ProjectContext.Provider value={contextValue}>{children}</ProjectContext.Provider>;
};
