// client/src/components/2 - Components/SettingsPageAccount.js

import React, { useState, useEffect, useContext } from "react";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";
import {
  Box,
  Flex,
  Button,
  Input,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
  FormControl,
  FormLabel,
  Divider,
  Grid,
  GridItem,
  useDisclosure,
  Heading,
  Select,
  HStack,
  Icon,
  Link,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import UserAvatar from "../2 - Components/Reusable/UserAvatar";
import { PiCameraBold, PiLink } from "react-icons/pi";
import ProfilePictureModal from "../3 - Modal/8 - Settings Modals/ProfilePictureModal";
import UtilsDatePicker from "../5 - General/Utils/UtilsDatePicker";
import InputMask from "react-input-mask";
import { useUpdateUser, useUploadProfilePicture } from "../5 - General/Hooks/useUpdateUser";

const MaskedInput = ({ mask, value, onChange, placeholder, ...props }) => (
  <Input as={InputMask} mask={mask} value={value} onChange={onChange} placeholder={placeholder} {...props} />
);

const SidePanelContent = ({ themeColor }) => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.600", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.750");
  const linkColor = useColorModeValue(`${themeColor}.600`, `${themeColor}.400`);
  const iconColor = useColorModeValue(`${themeColor}.600`, `${themeColor}.400`);

  return (
    <Box
      // Separate border properties to ensure correct application
      borderLeftWidth={{ base: "0", md: "1px" }}
      borderLeftStyle={{ base: "none", md: "solid" }}
      borderLeftColor={borderColor}
      position={{ base: "static", md: "sticky" }}
      top="0"
      right="0"
      minW={{ base: "full", md: "350px" }}
      w={{ base: "full", md: "350px" }}
      height={{ base: "auto", md: "100vh" }}
      bg={bgColor}
      p={8}
      overflowY="auto"
      // Custom scrollbar styles
      sx={{
        /* WebKit-based browsers */
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "4px",
          backgroundColor: useColorModeValue("gray.300", "gray.600"),
        },
        /* Firefox */
        scrollbarWidth: "thin",
        scrollbarColor: `${useColorModeValue("gray.300", "gray.600")} transparent`,
      }}
    >
      <Heading as="h2" fontWeight="600" size="md" mb={4} color={textColor}>
        Account Settings
      </Heading>
      <Text fontSize="md" mb={6} color={textColor}>
        Manage your personal account details, security settings, and preferences to customize your experience.
      </Text>

      {/* Personal Information */}
      <Heading as="h3" fontWeight="600" fontSize="14px" mb={2} color={textColor}>
        Personal Information
      </Heading>
      <Text fontSize="sm" mb={4} color={textColor}>
        Update your name, contact information, and other personal details to keep your account accurate.
      </Text>

      {/* Security Settings */}
      <Heading as="h3" fontWeight="600" fontSize="14px" mb={2} color={textColor}>
        Security Settings
      </Heading>
      <Text fontSize="sm" mb={4} color={textColor}>
        Manage your password, two-factor authentication, and other security options to protect your account.
      </Text>

      {/* Preferences */}
      <Heading as="h3" fontWeight="600" fontSize="14px" mb={2} color={textColor}>
        Preferences
      </Heading>
      <Text fontSize="sm" mb={4} color={textColor}>
        Customize your notification settings, language, and other preferences to enhance your user experience.
      </Text>

      <Divider borderColor={textColor} my={6} />

      <HStack spacing={1} mb={4} alignContent="center">
        <Text fontSize="sm" color={textColor}>
          Learn more about
        </Text>
        <Link href="#" fontSize="sm" color={linkColor} cursor="pointer" _hover={{ textDecoration: "underline" }}>
          Account Settings
        </Link>
        <Icon as={PiLink} fontSize="18px" color={iconColor} />
      </HStack>
    </Box>
  );
};

function SettingsPageAccount() {
  const { currentUser, currentUserId, themeColor } = useContext(WorkspaceContext);
  const { isOpen, onOpen, onClose } = useDisclosure(); // For ProfilePictureModal
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure(); // For SidePanel Drawer
  const [temporaryPreview, setTemporaryPreview] = useState(null);
  const [initialFormData, setInitialFormData] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const updateUserMutation = useUpdateUser();
  const uploadProfilePictureMutation = useUploadProfilePicture();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    dateOfBirth: "",
    street: "",
    city: "",
    province: "",
    postalCode: "",
    country: "Canada",
    preferredLanguage: "English",
    jobTitle: "",
    industry: "",
    _id: "",
  });

  // Determine if the device is mobile
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Define color variables based on color mode
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "gray.200");
  const bgTopBarColor = useColorModeValue("gray.50", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const sidePanelDrawerBg = useColorModeValue("gray.50", "gray.900");

  // Additional color definitions
  const inputBgColor = useColorModeValue("gray.50", "gray.700");
  const dividerColor = useColorModeValue("gray.200", "gray.700");

  // Fetch current user data using WorkspaceContext
  useEffect(() => {
    if (currentUser) {
      const userData = {
        firstName: currentUser.firstName || "",
        lastName: currentUser.lastName || "",
        phoneNumber: currentUser.phoneNumber || "",
        dateOfBirth: currentUser.dateOfBirth ? new Date(currentUser.dateOfBirth).toISOString() : "",
        street: currentUser.street || "",
        city: currentUser.city || "",
        province: currentUser.province || "",
        postalCode: currentUser.postalCode || "",
        country: currentUser.country || "Canada",
        preferredLanguage: currentUser.preferredLanguage || "English",
        jobTitle: currentUser.jobTitle || "",
        industry: currentUser.industry || "",
        _id: currentUser._id || "",
      };
      setFormData(userData);
      setInitialFormData(userData);
    }
  }, [currentUser]);

  const handleSave = () => {
    const updatedFields = { ...formData };
    updateUserMutation.mutate({ userId: currentUserId, updates: updatedFields });
    setIsModified(false);
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    setIsModified(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsModified(true);
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      dateOfBirth: date,
    }));
    if (date !== new Date(formData.dateOfBirth)) setIsModified(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTemporaryPreview(URL.createObjectURL(file));
      onOpen();
    }
  };

  const handleCropSubmit = (croppedBlob) => {
    const file = new File([croppedBlob], "profilePicture.jpeg", { type: "image/jpeg" });
    console.log(currentUserId);
    uploadProfilePictureMutation.mutate({ userId: currentUserId, file: file });
  };

  if (!currentUser) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex direction="row" w="100%" h="100vh">
      {/* Main Content */}
      <Box
        bg={bgColor}
        flex="1"
        overflowY="auto"
        sx={{
          /* WebKit-based browsers */
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
          },
          /* Firefox */
          scrollbarWidth: "thin",
        }}
      >
        <VStack align="stretch" width="100%">
          {/* Top Bar */}
          <Flex
            width="100%"
            justify="space-between"
            bg={bgTopBarColor}
            position="sticky"
            top="0"
            zIndex={10}
            alignItems="center"
            mb={8}
            px={8}
            borderBottom="1px solid"
            borderColor={borderColor}
            height="80px"
          >
            <Flex align="center" flexDir={{ base: "row", md: "row" }} textAlign="left">
              <Text fontSize="xl" pl={{ base: "20px", md: 0 }} fontWeight="500" color={textColor}>
                Account Settings
              </Text>
            </Flex>
            {/* Buttons */}
            {isMobile ? (
              <VStack spacing={2} align="flex-end" width="30%">
                <Button variant="outline" onClick={handleCancel} colorScheme="gray" isDisabled={!isModified} width="full">
                  Cancel{isMobile ? "" : " Changes"}
                </Button>
                <Button
                  colorScheme={themeColor}
                  onClick={handleSave}
                  isDisabled={!isModified || updateUserMutation.isLoading}
                  isLoading={updateUserMutation.isLoading}
                  width="full"
                >
                  Save{isMobile ? "" : " Changes"}
                </Button>
              </VStack>
            ) : (
              <Flex>
                <Button variant="outline" onClick={handleCancel} mr={3} colorScheme="gray" isDisabled={!isModified}>
                  Cancel Changes
                </Button>
                <Button
                  colorScheme={themeColor}
                  onClick={handleSave}
                  isDisabled={!isModified || updateUserMutation.isLoading}
                  isLoading={updateUserMutation.isLoading}
                >
                  Save Changes
                </Button>
              </Flex>
            )}
          </Flex>
          {/* Form Content */}
          <VStack spacing={8} align="stretch" width="100%" px={8} py={12} pt={0}>
            {isMobile && (
              <Button
                alignSelf="start"
                color="primary.500"
                aria-label="Open Settings"
                leftIcon={<HamburgerIcon />}
                variant="ghost"
                onClick={onDrawerOpen}
              >
                Learn more about Account Settings
              </Button>
            )}
            <FormControl>
              <FormLabel color={textColor}>Profile Picture</FormLabel>
              <Flex alignItems="center">
                <Box position="relative" cursor="pointer" onClick={onOpen} ml={4}>
                  <UserAvatar userIds={[currentUserId]} size="xl" />
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    bottom="0"
                    w="auto"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    opacity={0}
                    transition="opacity 0.2s"
                    _hover={{ opacity: 1 }}
                    bg="rgba(0, 0, 0, 0.5)"
                    borderRadius="full"
                    zIndex={1}
                    sx={{ aspectRatio: "1 / 1" }}
                  >
                    <PiCameraBold color="white" size={20} />
                  </Box>
                </Box>
                <Input type="file" accept="image/*" onChange={handleFileChange} display="none" />
              </Flex>
            </FormControl>

            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={8} mt={8}>
              <GridItem>
                <FormControl>
                  <FormLabel color={textColor}>First Name</FormLabel>
                  <Input name="firstName" type="text" value={formData.firstName} onChange={handleChange} required bg={inputBgColor} />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel color={textColor}>Last Name</FormLabel>
                  <Input name="lastName" type="text" value={formData.lastName} onChange={handleChange} required bg={inputBgColor} />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel color={textColor}>Cell Number</FormLabel>
                  <MaskedInput
                    mask="(999) 999-9999"
                    value={formData.phoneNumber}
                    onChange={(e) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        phoneNumber: e.target.value,
                      }));
                      setIsModified(true);
                    }}
                    placeholder="(123) 456-7890"
                    type="text"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel color={textColor}>Birthdate</FormLabel>
                  <UtilsDatePicker
                    value={formData.dateOfBirth ? new Date(formData.dateOfBirth) : null}
                    onChange={(date) => {
                      handleDateChange(date);
                    }}
                    endDate={new Date()}
                    placeholder="Select your birthdate"
                    initialView="value"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>
            </Grid>

            <Divider borderColor={dividerColor} />

            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={8}>
              <GridItem colSpan={{ base: 1, md: 2 }}>
                <FormControl>
                  <FormLabel color={textColor}>Street</FormLabel>
                  <Input name="street" type="text" value={formData.street} onChange={handleChange} required bg={inputBgColor} />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel color={textColor}>City</FormLabel>
                  <Input name="city" type="text" value={formData.city} onChange={handleChange} required bg={inputBgColor} />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel color={textColor}>Province</FormLabel>
                  <Input name="province" type="text" value={formData.province} onChange={handleChange} required bg={inputBgColor} />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel color={textColor}>Postal Code</FormLabel>
                  <Input name="postalCode" type="text" value={formData.postalCode} onChange={handleChange} required bg={inputBgColor} />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel color={textColor}>Country</FormLabel>
                  <Input name="country" type="text" value={formData.country} onChange={handleChange} required bg={inputBgColor} />
                </FormControl>
              </GridItem>
            </Grid>

            <Divider borderColor={dividerColor} />

            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={8}>
              <GridItem>
                <FormControl>
                  <FormLabel color={textColor}>Job Title</FormLabel>
                  <Input name="jobTitle" type="text" value={formData.jobTitle} onChange={handleChange} required bg={inputBgColor} />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel color={textColor}>Sector</FormLabel>
                  <Input name="industry" type="text" value={formData.industry} onChange={handleChange} required bg={inputBgColor} />
                </FormControl>
              </GridItem>
            </Grid>

            <Divider borderColor={dividerColor} />

            <FormControl>
              <FormLabel color={textColor}>Language</FormLabel>
              <Select name="preferredLanguage" value={formData.preferredLanguage} onChange={handleChange} required bg={inputBgColor}>
                <option value="French">Français</option>
                <option value="English">English</option>
              </Select>
            </FormControl>
          </VStack>
        </VStack>
      </Box>

      {/* Desktop Side Panel */}
      {!isMobile && <SidePanelContent themeColor={themeColor} />}

      {/* Mobile Side Panel Drawer */}
      {isMobile && (
        <Drawer placement="right" onClose={onDrawerClose} isOpen={isDrawerOpen}>
          <DrawerOverlay>
            <DrawerContent bg={sidePanelDrawerBg}>
              <DrawerCloseButton />
              <DrawerBody p={0}>
                <SidePanelContent themeColor={themeColor} />
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      )}

      {/* Profile Picture Modal */}
      <ProfilePictureModal
        themeColor={themeColor}
        isOpen={isOpen}
        onClose={onClose}
        onCropSubmit={handleCropSubmit}
        initialPreview={temporaryPreview}
      />
    </Flex>
  );
}

export default React.memo(SettingsPageAccount);
