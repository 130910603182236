// client/src/components/Projects.js

import React, { useContext, useEffect, useMemo, useCallback } from "react";
import { Flex, Spinner, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text, useDisclosure, useColorModeValue, Button } from "@chakra-ui/react";
import { PiCaretRightBold, PiPlus } from "react-icons/pi";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";
import ProjectCards from "./ProjectsCards";
import CreateProjectModal from "../3 - Modal/3 - Projects Modals/ProjectsCreateProjectModal";
import TaskGroups from "./ProjectsTaskGroup";
import { useParams, useNavigate } from "react-router-dom"; // Import hooks

const Projects = () => {
  const { currentWorkspace, themeColor, isWorkspaceLoading } = useContext(WorkspaceContext);
  const {
    isLoading,
    hasError,
    setCurrentProjectId,
    currentProjectId,
    tasks,
    activeProjects,
    currentTaskId,
    setCurrentTaskId,
    setCurrentSubtaskId,
  } = useContext(ProjectContext);
  const currentProject = activeProjects.find((project) => project._id === currentProjectId);
  const currentTask = tasks.find((task) => task._id === currentTaskId);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const { projectId, taskId, subtaskId } = useParams(); // Extract URL params

  // Synchronize URL params with context state
  useEffect(() => {
    setCurrentProjectId(projectId || null);
    setCurrentTaskId(taskId || null);
    setCurrentSubtaskId(subtaskId || null);
  }, [projectId, taskId, subtaskId, setCurrentProjectId, setCurrentTaskId, setCurrentSubtaskId]);

  const borderColor = useColorModeValue("gray.200", "gray.750");
  const breadcrumbTextColor = useColorModeValue("black", "white");
  const breadcrumbLinkColor = useColorModeValue("gray.500", "gray.400");
  const spinnerColor = useColorModeValue(`${themeColor}.500`, `${themeColor}.200`);
  const bgColor = useColorModeValue("white", "gray.800");
  const topBarBgColor = useColorModeValue("gray.50", "gray.900");

  // Reset project, task, and subtask selections when currentWorkspace changes
  // Put the route params into context state once we know the workspace is loaded
  useEffect(() => {
    // Only set IDs if the workspace is ready
    // (so we don’t overwrite them with null while loading).
    if (!isWorkspaceLoading && currentWorkspace) {
      setCurrentProjectId(projectId || null);
      setCurrentTaskId(taskId || null);
      setCurrentSubtaskId(subtaskId || null);
    }
  }, [
    projectId,
    taskId,
    subtaskId,
    currentWorkspace,
    isWorkspaceLoading,
    setCurrentProjectId,
    setCurrentTaskId,
    setCurrentSubtaskId
  ]);

  // Compute Breadcrumb Dynamically
  const breadcrumb = useMemo(() => {
    const crumbs = ["Projects"];

    if (currentWorkspace) {
      crumbs.push(currentWorkspace.companyName || "Select a Company");
    }

    if (currentProject) {
      crumbs.push(currentProject.name || "Unnamed Project");
    }

    if (currentTask) {
      crumbs.push(currentTask.name || "Unnamed Task");
    }

    return crumbs;
  }, [currentWorkspace, currentProject, currentTask /*, currentSubtask*/]);

  // Handle Breadcrumb Navigation
  const handleBreadcrumbClick = useCallback(
    (crumb, index) => {
      if (crumb === "Projects") {
        navigate("/dashboard/projects");
        setCurrentProjectId(null);
        setCurrentTaskId(null);
        setCurrentSubtaskId(null);
      } else if (crumb === currentWorkspace?.companyName) {
        navigate("/dashboard/projects");
        setCurrentProjectId(null);
        setCurrentTaskId(null);
        setCurrentSubtaskId(null);
      } else if (crumb === currentProject?.name) {
        navigate(`/dashboard/projects/${currentProjectId}`);
        setCurrentTaskId(null);
        setCurrentSubtaskId(null);
      } else if (crumb === currentTask?.name) {
        navigate(`/dashboard/projects/${currentProjectId}/${currentTaskId}`);
        setCurrentSubtaskId(null);
      }
      // Add handling for subtask if implemented
    },
    [
      navigate,
      currentWorkspace,
      currentProject,
      currentProjectId,
      currentTask,
      currentTaskId,
      setCurrentProjectId,
      setCurrentTaskId,
      setCurrentSubtaskId,
    ]
  );

  // Handle Edge Case: currentWorkspace still null
  if (!currentWorkspace) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" color={spinnerColor} />
      </Flex>
    );
  }

  return (
    <Flex height="100%" flex="1" boxShadow="lg" borderRadius="lg" flexDirection="column" overflow="auto" bg={bgColor}>
      {/* Top Bar with Breadcrumb */}
      <Flex
        justify="space-between"
        align="center"
        position="sticky"
        top={0}
        zIndex={1}
        pl={6}
        pr={6}
        h="80px"
        minH="80px"
        borderBottom="1px solid"
        borderColor={borderColor}
        bg={topBarBgColor}
      >
        {/* Breadcrumb */}
        <HStack spacing={4}>
          <Breadcrumb spacing="8px" fontSize="lg" separator={<PiCaretRightBold size="12px" color="gray.500" />}>
            {breadcrumb.map((crumb, index) => (
              <BreadcrumbItem key={index}>
                {index === 0 ? (
                  // Make the first breadcrumb item unclickable and inactive
                  <Text fontWeight="normal" color="gray.500" fontSize="lg">
                    {crumb}
                  </Text>
                ) : index === breadcrumb.length - 1 ? (
                  // Last breadcrumb item (active, not clickable)
                  <Text color={breadcrumbTextColor} fontWeight="500">
                    {crumb}
                  </Text>
                ) : (
                  // All other breadcrumb items (clickable)
                  <BreadcrumbLink
                    onClick={() => handleBreadcrumbClick(crumb, index)}
                    cursor="pointer"
                    color={breadcrumbLinkColor}
                    fontWeight="normal"
                  >
                    {crumb}
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </HStack>
        <Button onClick={onOpen} colorScheme={themeColor} leftIcon={<PiPlus fontSize="18px" />}>
          Create Project
        </Button>
      </Flex>

      {/* Main Content */}
      <Flex flex="1" height="100%" overflow="auto">
        {isLoading ? (
          // Show spinner until the fetch completes
          <Flex justify="center" align="center" height="100%" width="100%">
            <Spinner size="xl" color={spinnerColor} />
          </Flex>
        ) : hasError ? (
          // Show error message
          <Flex justify="center" align="center" height="100%" width="100%">
            <Text fontSize="xl" color="red.500">
              An error occurred while fetching projects.
            </Text>
          </Flex>
        ) : !currentProjectId ? (
          <ProjectCards />
        ) : (
          <TaskGroups />
        )}
      </Flex>

      {/* Create Project Modal */}
      <CreateProjectModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default Projects;
