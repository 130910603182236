// client/src/components/Files/FilesTopBar.jsx

import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import {
  Flex,
  Box,
  Text,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Collapse,
  useColorModeValue,
  MenuDivider,
  Tag,
  Tooltip,
  useBreakpointValue,
  Portal, // <-- Import Portal from Chakra UI
} from "@chakra-ui/react";
import { PiCaretRightBold, PiTrash, PiLink, PiFolderSimple } from "react-icons/pi";
import { BsFileEarmarkPlus, BsFolderPlus } from "react-icons/bs";
import { TbPlus } from "react-icons/tb";
import { useDrop } from "react-dnd";
import { FilesContext } from "../5 - General/Context/FilesContext";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";
import useCustomToast from "../5 - General/Utils/UtilsNotification";
import { ArrowBackIcon } from "@chakra-ui/icons";

const ItemTypes = {
  FILE: "file",
};

// Define the BreadcrumbItem component
const BreadcrumbItem = React.forwardRef(({ folder, isLast, handleClick }, ref) => {
  const { moveItems } = useContext(FilesContext);
  const { themeColor, currentWorkspace } = useContext(WorkspaceContext); // Access themeColor
  const customToast = useCustomToast();

  // ** Define color variables using useColorModeValue **
  const canDropBgColor = useColorModeValue(`${themeColor}.100`, `${themeColor}.700`);
  const breadcrumbHoverBgColor = useColorModeValue(`${themeColor}.100`, `${themeColor}.700`);
  const breadcrumbTextColor = useColorModeValue("black", "white");
  const breadcrumbTextLightColor = useColorModeValue("gray.500", "gray.400");

  // Only make the breadcrumb a drop target if it's not the last breadcrumb
  const isDroppable = !isLast;

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.FILE,
    canDrop: () => isDroppable,
    drop: (item) => {
      const folderId = folder ? folder._id : null;
      // Prevent moving a folder into itself or its descendants
      if (item.files.some((f) => f._id === folderId)) {
        customToast({
          title: "Invalid Move",
          description: "Cannot move a folder into itself or its subfolder.",
          status: "error",
        });
        return;
      }

      moveItems(
        item.files.map((file) => file._id),
        folderId
      ).catch((error) => {
        customToast({
          title: "Move Failed",
          description: "An error occurred while moving the files.",
          status: "error",
        });
        console.error("Error moving files:", error);
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  const bgColor = isOver && canDrop ? canDropBgColor : "transparent";
  const cursorStyle = isDroppable ? "pointer" : "default";
  return (
    <Box
      ref={isDroppable ? drop : null}
      p={2}
      borderRadius="md"
      _hover={isDroppable ? { textDecoration: "underline", bg: breadcrumbHoverBgColor, borderRadius: "xl" } : {}}
      cursor={cursorStyle}
      transition="all 0.2s"
      bg={bgColor}
      onClick={isDroppable ? () => handleClick(folder) : undefined}
    >
      <Text fontWeight="500" isTruncated fontSize="lg" color={isLast ? breadcrumbTextColor : breadcrumbTextLightColor}>
        {folder ? folder.name : currentWorkspace?.companyName || "Root"}
      </Text>
    </Box>
  );
});

BreadcrumbItem.displayName = "BreadcrumbItem";

const FilesTopBar = () => {
  const {
    folderPath,
    selectedItems,
    handleMassDelete,
    moveItems,
    currentFolderId,
    onOpenImportModal,
    onOpenCreateLinkModal,
    handleImportFolder,
    onOpenCreateFolderModal,
    handleBreadcrumbClick,
    handleGoBack,
  } = useContext(FilesContext);

  const { themeColor, currentWorkspace } = useContext(WorkspaceContext); // Access themeColor
  const customToast = useCustomToast();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isInRootFolder = currentFolderId === null;

  // ** Define color variables using useColorModeValue **
  const borderColor = useColorModeValue("gray.200", "gray.750");
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textLightColor = useColorModeValue("gray.600", "gray.400");
  const menuItemHoverBg = useColorModeValue("gray.100", "gray.700");
  const filesBreadcumbTextColor = useColorModeValue("gray.500", "gray.400");
  const mobileSelectedMenuBorderColor = useColorModeValue("gray.200", "gray.750");

  const [isBreadcrumbCompact, setIsBreadcrumbCompact] = useState(false);

  // Refs for measuring widths and visibility
  const containerRef = useRef();
  const lastBreadcrumbRef = useRef();

  const handleAddNew = (type) => {
    switch (type) {
      case "importFile":
        onOpenImportModal();
        break;
      case "createFolder":
        onOpenCreateFolderModal();
        break;
      case "createLink":
        onOpenCreateLinkModal();
        break;
      default:
        break;
    }
  };

  useLayoutEffect(() => {
    checkBreadcrumbWidth();
    window.addEventListener("resize", checkBreadcrumbWidth);
    return () => {
      window.removeEventListener("resize", checkBreadcrumbWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderPath]);

  const checkBreadcrumbWidth = () => {
    const container = containerRef.current;
    const lastBreadcrumb = lastBreadcrumbRef.current;

    if (container && lastBreadcrumb) {
      const containerRect = container.getBoundingClientRect();
      const lastBreadcrumbRect = lastBreadcrumb.getBoundingClientRect();

      // Check if the last breadcrumb's right edge is within the container's right edge
      const isInView = lastBreadcrumbRect.right <= containerRect.right;

      setIsBreadcrumbCompact(!isInView);
    }
  };

  const folderInputRef = useRef();

  const triggerFolderInput = () => {
    if (folderInputRef.current) {
      folderInputRef.current.click();
    }
  };

  const onFolderSelected = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      handleImportFolder(files);
    }
    event.target.value = null;
  };

  const handleMoveHere = async (folderId) => {
    if (selectedItems.size === 0) {
      customToast({
        title: "No Selection",
        description: "Please select at least one file to move.",
        status: "warning",
      });
      return;
    }
    try {
      await moveItems(Array.from(selectedItems), folderId);
    } catch (error) {
      customToast({
        title: "Move Failed",
        description: `Failed to move selected items.`,
        status: "error",
      });
      console.error(error);
    }
  };

  const renderFolderMenuItems = () => {
    const menuItems = [
      <MenuItem key="menu-company" spacing={2} _hover={{ bg: menuItemHoverBg }} onClick={() => handleMoveHere(null)} align="center">
        <Tooltip key="menu-tooltip-company" fontSize="sm" label="Click to move selected files to: Company Root" placement="left">
          <Box key="menu-box-company" cursor="pointer" display="flex" alignItems="center">
            <Box mr={4}>
              <PiFolderSimple fontSize="20px" color={`${themeColor}.500`} /> {/* Dynamic color */}
            </Box>
            <Text whiteSpace="nowrap" fontSize="md" isTruncated userSelect="none">
              {currentWorkspace?.companyName || "Root"}
            </Text>
          </Box>
        </Tooltip>
      </MenuItem>,
    ];

    menuItems.push(
      ...folderPath.map((folder, index) => (
        <MenuItem key={`menu-${folder._id}`} onClick={() => handleMoveHere(folder._id)} _hover={{ bg: menuItemHoverBg }}>
          <Tooltip key={`menu-tooltip-${folder._id}`} fontSize="sm" label={`Click to move selected files to: ${folder.name}`} placement="left">
            <Box key={`menu-box-${folder._id}`} display="flex" alignItems="center">
              <Box mr={4}>
                <PiFolderSimple fontSize="20px" color={`${themeColor}.500`} /> {/* Dynamic color */}
              </Box>
              <Text whiteSpace="nowrap" fontSize="md" isTruncated userSelect="none">
                {folder.name}
              </Text>
            </Box>
          </Tooltip>
        </MenuItem>
      ))
    );

    return menuItems;
  };

  const renderBreadcrumbs = () => {
    if (!currentWorkspace) {
      return <Text color="gray.500">Please select a company to view files.</Text>;
    }

    // Unclickable 'Files' breadcrumb
    const filesBreadcrumb = (
      <HStack key="breadcrumb-files" spacing={2} mr={4} align="center">
        <Text fontWeight="500" fontSize="lg" color={filesBreadcumbTextColor}>
          Files
        </Text>
      </HStack>
    );

    // Clickable root breadcrumb
    const rootBreadcrumb = (
      <HStack key="breadcrumb-root" spacing={2} align="center">
        <PiCaretRightBold />
        <BreadcrumbItem
          folder={null}
          isLast={folderPath.length === 0}
          handleClick={() => handleBreadcrumbClick(-1)} // Pass -1 for root
        />
      </HStack>
    );

    if (isBreadcrumbCompact && folderPath.length > 1) {
      return (
        <>
          {filesBreadcrumb}
          {rootBreadcrumb}
          <HStack spacing={2} align="center">
            <PiCaretRightBold />
            <Menu>
              <Tooltip label="Show intermediate folders">
                <MenuButton>
                  <Text fontWeight="500" color={textLightColor} cursor="pointer">
                    [...]
                  </Text>
                </MenuButton>
              </Tooltip>
              <MenuList>
                {folderPath.map((folder, index) => (
                  <MenuItem key={`menu-${folder._id}`} onClick={() => handleBreadcrumbClick(index)} _hover={{ bg: menuItemHoverBg }}>
                    {folder.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </HStack>
          <HStack spacing={2} align="center">
            <PiCaretRightBold />
            {folderPath.length > 0 && (
              <BreadcrumbItem
                folder={folderPath[folderPath.length - 1]}
                isLast={true}
                ref={lastBreadcrumbRef}
                handleClick={() => handleBreadcrumbClick(folderPath.length - 1)}
              />
            )}
          </HStack>
        </>
      );
    }

    return (
      <>
        {filesBreadcrumb}
        {rootBreadcrumb}
        {folderPath.map((folder, index) => (
          <HStack key={folder._id} spacing={2} align="center">
            <PiCaretRightBold />
            <BreadcrumbItem
              folder={folder}
              isLast={index === folderPath.length - 1}
              ref={index === folderPath.length - 1 ? lastBreadcrumbRef : null}
              handleClick={() => handleBreadcrumbClick(index)}
            />
          </HStack>
        ))}
      </>
    );
  };

  const renderBreadcrumbsOrBack = () => {
    if (isMobile) {
      return !isInRootFolder ? (
        <Button leftIcon={<ArrowBackIcon />} onClick={() => handleGoBack()} size="xs">
          Back
        </Button>
      ) : (
        renderBreadcrumbs()
      );
    }
    return renderBreadcrumbs();
  };

  // This is the "action menu" for selected files
  // We'll render it differently for mobile (in a Portal at bottom) vs. desktop (inline).
  const desktopSelectedItemsMenu = (
    <Collapse in={selectedItems.size > 0} animateOpacity>
      <Box borderRadius="xl">
        <Flex justify="space-between" align="center">
          <Tag colorScheme={themeColor} mr={2} size="sm" fontWeight="medium">
            {selectedItems.size} item{selectedItems.size > 1 ? "s" : ""} selected
          </Tag>
          <Menu>
            <Tooltip
              label={
                currentFolderId === null
                  ? "You cannot move a file to a preceding folder. To move files to a folder, drag and drop the icon of the file to move into a folder"
                  : "Move selected files to a preceding folder. To move files to a folder, drag and drop the icon of the file to move into a folder"
              }
              hasArrow
            >
              <MenuButton
                isDisabled={currentFolderId === null}
                cursor={currentFolderId === null ? "not-allowed" : "pointer"}
                as={Button}
                mr={2}
                size="xs"
                colorScheme={themeColor} // Dynamic color scheme
                variant="outline"
              >
                <Flex alignItems="center">
                  <Icon as={PiFolderSimple} mr={1} fontSize="18px" color={`${themeColor}.500`} /> <Text fontSize="12px">Move Back</Text>
                </Flex>
              </MenuButton>
            </Tooltip>

            <MenuList bg={useColorModeValue("white", "gray.700")}>{renderFolderMenuItems()}</MenuList>
          </Menu>
          <Tooltip label="Delete all selected files">
            <Button colorScheme="red" variant="outline" size="xs" onClick={() => handleMassDelete()}>
              <Flex alignItems="center">
                <Icon as={PiTrash} mr={1} fontSize="18px" color="red.500" />
                <Text my="auto" fontSize="12px">
                  Delete All
                </Text>
              </Flex>
            </Button>
          </Tooltip>
        </Flex>
      </Box>
    </Collapse>
  );

  const mobileSelectedItemsMenu = (
    <Portal>
      <Box position="fixed" bottom="10px" left="10px" right="10px" borderRadius="full" border='1px solid' borderColor={mobileSelectedMenuBorderColor} zIndex="900" p={4} bg={bgColor} boxShadow="md">
        <Collapse in={selectedItems.size > 0} animateOpacity>
          <Box borderRadius="xl">
            <Flex justify="space-between" align="center">
              <Tag colorScheme={themeColor} mr={2} size="sm" fontWeight="medium">
                {selectedItems.size} file{selectedItems.size > 1 ? "s" : ""} selected
              </Tag>
              <HStack spacing={1}>
                <Menu>
                  <MenuButton
                    isDisabled={currentFolderId === null}
                    cursor={currentFolderId === null ? "not-allowed" : "pointer"}
                    as={Button}
                    mr={2}
                    size="xs"
                    colorScheme={themeColor} // Dynamic color scheme
                    variant="outline"
                  >
                    <Flex alignItems="center">
                      <Icon as={PiFolderSimple} mr={1} fontSize="18px" color={`${themeColor}.500`} />
                      <Text fontSize="12px">Move Back</Text>
                    </Flex>
                  </MenuButton>
                  <MenuList bg={useColorModeValue("white", "gray.700")}>{renderFolderMenuItems()}</MenuList>
                </Menu>
                <Button colorScheme="red" variant="outline" size="xs" onClick={() => handleMassDelete()}>
                  <Flex alignItems="center">
                    <Icon as={PiTrash} mr={1} fontSize="18px" color="red.500" />
                    <Text my="auto" fontSize="12px">
                      Delete All
                    </Text>
                  </Flex>
                </Button>
              </HStack>
            </Flex>
          </Box>
        </Collapse>
      </Box>
    </Portal>
  );

  return (
    <>
      {/* Hidden Folder Input for Importing Folders */}
      <input
        type="file"
        webkitdirectory="true"
        directory="true"
        multiple
        ref={folderInputRef}
        style={{ display: "none" }}
        onChange={onFolderSelected}
      />

      <Flex
        px={8}
        py={4}
        h="80px"
        bg={bgColor}
        borderBottom="1px solid"
        borderColor={borderColor}
        align="center"
        justify="space-between"
        boxSizing="border-box"
        ref={containerRef}
      >
        {/* Breadcrumbs */}
        <Flex align="center" flex="1" overflow="hidden" minWidth="0" mr={1} ml={isMobile ? 4 : 0}>
          {renderBreadcrumbsOrBack()}
        </Flex>

        {/* Action Buttons (desktop collapse OR inline) */}
        <HStack spacing={2} flexShrink="0">
          {/* Render the selected-items menu inline for desktop, or hide for mobile */}
          {!isMobile && desktopSelectedItemsMenu}

          {currentWorkspace && (
            <Menu>
              <MenuButton as={Button} size="xs" colorScheme={themeColor}>
                <Flex alignItems="center">
                  <Icon as={TbPlus} color="white" mr={1} fontSize="18px" />
                  <Text fontSize="12px">Add New</Text>
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleAddNew("importFile")} icon={<BsFileEarmarkPlus fontSize="18px" />} fontSize="sm">
                  Import a File
                </MenuItem>
                <MenuItem onClick={triggerFolderInput} icon={<BsFolderPlus fontSize="18px" />} fontSize="sm">
                  Import a Folder
                </MenuItem>
                <MenuItem onClick={() => handleAddNew("createLink")} icon={<PiLink fontSize="18px" />} fontSize="sm">
                  Add a Link
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => handleAddNew("createFolder")} icon={<PiFolderSimple fontSize="18px" />} fontSize="sm">
                  Create a Folder
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </HStack>
      </Flex>

      {/* Render the selected-items menu in a Portal at bottom if mobile */}
      {isMobile && mobileSelectedItemsMenu}
    </>
  );
};

export default FilesTopBar;
