import React, { useState, useContext, useEffect, useRef } from "react";
import { Editor, EditorState, ContentState, convertFromHTML, RichUtils, Modifier } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Flex, Text, Button, Box, HStack, IconButton, useToast, Tooltip } from "@chakra-ui/react";
import {
  PiFloppyDiskBackFill,
  PiPencilSimpleLine,
  PiXBold,
  PiTextBBold,
  PiTextItalicBold,
  PiTextUnderlineBold,
  PiArrowUUpLeftBold,
  PiArrowUUpRightBold,
} from "react-icons/pi";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";

const styleMap = {
  RED: { color: "red" },
  BLUE: { color: "blue" },
  GREEN: { color: "green" },
};

const DescriptionSection = () => {
  const { currentTaskId, tasks, editTask } = useContext(ProjectContext);
  const currentTask = tasks.find((task) => task._id === currentTaskId);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [initialEditorState, setInitialEditorState] = useState(() => EditorState.createEmpty());
  const [isEditing, setIsEditing] = useState(false);
  const toast = useToast();
  const editorRef = useRef(null);

  useEffect(() => {
    if (currentTask && currentTask.description) {
      const blocksFromHTML = convertFromHTML(currentTask.description);
      const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
      setInitialEditorState(newEditorState);
    } else {
      setEditorState(EditorState.createEmpty());
      setInitialEditorState(EditorState.createEmpty());
    }
  }, [currentTask]);

  const handleSaveDescription = async () => {
    try {
      const htmlDescription = stateToHTML(editorState.getCurrentContent());
      await editTask(currentTask._id, { description: htmlDescription });
      setIsEditing(false);
      toast({
        title: "Description saved.",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      console.error("Error saving description:", error);
    }
  };

  const handleCancelEdit = () => {
    setEditorState(initialEditorState);
    setIsEditing(false);
  };

  const handleKeyCommand = (command, editorStateParam) => {
    const newState = RichUtils.handleKeyCommand(editorStateParam, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const toggleInlineStyle = (style) => {
    const newState = RichUtils.toggleInlineStyle(editorState, style);
    setEditorState(newState);
  };

  const handleUndo = () => {
    const newEditorState = EditorState.undo(editorState);
    setEditorState(newEditorState);
  };

  const handleRedo = () => {
    const newEditorState = EditorState.redo(editorState);
    setEditorState(newEditorState);
  };

  const removeFormatting = () => {
    let newState = editorState;
    const selection = newState.getSelection();
    let contentState = newState.getCurrentContent();

    ["BOLD", "ITALIC", "UNDERLINE", "RED", "BLUE", "GREEN"].forEach((style) => {
      contentState = Modifier.removeInlineStyle(contentState, selection, style);
    });
    newState = EditorState.push(newState, contentState, "change-inline-style");

    setEditorState(newState);
  };

  return (
    <Flex mx={4} my={8} p={4} borderWidth="1px" borderColor="gray.200" borderRadius="xl" bg="gray.50" flexDir="column">
      <Flex justifyContent="space-between" align="center" mb={2}>
        <Text h="32px" fontWeight="400">
          Task Description
        </Text>
        {isEditing ? (
          <HStack>
            <Button variant="outline" aria-label="Cancel edit" colorScheme="gray" size="xs" onClick={handleCancelEdit}>
              Cancel
            </Button>
            <Button
              w="fit-content"
              leftIcon={<PiFloppyDiskBackFill fontSize="18px" />}
              colorScheme="primary"
              onClick={handleSaveDescription}
              size="xs"
              variant="outline"
            >
              Save Description
            </Button>
          </HStack>
        ) : (
          <Button
            w="fit-content"
            leftIcon={<PiPencilSimpleLine fontSize="18px" />}
            aria-label="Edit description"
            colorScheme="gray"
            size="xs"
            variant="outline"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        )}
      </Flex>

      {isEditing ? (
        <Box>
          <HStack spacing={2} mb={2} wrap="wrap">
            <HStack spacing={0}>
              <Tooltip label="Undo">
                <IconButton icon={<PiArrowUUpLeftBold />} aria-label="Undo" onClick={handleUndo} variant="outline" size="sm" />
              </Tooltip>
              <Tooltip label="Redo">
                <IconButton icon={<PiArrowUUpRightBold />} aria-label="Redo" onClick={handleRedo} variant="outline" size="sm" />
              </Tooltip>
            </HStack>

            <HStack spacing={0}>
              {["BOLD", "ITALIC", "UNDERLINE"].map((style) => (
                <Tooltip key={style} label={style}>
                  <IconButton
                    icon={style === "BOLD" ? <PiTextBBold /> : style === "ITALIC" ? <PiTextItalicBold /> : <PiTextUnderlineBold />}
                    aria-label={style}
                    onClick={() => toggleInlineStyle(style)}
                    variant={editorState.getCurrentInlineStyle().has(style) ? "solid" : "outline"}
                    colorScheme={editorState.getCurrentInlineStyle().has(style) ? "blue" : "gray"}
                    size="sm"
                  />
                </Tooltip>
              ))}
            </HStack>

            <Tooltip label="Remove formatting">
              <IconButton icon={<PiXBold />} aria-label="Remove formatting" onClick={removeFormatting} variant="outline" size="sm" />
            </Tooltip>
          </HStack>

          <Box mt={4} bg="white" border="1px solid" borderColor="gray.200" borderRadius="lg" p={2} minH="150px">
            <Editor
              ref={(element) => (editorRef.current = element)}
              editorState={editorState}
              onChange={setEditorState}
              handleKeyCommand={handleKeyCommand}
              customStyleMap={styleMap}
            />
          </Box>
        </Box>
      ) : (
        <Box bg="white" p={4} borderRadius="xl" border="1px solid" borderColor="gray.200" boxShadow="base">
          {currentTask?.description ? (
            <div dangerouslySetInnerHTML={{ __html: currentTask.description }} />
          ) : (
            <Text color="gray.500" fontSize='md'>No description provided.</Text>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default DescriptionSection;
