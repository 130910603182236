// src/components/FilesContent.jsx

import React, { useContext } from "react";
import { Flex, Box, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
import { FilesContext } from "../5 - General/Context/FilesContext";
import FilesTopBar from "./FilesTopBar";
import FilesList from "./FilesList";
import ImportFileModal from "../3 - Modal/4 - Files Modals/FileUploadModal";
import CreateFolderModal from "../3 - Modal/4 - Files Modals/FileCreateFolderModal";
import RenameModal from "../3 - Modal/4 - Files Modals/FileRenameModal";
import DeleteConfirmationModal from "../3 - Modal/4 - Files Modals/FileDeleteConfirmationModal";
import FileImagePreviewModal from "../3 - Modal/4 - Files Modals/FileImagePreviewModal";
import FileCreateLinkModal from "../3 - Modal/4 - Files Modals/FileCreateLinkModal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const FilesContent = () => {
  const bgColor = useColorModeValue("white", "gray.800");
  const { isImagePreviewModalOpen, onCloseImagePreviewModal, selectedImage } = useContext(FilesContext);

  // Determine if we're on a mobile layout
  const isMobile = useBreakpointValue({ base: true, md: false });

  // A simpler "flex column" approach for mobile. For desktop, same as before.
  return (
    <Flex direction={isMobile ? "column" : "row"} height="100vh" overflow="hidden" w="100%">
      {/* Main Content */}
      <Box flex="1" display="flex" flexDirection="column" overflow="hidden" bg={bgColor}>
        <DndProvider backend={HTML5Backend}>
          {/* TopBar and List remain the same logic inside, but themselves can adapt to isMobile */}
          <FilesTopBar />
          <FilesList />
        </DndProvider>
      </Box>

      {/* Modals remain unchanged; they appear on top of everything */}
      <ImportFileModal />
      <CreateFolderModal />
      <RenameModal />
      <DeleteConfirmationModal />
      <FileImagePreviewModal isOpen={isImagePreviewModalOpen} onClose={onCloseImagePreviewModal} selectedImage={selectedImage} />
      <FileCreateLinkModal />
    </Flex>
  );
};

export default FilesContent;
