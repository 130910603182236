// client/src/queryKeys.js

export const queryKeys = {
  // Main queries
  workspaces: ["workspaces"],
  user: (userId) => ["user", userId],
  company: (companyId) => ["company", companyId],
  availableUsers: (userId) => ["availableUsers", userId],

  // Avatar queries
  userAvatar: (userId) => ["userAvatar", [userId]],
  companyAvatar: (companyId) => ["companyAvatar", [companyId]],

  // Settings queries
  companyUsers: (companyId) => ["companyUsers", companyId],

  // CRM queries
  users: (companyId, search, searchColumns, page, limit) => ["usersCRM", companyId, search, searchColumns, page, limit],
  companies: (companyId, search, searchColumns, page, limit) => ["companiesCRM", companyId, search, searchColumns, page, limit],

  // Files queries
  files: (companyId, parentFolderId) => ["files", companyId, parentFolderId],

  // Messages queries
  channels: () => ["channels"],
  channelIds: () => ["channelIds"],
  totalUnreadCount: () => ["totalUnreadCount"],
  channel: (channelId) => ["channel", channelId],
  messages: (channelId) => ["messages", channelId],
  unreadCount: (channelId) => ["unreadCount", channelId],
  channelUsers: (channelId) => ["channelUsers", channelId],

  // Project queries
  projectIds: (companyId) => ["projects", companyId],
  archivedProjectIds: (companyId) => ["archivedProjects", companyId],
  project: (projectId) => ["project", projectId],
  archivedProject: (projectId) => ["archivedProject", projectId],
  taskIds: (projectId) => ["tasks", projectId],
  task: (taskId) => ["task", taskId],
  subtask: (subtaskId) => ["subtask", subtaskId],
};
