// client/src/components/ProjectsTasksBoard.jsx

import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { Box, Flex, Heading, VStack, Tag, HStack, Text, IconButton, useColorModeValue } from "@chakra-ui/react";
import { PiPlus } from "react-icons/pi";
import { StrictModeDroppable } from "../5 - General/Utils/UtilsStrictModeDroppable";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import { formatReadableDate } from "../5 - General/Utils/UtilsFormatData";
import CreateTaskModal from "../3 - Modal/3 - Projects Modals/ProjectsCreateTaskModal";
import UserAvatar from "../2 - Components/Reusable/UserAvatar";

// Memoized TaskCard component
const TaskCard = React.memo(({ task, onTaskClick, snapshot }) => {
  const cardBgColor = useColorModeValue("gray.50", "gray.700");
  const taskBorderColor = task.color ? task.color : cardBgColor;
  const textColor = useColorModeValue("gray.800", "white");

  // Get color scheme based on task priority
  const getColorSchemeForPriority = useMemo(() => {
    switch (task.priority) {
      case "High":
        return "red";
      case "Medium":
        return "yellow";
      case "Low":
        return "green";
      default:
        return "gray";
    }
  }, [task.priority]);

  const assignedTo = task.assignedTo.map((user) => user._id);

  return (
    <Box
      bg={cardBgColor}
      onClick={() => onTaskClick(task)}
      color={textColor}
      border="2px solid"
      borderColor={taskBorderColor}
      transform="scale(0.99)"
      borderRadius="lg" // Slightly more rounded corners for a modern look
      boxShadow={snapshot.isDragging ? "xl" : "base"} // Subtle shadow change when dragging
      p={5} // Increased padding for a more spacious feel
      transition="all 0.2s ease-in-out" // Smooth transition for hover effects
      _hover={{
        transform: "scale(1)", // Small scale effect on hover
        boxShadow: "lg", // Increase shadow on hover
      }}
    >
      {/* Task Name */}
      <Text fontWeight="bold" fontSize="lg" mb={2}>
        {task.name}
      </Text>

      {/* Due Date */}
      <HStack spacing={1} alignItems="center">
        <Text fontSize="md">Due by {formatReadableDate(task.dueDate)}</Text>
      </HStack>

      {/* Priority */}
      <HStack my={3} spacing={4} alignItems="center">
        {/* Priority Tag */}
        <Tag borderRadius="full" colorScheme={getColorSchemeForPriority} px={4} py={1} fontSize="sm" fontWeight="500">
          {task.priority}
        </Tag>
      </HStack>

      {/* Assignees */}
      <UserAvatar
        userIds={assignedTo}
        size="sm"
        useGroup={true}
        maxAvatars={3}
        spacing={-2}
        border="2px solid white"
        boxShadow="md"
      />
    </Box>
  );
});

// Memoized Column component
const Column = React.memo(({ column, tasks, onTaskClick, handleOpenCreateTaskModal }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.750");

  return (
    <StrictModeDroppable droppableId={column._id}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          bg={bgColor}
          p={4}
          flex="1"
          borderRadius="md"
          boxShadow="md"
          minWidth="300px"
          display="flex"
          flexDirection="column"
          border="1px solid"
          borderColor={borderColor}
        >
          {/* Column Header */}
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Heading size="md" display="flex" alignItems="center">
              {column._id}
            </Heading>
            <HStack spacing={2}>
              <Tag ml={2} borderRadius="md" colorScheme="gray">
                {tasks.length}
              </Tag>
              <IconButton
                aria-label={`Add task to ${column._id}`}
                icon={<PiPlus fontSize="18px" />}
                size="sm"
                onClick={() => handleOpenCreateTaskModal(column._id)}
              />
            </HStack>
          </Flex>

          {/* Task List */}
          <VStack spacing={3} align="stretch" flexGrow={1} overflowY="auto" paddingBottom={2}>
            {tasks.map((task, index) => (
              <Draggable key={task._id} draggableId={task._id} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <TaskCard task={task} onTaskClick={onTaskClick} snapshot={snapshot} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </VStack>
        </Box>
      )}
    </StrictModeDroppable>
  );
});

const ProjectsTasksBoard = ({ onTaskClick }) => {
  const { tasks, currentProjectId, updateTaskOrder } = useContext(ProjectContext);
  // Initialize columns based on task statuses
  const initialColumns = useMemo(
    () => ({
      "Not Started": {
        _id: "Not Started",
        list: tasks.filter((task) => task.status === "Not Started").sort((a, b) => a.order - b.order),
      },
      "In Progress": {
        _id: "In Progress",
        list: tasks.filter((task) => task.status === "In Progress").sort((a, b) => a.order - b.order),
      },
      Completed: {
        _id: "Completed",
        list: tasks.filter((task) => task.status === "Completed").sort((a, b) => a.order - b.order),
      },
      "On Hold": {
        _id: "On Hold",
        list: tasks.filter((task) => task.status === "On Hold").sort((a, b) => a.order - b.order),
      },
    }),
    [tasks]
  );

  const [columns, setColumns] = useState(initialColumns);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Update columns when tasks change
  useEffect(() => {
    setColumns(initialColumns);
  }, [initialColumns]);

  const onDragEnd = useCallback(
    async ({ source, destination }) => {
      if (!destination) return;

      if (source.droppableId === destination.droppableId && source.index === destination.index) {
        return;
      }

      const sourceColumn = columns[source.droppableId];
      const destinationColumn = columns[destination.droppableId];

      let newColumns = { ...columns };

      // Moving within the same column
      if (sourceColumn === destinationColumn) {
        const updatedList = Array.from(sourceColumn.list);
        const [movedTask] = updatedList.splice(source.index, 1);
        updatedList.splice(destination.index, 0, movedTask);

        // Update the order of tasks in the column
        updatedList.forEach((task, index) => {
          task.order = index;
        });

        newColumns = {
          ...columns,
          [sourceColumn._id]: {
            ...sourceColumn,
            list: updatedList,
          },
        };
      } else {
        // Moving to a different column
        const sourceList = Array.from(sourceColumn.list);
        const [movedTask] = sourceList.splice(source.index, 1);
        const destinationList = Array.from(destinationColumn.list);

        // Update the task's status
        movedTask.status = destinationColumn._id;

        destinationList.splice(destination.index, 0, movedTask);

        // Update the order of tasks in both columns
        sourceList.forEach((task, index) => {
          task.order = index;
        });
        destinationList.forEach((task, index) => {
          task.order = index;
        });

        newColumns = {
          ...columns,
          [sourceColumn._id]: {
            ...sourceColumn,
            list: sourceList,
          },
          [destinationColumn._id]: {
            ...destinationColumn,
            list: destinationList,
          },
        };
      }

      setColumns(newColumns);

      // Prepare tasks to update
      const tasksToUpdate = [];
      Object.values(newColumns).forEach((column) => {
        column.list.forEach((task) => {
          tasksToUpdate.push({
            _id: task._id,
            status: task.status,
            order: task.order,
          });
        });
      });

      try {
        await updateTaskOrder(tasksToUpdate);
      } catch (error) {
        console.error("Error updating task orders:", error);
        // Optionally revert state
        setColumns(columns);
        // Show error toast (already handled in the hook)
      }
    },
    [columns, updateTaskOrder]
  );

  const handleOpenCreateTaskModal = useCallback((status) => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}  overflow="auto">
      <Flex justifyContent="space-between" p={4} gap={4} overflow="auto">
        {useMemo(
          () =>
            Object.values(columns).map((column) => (
              <Column
                key={column._id}
                column={column}
                tasks={column.list}
                onTaskClick={onTaskClick}
                handleOpenCreateTaskModal={handleOpenCreateTaskModal}
              />
            )),
          [columns, onTaskClick, handleOpenCreateTaskModal]
        )}
      </Flex>

      {/* Create Task Modal */}
      <CreateTaskModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        projectId={currentProjectId}
        parentTask={null} // Assuming no parent task when creating from board
      />
    </DragDropContext>
  );
};

export default React.memo(ProjectsTasksBoard);
