// client/src/components/2 - Components/Reusable/TableConfig.js

import React from "react";
import { Box, Button, HStack, Menu, MenuButton, MenuItem, MenuList, Tag, Text } from "@chakra-ui/react";
import UserAvatar from "./UserAvatar";
import CompanyAvatar from "./CompanyAvatar";
import { formatReadableDate, formatTimestamp } from "../../5 - General/Utils/UtilsFormatData";
import { PiCaretDownBold, PiTrash } from "react-icons/pi";
import { Select } from "chakra-react-select";

// Helper functions for rendering data
const renderDateTag = (value) => (
  <Tag size="sm" colorScheme="gray">
    {formatReadableDate(value)}
  </Tag>
);

const renderStatusTag = (status) => {
  const colorScheme = status === "Active" ? "green" : "red";
  return (
    <Tag size="sm" colorScheme={colorScheme}>
      {status}
    </Tag>
  );
};

// Customers table configuration
const customerColumns = () => [
  {
    accessor: "userDetails",
    label: "User Details",
    render: (value, row) => (
      <HStack spacing={4}>
        <UserAvatar userIds={[row._id]} size="sm" showInfo={false} />
        <Box>
          <Text fontWeight="600">{`${row.firstName} ${row.lastName}`}</Text>
          <Text fontSize="sm" color="gray.500">
            {row.email}
          </Text>
        </Box>
      </HStack>
    ),
    defaultVisible: true,
  },
  {
    accessor: "phoneNumber",
    label: "Phone",
    render: (value) => (
      <Text fontSize="sm">
        {value || "N/A"}
      </Text>
    ),
    defaultVisible: true,
  },
  {
    accessor: "role",
    label: "Role",
    render: (value) => (
      <Tag size="sm" colorScheme="primary">
        {value}
      </Tag>
    ),
    defaultVisible: true,
  },
  {
    accessor: "status",
    label: "Status",
    render: renderStatusTag,
    defaultVisible: true,
  },
  {
    accessor: "companyId",
    label: "Company",
    render: (value) => (
      <Text fontWeight="500">
        {value?.companyName || "N/A"}
      </Text>
    ),
    defaultVisible: true,
  },
  {
    accessor: "lastLoggedIn",
    label: "Last Login",
    render: (value) => (
      <Tag fontSize="sm" colorScheme="gray">
        {value ? formatTimestamp(value) : "Never"}
      </Tag>
    ),
    defaultVisible: true,
  },
  {
    accessor: "createdAt",
    label: "Date Joined",
    render: renderDateTag,
    defaultVisible: true,
  },
];

// Companies table configuration
const companyColumns = (themeColor) => [
  {
    accessor: "companyName",
    label: "Company Name",
    render: (value, row) => (
      <HStack spacing={4}>
        <CompanyAvatar companyIds={[row._id]} size="sm" showInfo={true} />
      </HStack>
    ),
    defaultVisible: true,
  },
  {
    accessor: "companyRegistrationNumber",
    label: "Registration Number",
    render: (value) => (
      <Text fontSize="sm">
        {value || "N/A"}
      </Text>
    ),
    defaultVisible: true,
  },
  {
    accessor: "companyRole",
    label: "Role",
    render: (value) => (
      <Tag size="sm" colorScheme="blue">
        {value}
      </Tag>
    ),
    defaultVisible: true,
  },
  {
    accessor: "createdBy",
    label: "Created By",
    render: (value) => (
      <Text fontSize="sm" color="gray.500">
        {value?.firstName && value?.lastName ? `${value.firstName} ${value.lastName}` : "N/A"}
      </Text>
    ),
    defaultVisible: true,
  },
  {
    accessor: "createdAt",
    label: "Creation Date",
    render: renderDateTag,
    defaultVisible: true,
  },
];

// Team Settings table configuration
const teamSettingsColumns = () => [
  {
    accessor: "userDetails",
    label: "User Details",
    render: (value, user) => (
      <HStack spacing={4}>
        <UserAvatar userIds={[user.id || user._id]} size="md" showInfo={false} />
        <Box>
          <Text fontWeight="600" color="gray.700">
            {user.firstName} {user.lastName}
          </Text>
          <Text fontSize="xs" color="gray.500">
            {user.email}
          </Text>
        </Box>
      </HStack>
    ),
    defaultVisible: true,
  },
  {
    accessor: "createdAt",
    label: "Date Joined",
    render: (value) => (
      <Button size="sm" variant="ghost" _hover={{ bg: null }}>
        {formatReadableDate(value)}
      </Button>
    ),
    defaultVisible: true,
  },
  {
    accessor: "role",
    label: "User Role",
    render: (value, user) => {
      if (user.currentUserRole === "Customer") {
        return (
          <Button size="xs" variant="outline" _hover={{ bg: null }}>
            {user.role}
          </Button>
        );
      }
      const roleOptions = [
        { value: "Admin", label: "Admin" },
        { value: "Editor", label: "Editor" },
        { value: "Customer", label: "Customer" },
      ];
      const selectedRole = roleOptions.find((option) => option.value === value);
      const isDisabled = !user.canModifyUser(user.currentUserRole, user.role) && user.role !== "Admin";

      return (
        <Select
          size="sm"
          value={selectedRole}
          onChange={(option) => user.handleChangeRole(user._id, option.value)}
          options={roleOptions}
          placeholder="Select role"
          isClearable={false}
          isSearchable={false}
          isDisabled={isDisabled}
          menuPortalTarget={document.body}
        />
      );
    },
    defaultVisible: true,
  },
  {
    accessor: "moreInfoMenu",
    label: "More Info",
    render: (value, user) => (
      <Menu>
        <MenuButton as={Button} variant="outline" aria-label="User Info" rightIcon={<PiCaretDownBold />} size="xs">
          See more
        </MenuButton>
        <MenuList>
          <MenuItem _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
            <Box>
              <Text fontSize="md">Last Login:</Text>
              <Tag size="xs" colorScheme={user.lastLoggedIn ? "gray" : "red"}>
                {user.lastLoggedIn ? formatTimestamp(user.lastLoggedIn) : "Never"}
              </Tag>
            </Box>
          </MenuItem>
          <MenuItem _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
            <Box>
              <Text fontSize="md">Status:</Text>
              <Tag size="xs" colorScheme={user.status === "Deactivated" ? "red" : "green"}>
                {user.status === "Deactivated" ? "Deactivated" : "Active"}
              </Tag>
            </Box>
          </MenuItem>
          <MenuItem _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
            <Box>
              <Text fontSize="md">Email:</Text>
              <Tag size="xs" colorScheme={user.isVerified ? "green" : "red"}>
                {user.isVerified ? "Verified" : "Not Verified"}
              </Tag>
            </Box>
          </MenuItem>
          <MenuItem _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
            <Box>
              <Text fontSize="md">Profile:</Text>
              <Tag size="xs" colorScheme={user.profileCompleted ? "green" : "red"}>
                {user.profileCompleted ? "Complete" : "Incomplete Profile"}
              </Tag>
            </Box>
          </MenuItem>
        </MenuList>
      </Menu>
    ),
    defaultVisible: true,
  },
];

// Action Column for Team Settings
const actionColumn = ({
  handleModifyRights,
  handleDeactivateUser,
  handleResendInvitation,
  handleSendPasswordReset,
  handleActivateUser,
  handleDeleteUser,
  currentUserRole,
  canModifyUser,
  isMobile,
  onOpenModal,
  themeColor,
}) => {
  if (isMobile) {
    return {
      label: "Actions",
      render: (user) => (
        <HStack w="100%" justify="end" spacing={2}>
          <Button size="xs" variant="outline" onClick={() => onOpenModal(user)} colorScheme={themeColor}>
            Actions
          </Button>
        </HStack>
      ),
    };
  } else {
    return {
      label: "Actions",
      render: (user) => {
        const isDisabled = !canModifyUser(currentUserRole, user.role);
        const isDisabledForAdmins = user.role === "Admin";

        return (
          <HStack w="100%" justify="end" spacing={2}>
            {!user.isVerified && (
              <Button
                size="xs"
                variant="outline"
                colorScheme={themeColor}
                onClick={() => handleResendInvitation(user)}
                aria-label={`Resend invitation to ${user.firstName}`}
                isDisabled={isDisabled}
              >
                Resend Invitation
              </Button>
            )}
            {user.isVerified && (
              <Button
                size="xs"
                variant="outline"
                colorScheme={themeColor}
                onClick={() => handleSendPasswordReset(user)}
                aria-label={`Send password reset to ${user.firstName}`}
                isDisabled={isDisabled}
              >
                Send Password Reset
              </Button>
            )}
            <Button
              size="xs"
              variant="outline"
              colorScheme={themeColor}
              onClick={() => handleModifyRights(user)}
              aria-label={`Modify rights for ${user.firstName}`}
              isDisabled={isDisabled}
            >
              Rights
            </Button>
            {user.status !== "Deactivated" ? (
              <Button
                size="xs"
                colorScheme="gray"
                variant="outline"
                onClick={() => handleDeactivateUser(user)}
                aria-label={`Deactivate ${user.firstName}`}
                isDisabled={isDisabled || isDisabledForAdmins}
              >
                Suspend
              </Button>
            ) : (
              <Button
                size="xs"
                variant="outline"
                colorScheme="green"
                onClick={() => handleActivateUser(user)}
                aria-label={`Reactivate ${user.firstName}`}
                isDisabled={isDisabled}
              >
                Reactivate
              </Button>
            )}
            <Button
              size="xs"
              variant="outline"
              colorScheme="red"
              onClick={() => handleDeleteUser(user)}
              aria-label={`Delete ${user.firstName}`}
              isDisabled={isDisabled || user.status !== "Deactivated"}
            >
              <PiTrash fontSize="14px" />
            </Button>
          </HStack>
        );
      },
    };
  }
};

// Main Table Configurations
export const tableConfigs = {
  CustomersTable: ({
    onEdit,
    onDelete,
    currentPage,
    totalPages,
    onPageChange,
    onSearchChange,
    searchValue,
    maxPerPage,
    totalItems,
    searchColumns,
    onSearchColumnsChange,
    isLoading,
    isError,
    error,
    themeColor, // Added themeColor
  }) => ({
    columns: customerColumns(themeColor),
    actionColumn: {
      label: "Actions",
      render: (row) => (
        <HStack spacing={2} justify="flex-end">
          <Button size="sm" variant="outline" colorScheme={themeColor} onClick={() => onEdit(row)}>
            Edit
          </Button>
          <Button size="sm" variant="outline" colorScheme="red" onClick={() => onDelete(row._id)}>
            Delete
          </Button>
        </HStack>
      ),
    },
    headerStyles: {
      h: "50px",
    },
    headerCellStyles: {
      fontWeight: "bold",
      fontSize: "14px",
      textTransform: "capitalize",
      borderBottom: "1px solid",
      transition: "background-color 0.2s",
    },
    rowStyles: {
      transition: "background-color 0.2s",
    },
    cellStyles: {
      fontSize: "14px",
    },
    // Pass pagination and search props
    currentPage,
    totalPages,
    onPageChange,
    onSearchChange,
    searchValue,
    maxHeight: "calc(100vh - 300px)",
    searchColumns,
    onSearchColumnsChange,
    maxPerPage,
    totalItems,
    isLoading,
    isError,
    error,
  }),

  CustomersCompanyTable: ({
    onEdit,
    onDelete,
    currentPage,
    totalPages,
    onPageChange,
    onSearchChange,
    searchValue,
    maxPerPage,
    totalItems,
    searchColumns,
    onSearchColumnsChange,
    isLoading,
    isError,
    error,
    themeColor, // Added themeColor
  }) => ({
    columns: companyColumns(themeColor),
    actionColumn: {
      label: "Actions",
      render: (row) => (
        <HStack spacing={2} justify="flex-end">
          <Button size="sm" variant="outline" colorScheme={themeColor} onClick={() => onEdit(row)}>
            Edit
          </Button>
          <Button size="sm" variant="outline" colorScheme="red" onClick={() => onDelete(row._id)}>
            Delete
          </Button>
        </HStack>
      ),
    },
    headerStyles: {
      h: "50px",
    },
    headerCellStyles: {
      fontWeight: "bold",
      fontSize: "14px",
      textTransform: "capitalize",
      borderBottom: "1px solid",
      transition: "background-color 0.2s",
    },
    rowStyles: {
      transition: "background-color 0.2s",
    },
    cellStyles: {
      fontSize: "14px",
    },
    // Pass pagination and search props
    currentPage,
    totalPages,
    onPageChange,
    onSearchChange,
    searchValue,
    maxHeight: "calc(100vh - 300px)",
    searchColumns,
    onSearchColumnsChange,
    maxPerPage,
    totalItems,
    isLoading,
    isError,
    error,
  }),

  teamSettings: ({
    handleModifyRights,
    handleChangeRole,
    handleDeactivateUser,
    handleResendInvitation,
    handleSendPasswordReset,
    handleActivateUser,
    handleDeleteUser,
    currentUserRole,
    canModifyUser,
    isMobile,
    handleOpenModal,
  }) => {
    const { desktopColumns, mobileColumns } = teamSettingsColumns();

    return {
      desktopColumns,
      mobileColumns,
      actionColumn: actionColumn({
        handleModifyRights,
        handleChangeRole,
        handleDeactivateUser,
        handleResendInvitation,
        handleSendPasswordReset,
        handleActivateUser,
        handleDeleteUser,
        currentUserRole,
        canModifyUser,
        isMobile,
        onOpenModal: handleOpenModal,
      }),
      headerStyles: {
        h: "50px",
      },
      headerCellStyles: {
        fontWeight: "bold",
        fontSize: "14px",
        textTransform: "capitalize",
        borderBottom: "1px solid",
        transition: "background-color 0.2s",
      },
      rowStyles: {
        transition: "background-color 0.2s",
      },
      cellStyles: {
        fontSize: "14px",
      },
    };
  },
};

export default tableConfigs;
