// client/src/components/Files/Modals/FileUploadModal.jsx

import React, { useContext, useState, useEffect } from "react";
import { Box, VStack, Text, IconButton, Flex, Image, Button, useColorModeValue, Input, Progress, Spinner } from "@chakra-ui/react";
import { PiXBold } from "react-icons/pi";
import { FilesContext } from "../../5 - General/Context/FilesContext";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { renderFileIcon } from "../../5 - General/Utils/UtilsFileIcon";
import { formatFileSize } from "../../5 - General/Utils/UtilsFormatData";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext"; // Imported WorkspaceContext

const FileUploadModal = () => {
  const { onCloseImportModal, isImportModalOpen, handleFileUpload } = useContext(FilesContext);
  const { themeColor } = useContext(WorkspaceContext); // Accessed themeColor
  const [files, setFiles] = useState([]); // Array of File objects
  const [filePreviews, setFilePreviews] = useState([]); // Array of preview URLs
  const [isDragOver, setIsDragOver] = useState(false); // State for drag over
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgressText, setUploadProgressText] = useState("");

  const borderColor = useColorModeValue("gray.400", "gray.600");
  const filePreviewTextColor = useColorModeValue("gray.800", "gray.200");
  const dragOverBgColor = useColorModeValue("gray.100", "gray.700");

  const customToast = useCustomToast();

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const totalFiles = files.length + selectedFiles.length;

    if (totalFiles > 5) {
      // Assuming MAX_FILES = 5
      customToast({
        title: "File Limit Exceeded",
        description: `You can only upload up to 5 files.`,
        status: "warning",
      });
      return;
    }

    const validFiles = selectedFiles.slice(0, 5 - files.length);
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);

    const newPreviews = validFiles.map((file) => (file.type.startsWith("image/") ? URL.createObjectURL(file) : null));
    setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

    // Reset the file input
    event.target.value = null;
  };

  const revokeFilePreviews = () => {
    filePreviews.forEach((preview) => {
      if (preview) URL.revokeObjectURL(preview);
    });
  };

  useEffect(() => {
    revokeFilePreviews();
    setFiles([]);
    setFilePreviews([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setFilePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      customToast({
        title: "No Files Selected",
        description: "Please select at least one file to upload.",
        status: "warning",
      });
      return;
    }

    setIsUploading(true);
    setUploadProgressText(`Uploading: 0/${files.length}`);

    for (let i = 0; i < files.length; i++) {
      try {
        await handleFileUpload(files[i]); // Ensure this waits for the actual upload to finish
        setUploadProgressText(`Uploading: ${i + 1}/${files.length}`);
      } catch (error) {
        console.error(`Error uploading file ${files[i].name}:`, error);
        customToast({
          title: "Upload Error",
          description: `Failed to upload "${files[i].name}".`,
          status: "error",
        });
      }
    }

    // After all uploads complete, show the final state for a moment
    setTimeout(() => {
      setIsUploading(false);
      onCloseImportModal();
      setFiles([]);
      setFilePreviews([]);
    }, 1000); // A short delay to let the user see the final state
  };

  const renderFilePreview = () => {
    return (
      <Flex wrap="wrap" gap={4}>
        {files.map((file, index) => (
          <Box key={index} maxW="50%" p={2} borderRadius="md" border="1px solid" borderColor={borderColor} position="relative">
            {file.type.startsWith("image/") && filePreviews[index] ? (
              <Image src={filePreviews[index]} boxSize="100px" objectFit="cover" borderRadius="md" alt="preview" mb={2} />
            ) : (
              <Box mb={2} textAlign="center">
                {renderFileIcon(file.name)}
              </Box>
            )}
            <VStack align="start" spacing={0} flex="1" color={filePreviewTextColor}>
              <Text fontWeight="bold" fontSize="sm" isTruncated>
                {file.name.length > 20 ? `${file.name.substring(0, 17)}...` : file.name}
              </Text>
              <Text fontSize="xs" color="gray.500">
                {formatFileSize(file.size)}
              </Text>
            </VStack>
            <IconButton
              icon={<PiXBold />}
              aria-label="Remove file"
              size="sm"
              variant="ghost"
              position="absolute"
              top="0"
              right="0"
              onClick={() => handleRemoveFile(index)}
            />
          </Box>
        ))}
      </Flex>
    );
  };

  const footerButtons = (
    <>
      <Button
        variant="outline"
        color={useColorModeValue("gray.600", "gray.300")}
        _hover={{
          color: useColorModeValue("black", "white"),
          bg: useColorModeValue("gray.100", "gray.500"),
        }}
        onClick={() => onCloseImportModal()}
        isDisabled={isUploading}
      >
        Cancel
      </Button>
      <Button
        colorScheme={themeColor} // Replaced "primary" with themeColor
        onClick={handleUpload}
        isDisabled={files.length === 0 || isUploading}
      >
        {isUploading ? "Uploading..." : "Upload"}
      </Button>
    </>
  );

  return (
    <ReusableModal isOpen={isImportModalOpen} onClose={() => onCloseImportModal()} title="Upload Files" footerButtons={footerButtons} size="lg">
      {isUploading ? (
        <VStack spacing={4} pt={4} width="100%">
          <Text>{uploadProgressText}</Text>
          <Spinner size="lg" color={`${themeColor}.500`} />
          <Progress width="100%" value={(parseInt(uploadProgressText.split(": ")[1]?.split("/")[0]) / files.length) * 100} colorScheme={themeColor} />
        </VStack>
      ) : (
        <VStack spacing={4} pt={4}>
          <Box width="100%">
            <Box
              width="100%"
              py={6}
              px={8}
              border="2px dashed"
              borderColor={isDragOver ? `${themeColor}.500` : borderColor} // Updated with themeColor
              borderRadius="md"
              textAlign="center"
              alignContent="center"
              cursor="pointer"
              bg={isDragOver ? dragOverBgColor : "transparent"}
              onClick={() => document.getElementById("fileInput").click()}
              onDragOver={(e) => {
                e.preventDefault();
                setIsDragOver(true);
              }}
              onDragEnter={(e) => {
                e.preventDefault();
                setIsDragOver(true);
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                setIsDragOver(false);
              }}
              onDrop={(e) => {
                e.preventDefault();
                setIsDragOver(false);
                const droppedFiles = Array.from(e.dataTransfer.files).slice(0, 5 - files.length);
                if (droppedFiles.length + files.length > 5) {
                  customToast({
                    title: "File Limit Exceeded",
                    description: `You can only upload up to 5 files.`,
                    status: "warning",
                  });
                  return;
                }
                const validFiles = droppedFiles.filter((file) => {
                  if (files.find((f) => f.name === file.name && f.size === file.size)) {
                    customToast({
                      title: "Duplicate File",
                      description: `"${file.name}" has already been selected.`,
                      status: "warning",
                    });
                    return false;
                  }
                  return true;
                });
                if (validFiles.length > 0) {
                  setFiles((prevFiles) => [...prevFiles, ...validFiles]);
                  const newPreviews = validFiles.map((file) => (file.type.startsWith("image/") ? URL.createObjectURL(file) : null));
                  setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
                }
              }}
            >
              <Text mb={2}>
                Drag and drop or click to select up to {5 - files.length} more file
                {5 - files.length > 1 ? "s" : ""}
              </Text>
              <Input type="file" id="fileInput" onChange={handleFileChange} display="none" multiple accept="*/*" />
            </Box>
            {files.length > 0 && <Box mt={4}>{renderFilePreview()}</Box>}
          </Box>
        </VStack>
      )}
    </ReusableModal>
  );
};

export default FileUploadModal;
