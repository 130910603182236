// App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { SocketProvider } from "./components/5 - General/Socket/SocketContext";
import { ProjectProvider } from "./components/5 - General/Context/ProjectsContext";
import { MessagesProvider } from "./components/5 - General/Context/MessagesContext";
import { WorkspaceProvider } from "./components/5 - General/Context/WorkspaceContext";
import { ToastContainer } from "react-toastify";

// Authentication Components
import AuthPage from "./components/1 - Authentification/AuthPage";
import VerificationSuccessPage from "./components/1 - Authentification/VerificationSuccessPage";
import ForgotPasswordPage from "./components/1 - Authentification/ForgotPasswordPage";
import ResetPasswordPage from "./components/1 - Authentification/ResetPasswordPage";
import Logout from "./components/1 - Authentification/Logout";

// Layout Components
import Layout from "./components/5 - General/Layout/Layout";
import LayoutSettings from "./components/5 - General/Layout/LayoutSettings";

//  Components
import Dashboard from "./components/2 - Components/Dashboard";
import Messages from "./components/2 - Components/Messages";
import Projects from "./components/2 - Components/Projects";
import Files from "./components/2 - Components/Files";
import Contracts from "./components/2 - Components/Contracts";
import Billing from "./components/2 - Components/Billing";
import Reports from "./components/2 - Components/Reports";
import Customers from "./components/2 - Components/Customers";
import Meetings from "./components/2 - Components/Meetings";

//  Settings Components
import SettingsPageAccount from "./components/2 - Components/SettingsPageAccount";
import SettingsPageCompany from "./components/2 - Components/SettingsPageCompany";
import SettingsPageTeam from "./components/2 - Components/SettingsPageTeam";
import SettingsPageNotifications from "./components/2 - Components/SettingsPageNotifications";
import SettingsPageMessages from "./components/2 - Components/SettingsPageMessages";
import SettingsPageProjects from "./components/2 - Components/SettingsPageProjects";
import SettingsPageFiles from "./components/2 - Components/SettingsPageFiles";
import SettingsPageContracts from "./components/2 - Components/SettingsPageContracts";
import SettingsPageBilling from "./components/2 - Components/SettingsPageBilling";
import SettingsPageReports from "./components/2 - Components/SettingsPageReports";
import SettingsPageMeetings from "./components/2 - Components/SettingsPageMeetings";

// Protected Route Component
import ProtectedRoute from "./components/5 - General/Protected/ProtectedRoute";
import { FilesProvider } from "./components/5 - General/Context/FilesContext";

function App() {
  return (
    <Router>
      <SocketProvider>
        <WorkspaceProvider>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Navigate to="/auth" />} />
            <Route path="/login" element={<Navigate to="/auth" />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
            <Route path="/verify-email-success" element={<VerificationSuccessPage />} />
            <Route path="/logout" element={<Logout />} />
            {/* Protected Routes using the Layout */}
            <Route
              element={
                <ProtectedRoute>
                  <Layout />
                </ProtectedRoute>
              }
            >
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/dashboard/messages"
                element={
                  <MessagesProvider>
                    <Messages />
                  </MessagesProvider>
                }
              />
              <Route
                path="/dashboard/messages/:channelId"
                element={
                  <MessagesProvider>
                    <Messages />
                  </MessagesProvider>
                }
              />
              <Route path="/dashboard/messages/:channelId" element={<Messages />} />
              <Route
                path="/dashboard/projects"
                element={
                  <ProjectProvider>
                    <Projects />
                  </ProjectProvider>
                }
              />
              <Route
                path="/dashboard/projects/:projectId"
                element={
                  <ProjectProvider>
                    <Projects />
                  </ProjectProvider>
                }
              />
              <Route
                path="/dashboard/projects/:projectId/:taskId"
                element={
                  <ProjectProvider>
                    <Projects />
                  </ProjectProvider>
                }
              />
              <Route
                path="/dashboard/projects/:projectId/:taskId/:subtaskId"
                element={
                  <ProjectProvider>
                    <Projects />
                  </ProjectProvider>
                }
              />
              <Route
                path="/dashboard/files/:folderId?"
                element={
                  <FilesProvider>
                    <Files />
                  </FilesProvider>
                }
              />
              <Route path="/dashboard/contracts" element={<Contracts />} />
              <Route path="/dashboard/billing" element={<Billing />} />
              <Route path="/dashboard/reports" element={<Reports />} />
              <Route path="/dashboard/customers" element={<Customers />} />
              <Route path="/dashboard/meetings" element={<Meetings />} />
            </Route>

            {/* Protected Routes using LayoutSettings */}
            <Route
              element={
                <ProtectedRoute>
                  <LayoutSettings />
                </ProtectedRoute>
              }
            >
              <Route path="/dashboard/settings" element={<SettingsPageAccount />} />
              <Route path="/dashboard/settings/company" element={<SettingsPageCompany />} />
              <Route path="/dashboard/settings/notifications" element={<SettingsPageNotifications />} />
              <Route path="/dashboard/settings/users" element={<SettingsPageTeam />} />
              <Route path="/dashboard/settings/messages" element={<SettingsPageMessages />} />
              <Route path="/dashboard/settings/projects" element={<SettingsPageProjects />} />
              <Route path="/dashboard/settings/files" element={<SettingsPageFiles />} />
              <Route path="/dashboard/settings/contracts" element={<SettingsPageContracts />} />
              <Route path="/dashboard/settings/billing" element={<SettingsPageBilling />} />
              <Route path="/dashboard/settings/reports" element={<SettingsPageReports />} />
              <Route path="/dashboard/settings/meetings" element={<SettingsPageMeetings />} />
            </Route>
          </Routes>
        </WorkspaceProvider>
      </SocketProvider>
    </Router>
  );
}

export default App;
