// client/src/components/5 - General/Context/MessagesContext.js

import React, { createContext, useState, useContext, useEffect, useCallback, useMemo, useRef } from "react";
import { getMessages, getChannelById, getChannelIds, downloadFileWithLink } from "../../4 - API/API-Messages";
import { getAvailableUsers } from "../../4 - API/API-Users";
import { useInfiniteQuery, useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { formatTimestamp } from "../Utils/UtilsFormatData";
import { useSocket } from "../Socket/SocketContext";
import {
  useSendMessage,
  useUpdateMessage,
  useCreateChannel,
  useDeleteChannel,
  useUpdateChannel,
  useMarkMessagesAsRead,
} from "../Hooks/useUpdateMessages";
import { queryKeys } from "../../../queryKeys";
import { WorkspaceContext } from "./WorkspaceContext";
import { useNavigate, useLocation } from "react-router-dom";
import useCustomToast from "../Utils/UtilsNotification";

export const MessagesContext = createContext();

export const MessagesProvider = ({ children }) => {
  // **Socket Context**
  const { perChannelUnreadCounts, globalUnreadCount, socket } = useSocket();
  const previousMessagesRef = useRef([]);

  // State for active channel drawer on mobile
  const [isActiveDrawerOpen, setIsActiveDrawerOpen] = useState(false);
  const activeDrawerBtnRef = useRef();

  // **State Variables**
  const [currentChannelId, setCurrentChannelId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modals, setModals] = useState({});
  const [channelName, setChannelName] = useState("");

  // **Context and Query Client**
  const { currentUser } = useContext(WorkspaceContext);
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  // **Mutations using Unified Hooks**
  const sendMessageMutation = useSendMessage();
  const updateMessageMutation = useUpdateMessage();
  const createChannelMutation = useCreateChannel();
  const deleteChannelMutation = useDeleteChannel();
  const updateChannelMutation = useUpdateChannel();
  const markMessagesAsReadMutation = useMarkMessagesAsRead();

  const navigate = useNavigate();
  const location = useLocation();

  // **Fetch Channel IDs**
  const {
    data: channelIds,
    isLoading: isChannelIdsLoading,
    error: channelIdsError,
    isFetching: isFetchingChannelIds,
  } = useQuery({
    queryKey: queryKeys.channelIds(),
    queryFn: () => getChannelIds(),
    enabled: Boolean(currentUser),
  });

  const channelIdsArray = channelIds || [];

  // **Fetch Channel Data for Each Channel ID**
  const channelQueries = useQueries({
    queries: channelIdsArray.map((id) => ({
      queryKey: queryKeys.channel(id),
      queryFn: () => getChannelById(id),
      enabled: Boolean(id),
    })),
  });

  const isLoadingChannels = channelQueries.some((query) => query.isLoading);
  const isChannelsError = channelQueries.some((query) => query.isError);
  const channelsError = channelQueries.find((query) => query.isError)?.error;
  const isFetchingChannels = channelQueries.some((query) => query.isFetching);
  const channels = channelQueries.map((q) => q.data).filter(Boolean);

  // **Compute if All Channels Have Successfully Loaded**
  const isChannelsSuccess = channelQueries.length > 0 && channelQueries.every((query) => query.isSuccess);

  // **Use Infinite Query for Messages with Offset-Based Pagination**
  const {
    data: messagesPages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching: isFetchingMessages,
    isLoading: isMessagesLoading,
    error: messagesError,
  } = useInfiniteQuery({
    queryKey: queryKeys.messages(currentChannelId),
    queryFn: ({ pageParam = 0 }) => getMessages(currentChannelId, pageParam, 35),
    enabled: Boolean(currentChannelId),
    getNextPageParam: (lastPage, allPages) => {
      const nextSkip = allPages.reduce((acc, page) => acc + page.messages.length, 0);
      if (nextSkip < lastPage.totalMessages) {
        return nextSkip;
      }
      return undefined;
    },
    keepPreviousData: true,
  });

  const messages = useMemo(() => {
    return messagesPages ? messagesPages.pages.flatMap((page) => page.messages).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)) : [];
  }, [messagesPages]);

  const totalMessages = messagesPages?.pages[0]?.totalMessages || 0;
  const totalPages = messagesPages?.pages[0]?.totalPages || 1;

  // **Fetch Available Users for Inviting to Channels**
  const {
    data: availableUsers = [],
    isLoading: isAvailableUsersLoading,
    error: availableUsersError,
    isFetching: isFetchingAllAvailableUsers,
  } = useQuery({
    queryKey: queryKeys.availableUsers(currentUser?._id),
    queryFn: () => getAvailableUsers(),
    enabled: Boolean(currentUser),
  });

  // **Handle Channel Change**
  const handleChannelChange = useCallback(
    (channelId) => {
      if (channelId === currentChannelId) return;
      setCurrentChannelId(channelId);
      const channel = channels.find((ch) => ch._id === channelId);
      setChannelName(channel ? channel.name : "");
      if (channel) {
        if (perChannelUnreadCounts[channelId] > 0) {
          markMessagesAsReadMutation.mutate({ channelId });
        }
        setIsActiveDrawerOpen(true);
      } else {
        customToast({
          title: "Channel Not Found",
          description: "The conversation you're trying to access does not exist.",
          status: "error",
        });
        navigate("/dashboard/messages"); // Redirect to messages list
        setIsActiveDrawerOpen(false);
      }
    },
    [currentChannelId, channels, markMessagesAsReadMutation, customToast, navigate, perChannelUnreadCounts]
  );

  const handleChannelClick = (channelId) => {
    navigate(`/dashboard/messages/${channelId}`);
  };

  // **Centralized Channel Handling Based on URL**
  useEffect(() => {
    const pathMatch = location.pathname.match(/\/dashboard\/messages\/(\w+)/);
    const urlChannelId = pathMatch ? pathMatch[1] : null;

    if (urlChannelId) {
      // Ensure channels are fully loaded and no errors
      if (!isLoadingChannels && !isFetchingChannels && !isChannelIdsLoading && !isFetchingChannelIds && !isChannelsError && isChannelsSuccess) {
        const channelExists = channels.some((channel) => channel._id === urlChannelId);
        if (channelExists) {
          handleChannelChange(urlChannelId);
        } else {
          // Channel not found, handle error
          customToast({
            title: "Channel Not Found",
            description: "The conversation you're trying to access does not exist.",
            status: "error",
          });
          navigate("/dashboard/messages"); // Redirect to messages list
          setIsActiveDrawerOpen(false);
        }
      } else {
        // Loading Convos
      }
    } else {
      // No channelId in URL, set currentChannelId to null
      setCurrentChannelId(null);
      setIsActiveDrawerOpen(false);
    }
  }, [
    location.pathname,
    isLoadingChannels,
    isFetchingChannels,
    isChannelIdsLoading,
    isFetchingChannelIds,
    channels,
    handleChannelChange,
    customToast,
    navigate,
    isChannelsError,
    isChannelsSuccess,
  ]);

  // **Handle Mark Messages As Read**
  const handleMarkMessagesAsRead = async (channelId) => {
    try {
      await markMessagesAsReadMutation.mutateAsync({ channelId });
    } catch (error) {
      console.error("Error marking messages as read:", error);
      customToast({
        title: "Mark as Read Error",
        description: "Failed to mark messages as read.",
        status: "error",
      });
    }
  };

  useEffect(() => {
    if (!currentChannelId) return;

    const previousMessages = previousMessagesRef.current;
    const currentMessages = messages;

    // Determine if new messages have been added
    if (currentMessages.length > previousMessages.length) {
      // Identify new messages in the current channel
      const newMessages = currentMessages.filter(
        (msg) => msg.channelId === currentChannelId && !previousMessages.some((prevMsg) => prevMsg._id === msg._id)
      );
      if (newMessages.length > 0) {
        console.log(`New message(s) detected in channel ${currentChannelId}. Marking as read.`);
        markMessagesAsReadMutation.mutate({ channelId: currentChannelId });
      }
    }

    // Update the reference to the current messages for the next comparison
    previousMessagesRef.current = currentMessages;
  }, [messages, currentChannelId, markMessagesAsReadMutation, socket]);

  // **Send Message Handler**
  const sendMessage = async (content) => {
    try {
      await sendMessageMutation.mutateAsync({
        channelId: currentChannelId,
        content,
        file: selectedFile,
      });
      setSelectedFile(null);
      handleMarkMessagesAsRead(currentChannelId);
    } catch (error) {
      console.error("Error sending message:", error);
      customToast({
        title: "Send Message Error",
        description: "Failed to send your message.",
        status: "error",
      });
    }
  };

  // **Load More Messages Handler**
  const handleLoadMoreMessages = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  // **Edit Message Handler using Unified Hook**
  const handleEditMessage = async (messageId, content) => {
    try {
      await updateMessageMutation.mutateAsync({
        channelId: currentChannelId,
        action: "edit",
        payload: { messageId, newContent: content },
      });
    } catch (error) {
      console.error("Error editing message:", error);
      customToast({
        title: "Edit Message Error",
        description: "Failed to edit your message.",
        status: "error",
      });
    }
  };

  // **Delete Message Handler using Unified Hook**
  const handleDeleteMessage = async (messageId) => {
    try {
      await updateMessageMutation.mutateAsync({
        channelId: currentChannelId,
        action: "delete",
        payload: { messageId },
      });
    } catch (error) {
      console.error("Error deleting message:", error);
      customToast({
        title: "Delete Message Error",
        description: "Failed to delete the message.",
        status: "error",
      });
    }
  };

  // **Create Channel Handler**
  const handleCreateChannel = async (name, members) => {
    try {
      const newChannel = await createChannelMutation.mutateAsync({
        name,
        members,
      });
      setCurrentChannelId(newChannel._id);
      setModals((prev) => ({ ...prev, oneOnOne: false, create: false }));
    } catch (error) {
      console.error("Error creating channel:", error);
      customToast({
        title: "Create Channel Error",
        description: "Failed to create the channel.",
        status: "error",
      });
    }
  };

  // **Delete Channel Handler**
  const handleDeleteChannel = async () => {
    try {
      await deleteChannelMutation.mutateAsync({
        channelId: currentChannelId,
      });
      setCurrentChannelId(null);
      navigate(`/dashboard/messages`);
      setModals((prev) => ({ ...prev, delete: false }));
    } catch (error) {
      console.error("Error deleting channel:", error);
      customToast({
        title: "Delete Channel Error",
        description: "Failed to delete the channel.",
        status: "error",
      });
    }
  };

  // **Add Users to Channel Handler using Unified Hook**
  const handleAddUsersToChannel = async (userIds) => {
    try {
      await updateChannelMutation.mutateAsync({
        channelId: currentChannelId,
        action: "addUsers",
        payload: { userIds },
      });
      // Invalidate channel users to fetch the updated list
      queryClient.invalidateQueries({ queryKey: queryKeys.channelUsers(currentChannelId) });
      setModals((prev) => ({ ...prev, addUser: false }));
    } catch (error) {
      console.error("Error adding users to channel:", error);
      customToast({
        title: "Add Users Error",
        description: "Failed to add users to the channel.",
        status: "error",
      });
    }
  };

  // **Remove User from Channel Handler using Unified Hook**
  const handleRemoveUserFromChannel = async (userId) => {
    try {
      await updateChannelMutation.mutateAsync({
        channelId: currentChannelId,
        action: "removeUsers",
        payload: { userIds: [userId] }, // Payload expects an array
      });
      // Invalidate channel users to fetch the updated list
      queryClient.invalidateQueries({ queryKey: queryKeys.channelUsers(currentChannelId) });
    } catch (error) {
      console.error("Error removing user from channel:", error);
      customToast({
        title: "Remove User Error",
        description: "Failed to remove user from the channel.",
        status: "error",
      });
    }
  };

  // **Handle Download File**
  const handleDownloadFile = async (message) => {
    try {
      downloadFileWithLink(message.fileDownloadUrl); // Use the fileDownloadUrl to initiate download
    } catch (error) {
      console.error("Error downloading file:", error);
      customToast({
        title: "Download File Error",
        description: "Failed to download the file.",
        status: "error",
      });
    }
  };

  // **Rename Channel Handler using Unified Hook**
  const handleRenameChannel = async (newName) => {
    try {
      await updateChannelMutation.mutateAsync({
        channelId: currentChannelId,
        action: "rename",
        payload: { newName },
      });
      setChannelName(newName);
    } catch (error) {
      console.error("Error renaming channel:", error);
    }
  };

  // **Normalize and Group Messages for Display**
  const normalizeMessage = (message) => {
    if (!message.sender) {
      // Handle system messages or messages without a sender
      return {
        ...message,
        sender: {
          _id: "system",
          firstName: "System",
          lastName: "Message",
        },
        content: typeof message.content === "string" ? message.content : "",
      };
    }

    return {
      ...message,
      sender: {
        _id: message.sender._id, // Ensure correct id is used
        firstName: message.sender.firstName || "Unknown", // Fallback if firstName is missing
        lastName: message.sender.lastName || "", // Fallback if lastName is missing
      },
      content: typeof message.content === "string" ? message.content : "",
    };
  };

  // Group messages based on sender and sequence
  const groupMessages = useCallback(
    (messages, existingGrouped = []) => {
      const grouped = [...existingGrouped];
      let lastSender = existingGrouped.length > 0 ? existingGrouped[existingGrouped.length - 1].sender?._id || null : null;

      messages.forEach((msg, index) => {
        const isUserMessage = msg.sender && msg.sender._id === currentUser?._id;
        const settings = {
          showOffsetOther: false,
          showOffsetUser: false,
          showAvatarOther: false,
          showAvatarUser: false,
          showName: false,
          showDate: false,
          isSystemMessage: msg.isSystemMessage || false,
          messageClass: isUserMessage ? "messages-user" : "messages-other",
        };

        if (msg.isSystemMessage) {
          settings.showDate = true;
          settings.messageClass = "messages-system";

          grouped.push({
            ...msg,
            ...settings,
            content: msg.content || "",
            key: `msg-${msg._id}-${index}`, // Stable key
          });

          lastSender = null;
        } else {
          if (!msg.sender) {
            console.error("Message sender is null:", msg);
            return;
          }

          if (msg.sender._id !== lastSender) {
            settings.showAvatarOther = !isUserMessage;
            settings.showAvatarUser = isUserMessage;
            settings.showName = true;
          } else {
            settings.showOffsetOther = !isUserMessage;
            settings.showOffsetUser = isUserMessage;
          }

          const isLastMessage = index === messages.length - 1;
          const isNextSenderDifferent = messages[index + 1] && messages[index + 1].sender && messages[index + 1].sender._id !== msg.sender._id;

          if (isLastMessage || isNextSenderDifferent) {
            settings.showDate = true;
          }

          grouped.push({
            ...msg,
            ...settings,
            content: msg.content || "",
            key: `msg-${msg._id}-${index}`, // Stable key
          });

          lastSender = msg.sender?._id || null;
        }
      });

      return grouped;
    },
    [currentUser]
  );

  // Memoize normalized and grouped messages for performance
  const normalizedMessages = useMemo(() => {
    return messages.map(normalizeMessage);
  }, [messages]);

  const groupedMessagesMemo = useMemo(() => {
    return groupMessages(normalizedMessages);
  }, [normalizedMessages, groupMessages]);

  // **Handle File Selection**
  const handleFileChangeHandler = (file) => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
    if (file && file.size > MAX_FILE_SIZE) {
      customToast({
        title: "File Too Large",
        description: "Please select a file smaller than 10MB.",
        status: "error",
      });
      setSelectedFile(null);
      return;
    }
    setSelectedFile(file);
  };

  // **Handle Image Preview**
  const handleImagePreviewHandler = (image) => {
    setSelectedImage(image);
    setModals((prev) => ({ ...prev, imagePreview: true }));
  };

  const currentChannelType = channels.find((ch) => ch._id === currentChannelId)?.conversationType || "group";

  return (
    <MessagesContext.Provider
      value={{
        // **State Variables**
        currentChannelId,
        currentChannelType,
        channels,
        messages,
        currentUser,
        channelName,
        availableUsers,
        modals,
        selectedFile,
        selectedImage,

        // **Unread Count and Channel Users**
        perChannelUnreadCounts,
        globalUnreadCount,

        // **Handlers**
        handleChannelClick,
        handleLoadMoreMessages,
        sendMessage,
        handleEditMessage,
        handleDeleteMessage,
        handleCreateChannel,
        handleDownloadFile,
        handleDeleteChannel,
        handleAddUsersToChannel,
        handleRemoveUserFromChannel,
        handleRenameChannel,
        handleFileChange: handleFileChangeHandler,
        handleImagePreview: handleImagePreviewHandler,

        // **Loading States**
        isChannelIdsLoading,
        isFetchingChannelIds,
        isFetchingChannels,
        isLoadingChannels,
        isAvailableUsersLoading,
        isMessagesLoading,
        isFetchingMessages,
        isFetchingAllAvailableUsers,
        isFetchingNextPage,

        // **Errors**
        channelIdsError,
        channelsError,
        messagesError,
        availableUsersError,
        isChannelsError,

        // **Utility Functions**
        formatTimestamp,

        // **Pagination Info**
        hasNextPage,
        totalMessages,
        totalPages,

        // **Others**
        setModals,
        groupedMessages: groupedMessagesMemo, // Exposed grouped messages for easier access
        isActiveDrawerOpen,
        setIsActiveDrawerOpen,
        activeDrawerBtnRef,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};
