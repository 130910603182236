// client/src/components/Projects/Modals/CreateTaskModal.jsx

import React, { useState, useContext, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  HStack,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  useColorModeValue,
  Text,
  Tag,
  TagCloseButton,
  TagLabel,
  IconButton,
} from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import { PiXBold, PiArrowRightBold } from "react-icons/pi";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import TagMenu from "../../2 - Components/Reusable/TagMenu";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";

const CreateTaskModal = ({ isOpen, onClose, projectId, parentTask }) => {
  const { createTask, currentProjectId, activeProjects } = useContext(ProjectContext);
  const currentProject = activeProjects.find((project) => project._id === currentProjectId);

  // State variables
  const [taskName, setTaskName] = useState("");
  const [status, setStatus] = useState("Not Started");
  const [dueDate, setDueDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [priority, setPriority] = useState("");
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [selectedParentTask, setSelectedParentTask] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const arrowColor = useColorModeValue("gray.500", "gray.300");
  const customToast = useCustomToast();

  // Initialize form fields when modal opens
  useEffect(() => {
    if (isOpen) {
      setTaskName("");
      setStatus("Not Started");
      setPriority("");
      setAssignedUsers([]);
      setTags([]);
      setTagInput("");
      setSelectAllUsers(false);
      setSelectedParentTask(parentTask ? { value: parentTask._id, label: parentTask.name } : null);
    }
  }, [isOpen, parentTask]);

  // Populate availableUsers with current project members
  useEffect(() => {
    if (isOpen && currentProject && currentProject.members) {
      const formattedMembers = currentProject.members.map((member) => ({
        value: member,
      }));
      setAvailableUsers(formattedMembers);
    } else {
      setAvailableUsers([]);
    }
  }, [isOpen, currentProject]);

  // Task Options for Parent Task Selection
  const taskOptions = useMemo(() => {
    return (
      currentProject?.tasks?.map((task) => ({
        value: task._id.toString(),
        label: task.name,
      })) || []
    );
  }, [currentProject]);

  // Reset form fields
  const resetForm = useCallback(() => {
    setTaskName("");
    setStatus("Not Started");
    setPriority("");
    setAssignedUsers([]);
    setTags([]);
    setTagInput("");
    setSelectAllUsers(false);
    setSelectedParentTask(null);
  }, []);

  // Handle Task Submission
  const handleTaskSubmit = useCallback(async () => {
    if (!projectId) {
      console.error("Project ID is undefined.");
      customToast({
        title: "Error",
        description: "Project ID is undefined.",
        status: "error",
      });
      return;
    }

    if (!taskName.trim()) {
      customToast({
        title: "Error",
        description: "Task name is required.",
        status: "error",
      });
      return;
    }

    const taskData = {
      name: taskName,
      status,
      startDate,
      dueDate,
      priority,
      assignedTo: selectAllUsers ? availableUsers.map((user) => user.value) : assignedUsers.map((user) => user.value),
      tags,
      parentTaskId: selectedParentTask ? selectedParentTask.value : null,
    };

    try {
      setIsLoading(true);
      await createTask({
        projectId,
        taskData,
      });
      customToast({
        title: "Task Created",
        description: "The task has been created successfully.",
        status: "success",
      });
      onClose();
      resetForm();
    } catch (error) {
      console.error("Error creating task:", error);
      customToast({
        title: "Error",
        description: error.message || "Error creating the task.",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    projectId,
    taskName,
    status,
    startDate,
    dueDate,
    priority,
    selectAllUsers,
    availableUsers,
    assignedUsers,
    tags,
    selectedParentTask,
    createTask,
    resetForm,
    customToast,
    onClose,
  ]);

  // Handle Adding a Tag
  const handleAddTag = useCallback(() => {
    if (tagInput.trim()) {
      setTags((prevTags) => [...prevTags, tagInput.trim()]);
      setTagInput("");
    }
  }, [tagInput]);

  // Handle Removing a Tag
  const handleRemoveTag = useCallback((index) => {
    setTags((prevTags) => prevTags.filter((_, i) => i !== index));
  }, []);

  // Custom Option Component for Users
  const CustomUserOption = useCallback((props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Box ref={innerRef} {...innerProps} _hover={{ bg: "gray.100" }}>
        <HStack spacing={3} px={6} py={1}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
        </HStack>
      </Box>
    );
  }, []);

  // Custom MultiValue Component for Users
  const CustomUserMultiValue = useCallback((props) => {
    const { data, innerRef, innerProps, removeProps } = props;
    return (
      <Box
        ref={innerRef}
        {...innerProps}
        mb={1}
        bg="gray.50"
        borderRadius="xl"
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={1}
      >
        <HStack spacing={1}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
          <Text>{data.label}</Text>
        </HStack>
        <IconButton
          icon={<PiXBold fontSize="16px" />}
          colorScheme="gray"
          variant="ghost"
          size="sm"
          onClick={removeProps.onClick}
          aria-label="Remove"
        />
      </Box>
    );
  }, []);

  // Define the footer buttons
  const footerButtons = (
    <HStack ml="auto" spacing={3}>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
      <Button
        colorScheme="blue"
        onClick={handleTaskSubmit}
        isLoading={isLoading}
        disabled={!taskName.trim() || (selectAllUsers ? availableUsers.length === 0 : assignedUsers.length === 0)}
      >
        Create Task
      </Button>
    </HStack>
  );

  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title="Create a New Task"
      footerButtons={footerButtons}
      size="lg"
      isCentered
      scrollBehavior="inside"
    >
      {/* Task Name */}
      <FormControl isRequired p={4} pt={2}>
        <FormLabel fontSize="md" fontWeight="500">
          Task Name
        </FormLabel>
        <Input value={taskName} onChange={(e) => setTaskName(e.target.value)} placeholder="Enter task name..." />
      </FormControl>

      {/* Parent Task Selection */}
      {parentTask && (
        <FormControl p={4}>
          <FormLabel fontSize="md" fontWeight="500">
            Parent Task (Optional)
          </FormLabel>
          <Select
            placeholder="Select parent task..."
            value={selectedParentTask}
            onChange={(option) => setSelectedParentTask(option)}
            options={taskOptions}
            isClearable
            components={chakraComponents}
            closeMenuOnSelect={true}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </FormControl>
      )}

      {/* Task Duration */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Task Duration
        </FormLabel>
        <HStack spacing={2}>
          <UtilsDatePicker value={startDate} placeholder="Start Date" onChange={setStartDate} inputVariant="default" />
          <PiArrowRightBold color={arrowColor} />
          <UtilsDatePicker
            value={dueDate}
            placeholder="Due Date"
            onChange={setDueDate}
            inputVariant="default"
            isDisabled={!startDate}
            startDate={startDate ? startDate : null}
            highlightDates={startDate ? [startDate] : []}
            highlightTooltips={startDate ? [`Task Start Date: ${startDate.toLocaleDateString()}`] : []}
          />
        </HStack>
      </FormControl>

      {/* Status and Priority */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Task Status and Priority
        </FormLabel>
        <HStack spacing={4}>
          <TagMenu
            options={[
              { value: "Not Started", label: "Not Started", color: "gray" },
              { value: "In Progress", label: "In Progress", color: "blue" },
              { value: "Completed", label: "Completed", color: "green" },
              { value: "On Hold", label: "On Hold", color: "orange" },
            ]}
            selectedValue={status}
            field="status"
            handleBlurSave={(id, field, value) => setStatus(value)} // Corrected signature
          />
          <TagMenu
            options={[
              { value: "Low", label: "Low", color: "green" },
              { value: "Medium", label: "Medium", color: "yellow" },
              { value: "High", label: "High", color: "red" },
            ]}
            selectedValue={priority}
            field="priority"
            handleBlurSave={(id, field, value) => setPriority(value)} // Corrected signature
          />
        </HStack>
      </FormControl>

      {/* Assign Members */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Assign Members
        </FormLabel>
        {!selectAllUsers && (
          <Select
            isMulti
            placeholder="Select members..."
            options={availableUsers}
            value={assignedUsers}
            onChange={setAssignedUsers}
            components={{
              Option: CustomUserOption,
              MultiValue: CustomUserMultiValue,
            }}
            closeMenuOnSelect={false}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (base) => ({ ...base, zIndex: 9999 }),
            }}
            isDisabled={availableUsers.length === 0}
          />
        )}
        <Checkbox isChecked={selectAllUsers} onChange={(e) => setSelectAllUsers(e.target.checked)} mt={2}>
          Select all users from assigned companies
        </Checkbox>
      </FormControl>

      {/* Tags */}
      <FormControl id="tags" mt={4} p={4}>
        <FormLabel>Tags</FormLabel>
        <HStack>
          <Input
            placeholder="Add a tag"
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleAddTag()}
          />
          <Button px={4} onClick={handleAddTag} h="32px" variant="outline">
            Add Tag
          </Button>
        </HStack>
        <Box mt={2}>
          {tags.map((tag, index) => (
            <Tag key={index} borderRadius="full" colorScheme="blue" mr={1} pl={3} pr={3}>
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton onClick={() => handleRemoveTag(index)} />
            </Tag>
          ))}
        </Box>
      </FormControl>
    </ReusableModal>
  );
};

export default CreateTaskModal;
