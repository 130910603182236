import React from "react";
import { Box, Button, Text, VStack, Heading, Image } from "@chakra-ui/react";
import { PiRepeatBold } from "react-icons/pi";
import LoginImg from '../../../img/logoWhiteText.png';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary: ", error, errorInfo);
  }

  handleRefresh = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Box
          minH="100vh"
          bgGradient="linear(to-r, blue.700, primary.400)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="white"
          textAlign="center"
          px={4}
        >
          <VStack spacing={6}>
            <Image src={LoginImg} alt="Company Logo" boxSize={["200px", "150px"]} objectFit="cover" />
            <Heading as="h1" size={["xl", "2xl"]}>
              Oops! Something went wrong.
            </Heading>
            <Text fontSize="lg">An unexpected error occurred. Please try refreshing the page.</Text>
            <Button leftIcon={<PiRepeatBold />} colorScheme="white" variant="solid" size="lg" onClick={this.handleRefresh}>
              Refresh the page
            </Button>
          </VStack>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
