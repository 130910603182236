// client\src\components\5 - General\Hooks\useUpdateFile.js

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSocket } from "../Socket/SocketContext";
import useCustomToast from "../Utils/UtilsNotification";
import { uploadFile } from "../../4 - API/API-Files";
import { queryKeys } from "../../../queryKeys";

// Hook to create a new folder
export const useCreateFolder = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const createNewFolder = ({ folderName, companyId, parentFolderId }) => {
    return new Promise((resolve, reject) => {
      const payload = { folderName, companyId, parentFolderId };
      socket.emit("createFolder", payload, (response) => {
        if (response.status === "ok") {
          resolve(response.folder);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: createNewFolder,
    mutationKey: "createFolder",
    onSuccess: (newFolder) => {
      customToast({
        title: "Folder Created",
        description: `Folder "${newFolder.originalFileName}" created successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to create folder.",
        status: "error",
      });
      console.error("Error creating folder:", error);
    },
  });
};

export const useUploadFile = () => {
  const customToast = useCustomToast();

  const uploadFileMutationFn = ({ file, companyId, parentFolderId }) => {
    return uploadFile(file, companyId, parentFolderId);
  };

  return useMutation({
    mutationFn: uploadFileMutationFn,
    mutationKey: "uploadFile",
    onError: (error) => {
      customToast({
        title: "Upload Failed",
        description: error.message || "Failed to upload file.",
        status: "error",
      });
      console.error("Error uploading file:", error);
    },
  });
};

// Hook to delete files
export const useDeleteFile = () => {
  const { socket } = useSocket();
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  const deleteSelectedFiles = ({ fileIds, companyId }) => {
    return new Promise((resolve, reject) => {
      socket.emit("deleteFile", { fileIds, companyId }, (response) => {
        if (response.status === "ok") {
          resolve(response.deletedFiles);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: deleteSelectedFiles,
    mutationKey: "deleteFile",
    onSuccess: (deletedFiles) => {
      if (deletedFiles.length > 0) {
        const companyId = deletedFiles[0].companyId;
        const parentFolderId = deletedFiles[0].parentFolder || null;
        const deletedIds = new Set(deletedFiles.map((file) => file._id));

        queryClient.setQueryData(queryKeys.files(companyId, parentFolderId), (oldData) => {
          if (!oldData) return [];
          return oldData.filter((file) => !deletedIds.has(file._id));
        });
      }

      customToast({
        title: "Files Deleted",
        description: `${deletedFiles.length} file${deletedFiles.length !== 1 ? "s" : ""} deleted successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to delete files.",
        status: "error",
      });
      console.error("Error deleting files:", error);
    },
  });
};

// Hook to rename files or folders
export const useRenameFile = () => {
  const { socket } = useSocket();
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  const renameSelectedItem = ({ itemId, newName }) => {
    return new Promise((resolve, reject) => {
      const payload = { itemId, newName };
      socket.emit("renameFileOrFolder", payload, (response) => {
        if (response.status === "ok") {
          resolve(response.file);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: renameSelectedItem,
    mutationKey: "renameFileOrFolder",
    onSuccess: (file) => {
      if (!file) {
        customToast({
          title: "Error",
          description: "No file data received.",
          status: "error",
        });
        return;
      }

      // If `file.parentFolder` is an object with _id, use that; otherwise null
      const folderId = file.parentFolder?._id || null;

      queryClient.setQueryData(queryKeys.files(file.companyId, folderId), (oldData) => {
        // If there's no data yet, create our consistent shape:
        if (!oldData) {
          return {
            filesAndFolders: [file],
            folderPath: [], // or whatever else is appropriate
          };
        }

        // Otherwise, update filesAndFolders by mapping over them
        return {
          ...oldData,
          filesAndFolders: oldData.filesAndFolders.map((oldFile) => (oldFile._id === file._id ? file : oldFile)),
        };
      });

      customToast({
        title: "Item Renamed",
        description: `Item renamed to "${file.originalFileName}".`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to rename item.",
        status: "error",
      });
      console.error("Error renaming item:", error);
    },
  });
};

// Hook to create a new link
export const useCreateLink = () => {
  const { socket } = useSocket();
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  const createNewLink = ({ linkName, linkUrl, companyId, parentFolderId, userIds = [] }) => {
    return new Promise((resolve, reject) => {
      const payload = { linkName, linkUrl, companyId, parentFolderId, userIds };
      socket.emit("createLink", payload, (response) => {
        if (response.status === "ok") {
          resolve(response.link);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: createNewLink,
    mutationKey: "createLink",
    onSuccess: (newLink) => {
      queryClient.invalidateQueries(queryKeys.files(newLink.companyId, newLink.parentFolder || null));
      customToast({
        title: "Link Created",
        description: `Link created successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to create link.",
        status: "error",
      });
      console.error("Error creating link:", error);
    },
  });
};

// Updated Hook to move files or folders
export const useMoveFiles = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const moveFilesToFolder = ({ fileIds, newParentFolderId, companyId }) => {
    return new Promise((resolve, reject) => {
      const payload = { fileIds, newParentFolderId, companyId };
      socket.emit("moveFiles", payload, (response) => {
        if (response.status === "ok") {
          resolve(response.movedFiles);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: moveFilesToFolder,
    mutationKey: ["moveFiles"],
    onSuccess: (movedFiles) => {
      if (!movedFiles || movedFiles.length === 0) {
        console.error("No movedFiles found in the response.");
        return;
      }

      // Single toast notification
      customToast({
        title: "Files Moved",
        description: `${movedFiles.length} file${movedFiles.length !== 1 ? "s" : ""} moved successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to move files.",
        status: "error",
      });
      console.error("Error moving files:", error);
    },
  });
};
