import React, { useContext, useState } from "react";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { formatDueDate } from "../../5 - General/Utils/UtilsFormatData";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import UserAvatar from "../Reusable/UserAvatar";
import TagMenu from "../Reusable/TagMenu";
import CreateTaskModal from "../../3 - Modal/3 - Projects Modals/ProjectsCreateTaskModal";

const SubtaskSection = () => {
  const { currentTaskId, currentProjectId, tasks, subtasks, editTask, isLoading: loadingSubtasks } = useContext(ProjectContext);
  const currentTask = tasks.find((task) => task._id === currentTaskId);

  // Local state to handle the create-task modal visibility
  const [isCreateSubtaskModalOpen, setIsCreateSubtaskModalOpen] = useState(false);

  const borderColor = useColorModeValue("gray.200", "gray.750");
  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("gray.50", "gray.700");

  // Example status/priority options
  const statusOptions = [
    { value: "Not Started", label: "Not Started", color: "gray" },
    { value: "In Progress", label: "In Progress", color: "blue" },
    { value: "Completed", label: "Completed", color: "green" },
    { value: "On Hold", label: "On Hold", color: "orange" },
  ];
  const priorityOptions = [
    { value: "High", label: "High", color: "red" },
    { value: "Medium", label: "Medium", color: "yellow" },
    { value: "Low", label: "Low", color: "green" },
  ];

  // This is the array of subtasks for the current task
  const currentSubtasks = subtasks;

  // Handlers for status/priority changes
  const onStatusChange = (subtaskId, field, value) => {
    editTask(subtaskId, { [field]: value });
  };
  const onPriorityChange = (subtaskId, field, value) => {
    editTask(subtaskId, { [field]: value });
  };

  return (
    <Box>
      {/* The existing subtask headers/listing */}
      {currentSubtasks.length > 0 ? (
        <Box
          display="grid"
          gridTemplateColumns={"8fr 2fr 2fr 2fr 2fr"}
          bg="gray.100"
          p={4}
          borderBottom="1px solid"
          borderColor={borderColor}
          pl="40px"
        >
          <Box fontWeight="600" fontSize="16px">
            Subtasks
          </Box>
          <Box fontWeight="600" fontSize="16px">
            Due Date
          </Box>
          <Box fontWeight="600" fontSize="16px">
            Status
          </Box>
          <Box fontWeight="600" fontSize="16px">
            Priority
          </Box>
          <Box fontWeight="600" fontSize="16px">
            Assigned To
          </Box>
        </Box>
      ) : null}

      {/* Render each subtask row */}
      {currentSubtasks.length > 0 ? (
        currentSubtasks.map((subtask) => (
          <Box
            key={subtask._id}
            display="grid"
            gridTemplateColumns={"8fr 2fr 2fr 2fr 2fr"}
            bg={bgColor}
            _hover={{ backgroundColor: hoverBg }}
            transition="background-color 0.2s"
            alignItems="center"
            cursor="pointer"
            borderBottom="1px solid"
            borderColor={borderColor}
            py="10px"
            pl={4}
          >
            {/* Subtask name */}
            <Box display="flex" alignItems="center">
              <Box w="10px" h="30px" bg={subtask.color || "primary.500"} mr={4} borderRadius="md" />
              <Text fontWeight="400" fontSize="lg" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                {subtask.name}
              </Text>
            </Box>
            {/* Due date */}
            <Box>{subtask.dueDate ? <Text>{formatDueDate(subtask.dueDate)}</Text> : "No Due Date"}</Box>
            {/* Status */}
            <Box>
              <TagMenu
                options={statusOptions}
                selectedValue={subtask.status}
                field="status"
                handleBlurSave={onStatusChange}
                subtaskId={subtask._id}
              />
            </Box>
            {/* Priority */}
            <Box>
              <TagMenu
                options={priorityOptions}
                selectedValue={subtask.priority}
                field="priority"
                handleBlurSave={onPriorityChange}
                subtaskId={subtask._id}
              />
            </Box>
            {/* Assigned to */}
            <Box>
              <UserAvatar userIds={subtask.assignedTo} size="sm" useGroup={true} maxAvatars={3} spacing={-1} />
            </Box>
          </Box>
        ))
      ) : !loadingSubtasks ? (
        <Box p={4} display="flex" justifyContent="center">
          <Text mt={4} fontSize='md'  color="gray.500" pl={8}>
            No subtasks available.
          </Text>
        </Box>
      ) : (
        <Text mt={4} color="gray.500" pl={4}>
          Loading subtasks...
        </Text>
      )}

      {/* CREATE SUBTASK MODAL */}
      <CreateTaskModal
        isOpen={isCreateSubtaskModalOpen}
        onClose={() => setIsCreateSubtaskModalOpen(false)}
        projectId={currentProjectId}
        parentTask={currentTask}
      />
    </Box>
  );
};

export default SubtaskSection;
