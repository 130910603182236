// client/src/components/Projects/Modals/EditTaskModal.jsx

import React, { useState, useEffect, useContext } from "react";
import { Button, FormControl, FormLabel, Input, HStack, FormErrorMessage } from "@chakra-ui/react";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";

const EditTaskModal = ({ isOpen, onClose, task }) => {
  const { editTask } = useContext(ProjectContext);
  const [taskName, setTaskName] = useState(task.name);
  const [isError, setIsError] = useState(false);

  // Reset form when modal opens/closes or task changes
  useEffect(() => {
    if (isOpen) {
      setTaskName(task.name);
      setIsError(false);
    }
  }, [isOpen, task]);

  const handleSave = () => {
    if (taskName.trim() === "") {
      setIsError(true);
      return;
    }

    // Call the editTask mutation
    editTask.mutate(
      { taskId: task._id, updates: { name: taskName } },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  // Define the footer buttons
  const footerButtons = (
    <HStack spacing={3}>
      <Button variant="ghost" onClick={onClose}>
        Cancel
      </Button>
      <Button colorScheme="blue" onClick={handleSave} isLoading={editTask.isLoading}>
        Save
      </Button>
    </HStack>
  );

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Edit Task" footerButtons={footerButtons} size="md" scrollBehavior="inside">
      <FormControl id="task-name" isRequired isInvalid={isError}>
        <FormLabel>Task Name</FormLabel>
        <Input
          value={taskName}
          onChange={(e) => {
            setTaskName(e.target.value);
            if (e.target.value.trim() !== "") {
              setIsError(false);
            }
          }}
          placeholder="Enter new task name"
        />
        {isError && <FormErrorMessage>Task name cannot be empty.</FormErrorMessage>}
      </FormControl>
    </ReusableModal>
  );
};

export default EditTaskModal;
