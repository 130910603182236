// client/src/components/Projects/Modals/AssignMembersModal.jsx

import React, { useState, useEffect, useContext, useMemo } from "react";
import { Button, Spinner, IconButton, HStack, Box, FormControl, FormLabel, Checkbox, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { PiXBold } from "react-icons/pi";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import { useQuery } from "@tanstack/react-query";
import { getAvailableUserAndCompanyOptions } from "../../4 - API/API-Projects";
import isEqual from "lodash/isEqual";

const AssignMembersModal = ({ isOpen, onClose, currentProject }) => {
  const { currentUser } = useContext(WorkspaceContext);
  const { assignMembersToProject } = useContext(ProjectContext);

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectAllUsers, setSelectAllUsers] = useState(false);

  const customToast = useCustomToast();

  // Fetch available users and companies when the modal is open
  const { data: availableUserAndCompanyOptions = [], isLoading: isAvailableUserAndCompanyOptionsLoading } = useQuery({
    queryKey: ["availableUsers", currentUser?._id],
    queryFn: () => getAvailableUserAndCompanyOptions(currentUser?._id),
    enabled: isOpen && Boolean(currentUser),
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
  });

  // Extract assigned company IDs from the current project
  const assignedCompanyIds = useMemo(() => {
    if (!currentProject || !currentProject.assignedToCompanies) return [];
    return currentProject.assignedToCompanies.map((company) => company._id || company);
  }, [currentProject]);

  // Filter users based on assigned companies
  const memberOptions = useMemo(() => {
    if (!availableUserAndCompanyOptions || availableUserAndCompanyOptions.length === 0) return [];

    return availableUserAndCompanyOptions
      .filter((user) => assignedCompanyIds.includes(user.companyId))
      .map((user) => ({
        value: user.value, // Ensure 'value' matches currentProject.members
        label: `${user.firstName} ${user.lastName}`,
        user,
      }));
  }, [availableUserAndCompanyOptions, assignedCompanyIds]);

  // Initialize selected members when modal opens or memberOptions change
  useEffect(() => {
    if (isOpen && currentProject && memberOptions.length > 0) {
      const initialSelectedMembers = memberOptions.filter((member) => currentProject.members.includes(member.value));

      // Only update if different
      if (!isEqual(initialSelectedMembers, selectedMembers)) {
        setSelectedMembers(initialSelectedMembers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, currentProject, memberOptions]);

  // Synchronize selectAllUsers based on selectedMembers
  useEffect(() => {
    if (isOpen) {
      const shouldSelectAll = selectedMembers.length === memberOptions.length && memberOptions.length > 0;
      if (selectAllUsers !== shouldSelectAll) {
        setSelectAllUsers(shouldSelectAll);
      }
    }
  }, [selectedMembers, memberOptions, isOpen, selectAllUsers]);

  // Handle "Select All Users" checkbox
  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAllUsers(checked);
    if (checked) {
      setSelectedMembers(memberOptions);
    } else {
      const currentUserOption = memberOptions.find((user) => user.value === currentUser?._id);
      setSelectedMembers(currentUserOption ? [currentUserOption] : []);
    }
  };

  const handleSubmit = async () => {
    if (selectAllUsers) {
      // Select all member IDs from assigned companies
      const allMemberIds = memberOptions.map((user) => user.value);
      await submitAssignment(allMemberIds);
    } else {
      if (selectedMembers.length === 0) {
        customToast({
          title: "Error",
          description: "Please select at least one member or choose to select all users.",
          status: "error",
        });
        return;
      }
      const selectedMemberIds = selectedMembers.map((user) => user.value);
      await submitAssignment(selectedMemberIds);
    }
  };

  const submitAssignment = async (memberIds) => {
    try {
      await assignMembersToProject({ projectId: currentProject._id, memberIds });
      onClose();
      resetForm();
    } catch (error) {
      // Error handling is already managed in the hook
      console.error("Error assigning members:", error);
    }
  };

  const resetForm = () => {
    setSelectedMembers([]);
    setSelectAllUsers(false);
  };

  // Custom Option Component for Users
  const CustomUserOption = React.memo((props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Box ref={innerRef} {...innerProps} _hover={{ bg: "gray.100" }}>
        <HStack spacing={3} px={6} py={1}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
        </HStack>
      </Box>
    );
  });

  // Custom MultiValue Component for Users
  const CustomUserMultiValue = React.memo((props) => {
    const { data, innerRef, innerProps, removeProps } = props;
    return (
      <Box
        ref={innerRef}
        {...innerProps}
        mb={2}
        bg="gray.50"
        borderRadius="md"
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={1}
      >
        <HStack spacing={3}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
        </HStack>
        <IconButton
          icon={<PiXBold fontSize="16px" />}
          colorScheme="gray"
          variant="ghost"
          size="sm"
          onClick={removeProps.onClick}
          aria-label="Remove member"
        />
      </Box>
    );
  });

  // Define the footer buttons
  const footerButtons = (
    <HStack ml="auto" spacing={3}>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
      <Button
        colorScheme="primary"
        onClick={handleSubmit}
        isLoading={isAvailableUserAndCompanyOptionsLoading}
        disabled={isAvailableUserAndCompanyOptionsLoading}
      >
        Save
      </Button>
    </HStack>
  );

  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title="Assign Members"
      footerButtons={footerButtons}
      size="xl"
      isCentered
      scrollBehavior="inside"
    >
      {/* Members Selection */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Select Members
        </FormLabel>
        <Select
          isMulti
          placeholder="Select members..."
          options={memberOptions}
          value={selectedMembers}
          onChange={(selected) => {
            setSelectedMembers(selected || []);
          }}
          components={{
            Option: CustomUserOption,
            MultiValue: CustomUserMultiValue,
          }}
          closeMenuOnSelect={false}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (base) => ({ ...base, zIndex: 9999 }),
          }}
          isDisabled={memberOptions.length === 0}
        />
      </FormControl>

      {/* Select All Users Checkbox */}
      <FormControl p={4}>
        <Checkbox isChecked={selectAllUsers} onChange={handleSelectAllChange} mt={2} isDisabled={memberOptions.length === 0}>
          Select all users from assigned companies
        </Checkbox>
      </FormControl>

      {/* Loading State for Members */}
      {isAvailableUserAndCompanyOptionsLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" py={4}>
          <Spinner />
        </Box>
      )}

      {/* No Members Available */}
      {!isAvailableUserAndCompanyOptionsLoading && memberOptions.length === 0 && (
        <Box p={4}>
          <Text>No members available to assign.</Text>
        </Box>
      )}
    </ReusableModal>
  );
};

export default AssignMembersModal;
