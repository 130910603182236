// src/components/3 - Modal/2 - Messages Modals/MessagesImagePreviewModal.js

import React, { useContext } from "react";
import { Box, Button, Image, useColorModeValue } from "@chakra-ui/react";
import { downloadFileWithLink } from "../../4 - API/API-Messages";
import { PiDownloadSimple } from "react-icons/pi";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";
import ReusableModal from "../9 - General Modals/ReusableModal";

const MessagesImagePreviewModal = ({ isOpen, onClose, selectedImage }) => {
  const { themeColor } = useContext(WorkspaceContext);
  const buttonColorScheme = useColorModeValue("gray", themeColor);
  const imageUrl = selectedImage?.url || "";

  const downloadFile = async () => {
    if (!selectedImage.url || !selectedImage.originalFileName) return;
    try {
      await downloadFileWithLink(imageUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  if (!selectedImage) return null;

  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title="Image Preview"
      size="lg"
      footerButtons={
        <Button
          ml="auto"
          colorScheme={buttonColorScheme}
          leftIcon={<PiDownloadSimple size="16px" />}
          onClick={(e) => {
            e.stopPropagation();
            downloadFile();
          }}
        >
          Download Image
        </Button>
      }
    >
      <Box alignSelf="center" justifySelf="center">
        <Image src={imageUrl} alt={selectedImage.originalFileName} objectFit="contain" maxH="60vh" mb={4} borderRadius="md" />
      </Box>
    </ReusableModal>
  );
};

export default MessagesImagePreviewModal;
