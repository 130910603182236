// client/src/components/5 - General/Hooks/useUpdateProject.js

import { useMutation } from "@tanstack/react-query";
import { useSocket } from "../Socket/SocketContext";
import useCustomToast from "../Utils/UtilsNotification";

// Hook to create a new project
export const useCreateProject = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const createNewProject = (projectData) => {
    return new Promise((resolve, reject) => {
      socket.emit("createProject", { ...projectData }, (response) => {
        if (response.status === "ok") {
          resolve(response.project);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: createNewProject,
    mutationKey: "createProject",
    onSuccess: () => {
      customToast({
        title: "Project Created",
        description: `Project created successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to create project.",
        status: "error",
      });
      console.error("Error creating project:", error);
    },
  });
};

// Hook to edit an existing project
export const useEditProject = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const editExistingProject = ({ projectId, updates }) => {
    return new Promise((resolve, reject) => {
      socket.emit("editProject", { projectId, updates }, (response) => {
        if (response.status === "ok") {
          resolve(response.project);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: editExistingProject,
    mutationKey: "editProject",
    onSuccess: () => {
      customToast({
        title: "Project Updated",
        description: `Project updated successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to edit project.",
        status: "error",
      });
      console.error("Error editing project:", error);
    },
  });
};

// Hook to delete a project
export const useDeleteProject = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const deleteExistingProject = ({ projectId }) => {
    return new Promise((resolve, reject) => {
      socket.emit("deleteProject", { projectId }, (response) => {
        if (response.status === "ok") {
          resolve(response.message);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: deleteExistingProject,
    mutationKey: "deleteProject",
    onSuccess: () => {
      customToast({
        title: "Project Deleted",
        description: `Project deleted successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to delete project.",
        status: "error",
      });
      console.error("Error deleting project:", error);
    },
  });
};

// Hook to toggle archive status of a project
export const useToggleArchiveProject = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const toggleArchiveStatus = ({ projectId }) => {
    return new Promise((resolve, reject) => {
      socket.emit("toggleArchiveProject", { projectId }, (response) => {
        if (response.status === "ok") {
          resolve(response.project);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: toggleArchiveStatus,
    mutationKey: "toggleArchiveProject",
    onSuccess: () => {
      customToast({
        title: "Project Archive Status Toggled",
        description: `Project archive status updated successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to toggle archive status.",
        status: "error",
      });
      console.error("Error toggling archive status:", error);
    },
  });
};

// Hook to create a new task
export const useCreateTask = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const createNewTask = ({ projectId, taskData }) => {
    return new Promise((resolve, reject) => {
      socket.emit("createTask", { projectId, ...taskData }, (response) => {
        if (response.status === "ok") {
          resolve(response.task);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: createNewTask,
    mutationKey: "createTask",
    onSuccess: () => {
      customToast({
        title: "Task Created",
        description: `Task created successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to create task.",
        status: "error",
      });
      console.error("Error creating task:", error);
    },
  });
};

export const useEditTask = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const editExistingTask = ({ taskId, updates }) => {
    return new Promise((resolve, reject) => {
      socket.emit("editTask", { taskId, updates }, (response) => {
        if (response.status === "ok") {
          resolve(response.task);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: editExistingTask,
    mutationKey: "editTask",
    onSuccess: () => {
      // Note: The real-time update will be handled via the 'taskUpdated' event
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to edit task.",
        status: "error",
      });
      console.error("Error editing task:", error);
    },
  });
};

// Hook to delete a task
export const useDeleteTask = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const deleteExistingTask = ({ taskId }) => {
    return new Promise((resolve, reject) => {
      socket.emit("deleteTask", { taskId }, (response) => {
        if (response.status === "ok") {
          resolve(response.message);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: deleteExistingTask,
    mutationKey: "deleteTask",
    onSuccess: () => {
      customToast({
        title: "Task Deleted",
        description: `Task deleted successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to delete task.",
        status: "error",
      });
      console.error("Error deleting task:", error);
    },
  });
};

// Hook to assign users to a task
export const useAssignUsersToTask = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const assignUsersToTask = ({ taskId, userIds }) => {
    return new Promise((resolve, reject) => {
      socket.emit("assignUsersToTask", { taskId, userIds }, (response) => {
        if (response.status === "ok") {
          resolve(response.task);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: assignUsersToTask,
    mutationKey: "assignUsersToTask",
    onSuccess: () => {
      customToast({
        title: "Users Assigned",
        description: `Users assigned to task successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to assign users to task.",
        status: "error",
      });
      console.error("Error assigning users to task:", error);
    },
  });
};

// Hook to add a comment to a task
export const useAddComment = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const addCommentToTask = ({ taskId, commentText }) => {
    return new Promise((resolve, reject) => {
      const payload = { taskId, commentText };
      socket.emit("addCommentToTask", payload, (response) => {
        if (response.status === "ok") {
          resolve(response.comment);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: addCommentToTask,
    mutationKey: "addCommentToTask",
    onSuccess: () => {
      customToast({
        title: "Comment Added",
        description: `Your comment has been added successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to add comment.",
        status: "error",
      });
      console.error("Error adding comment:", error);
    },
  });
};

// Hook to edit a comment in a task
export const useEditComment = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const editCommentInTask = ({ taskId, commentId, editedCommentText }) => {
    return new Promise((resolve, reject) => {
      const payload = { taskId, commentId, editedCommentText };
      socket.emit("editCommentInTask", payload, (response) => {
        if (response.status === "ok") {
          resolve(response.message);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: editCommentInTask,
    mutationKey: "editCommentInTask",
    onSuccess: () => {
      customToast({
        title: "Comment Edited",
        description: `Your comment has been edited successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to edit comment.",
        status: "error",
      });
      console.error("Error editing comment:", error);
    },
  });
};

// Hook to delete a comment from a task
export const useDeleteComment = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const deleteCommentFromTask = ({ taskId, commentId }) => {
    return new Promise((resolve, reject) => {
      const payload = { taskId, commentId };
      console.log("payload", payload);
      socket.emit("deleteCommentFromTask", payload, (response) => {
        if (response.status === "ok") {
          resolve(response.message);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: deleteCommentFromTask,
    mutationKey: "deleteCommentFromTask",
    onSuccess: () => {
      customToast({
        title: "Comment Deleted",
        description: `Your comment has been deleted successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to delete comment.",
        status: "error",
      });
      console.error("Error deleting comment:", error);
    },
  });
};

// like comment in a task

export const useLikeComment = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const likeCommentInTask = ({ taskId, commentId }) => {
    return new Promise((resolve, reject) => {
      const payload = { taskId, commentId };
      socket.emit("likeCommentInTask", payload, (response) => {
        if (response.status === "ok") {
          resolve(response.comment);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: likeCommentInTask,
    mutationKey: "likeCommentInTask",
    onSuccess: () => {
      customToast({
        title: "Comment Liked",
        description: `Your comment has been liked successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to like comment.",
        status: "error",
      });
      console.error("Error liking comment:", error);
    },
  });
};

// Hook to log time to a task
export const useLogTime = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const logTimeToTask = ({ taskId, timeSpent, description }) => {
    return new Promise((resolve, reject) => {
      socket.emit("logTimeToTask", { taskId, timeSpent, description }, (response) => {
        if (response.status === "ok") {
          resolve(response.timeLog);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: logTimeToTask,
    mutationKey: "logTimeToTask",
    onSuccess: () => {
      customToast({
        title: "Time Logged",
        description: `Time logged successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to log time.",
        status: "error",
      });
      console.error("Error logging time:", error);
    },
  });
};

// Hook to edit a time log in a task
export const useEditTimeLog = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const editTimeLogInTask = ({ taskId, logId, timeSpent, description }) => {
    return new Promise((resolve, reject) => {
      socket.emit("editTimeLogInTask", { taskId, logId, timeSpent, description }, (response) => {
        if (response.status === "ok") {
          resolve(response.timeLog);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: editTimeLogInTask,
    mutationKey: "editTimeLogInTask",
    onSuccess: () => {
      customToast({
        title: "Time Log Updated",
        description: `Time log updated successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to edit time log.",
        status: "error",
      });
      console.error("Error editing time log:", error);
    },
  });
};

// Hook to delete a time log from a task
export const useDeleteTimeLog = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const deleteTimeLogFromTask = ({ taskId, logId }) => {
    return new Promise((resolve, reject) => {
      socket.emit("deleteTimeLogFromTask", { taskId, logId }, (response) => {
        if (response.status === "ok") {
          resolve(response.message);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: deleteTimeLogFromTask,
    mutationKey: "deleteTimeLogFromTask",
    onSuccess: () => {
      customToast({
        title: "Time Log Deleted",
        description: `Time log deleted successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to delete time log.",
        status: "error",
      });
      console.error("Error deleting time log:", error);
    },
  });
};

// Hook to assign members to a project
export const useAssignMembers = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const assignMembersToProject = ({ projectId, memberIds }) => {
    return new Promise((resolve, reject) => {
      socket.emit("assignMembersToProject", { projectId, memberIds }, (response) => {
        if (response.status === "ok") {
          resolve(response.project);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: assignMembersToProject,
    mutationKey: "assignMembersToProject",
    onSuccess: () => {
      customToast({
        title: "Members Assigned",
        description: `Members assigned to project successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to assign members.",
        status: "error",
      });
      console.error("Error assigning members:", error);
    },
  });
};

export const useUpdateTaskOrder = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const updateOrder = (tasks) => {
    return new Promise((resolve, reject) => {
      socket.emit("updateTaskOrder", { tasks }, (response) => {
        if (response.status === "ok") {
          resolve(response.message);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: updateOrder,
    mutationKey: "updateTaskOrder",
    onSuccess: () => {
      customToast({
        title: "Task Order Updated",
        description: "The order of tasks has been updated successfully.",
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Update Failed",
        description: error.message || "Failed to update task order.",
        status: "error",
      });
      console.error("Error updating task order:", error);
    },
  });
};
