// client/src/components/Files/ImportProgressModal.jsx

import React, { useContext } from "react";
import { Progress, Text, VStack } from "@chakra-ui/react";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext"; // Imported WorkspaceContext

const ImportProgressModal = ({ isOpen, onClose, progress, statusMessage }) => {
  const { themeColor } = useContext(WorkspaceContext); // Accessed themeColor

  const footerButtons = <></>;

  return (
    <ReusableModal footerButtons={footerButtons} isOpen={isOpen} onClose={onClose} title="Importing Folder" size="md" isCentered>
      <VStack spacing={4} align="stretch">
        <Progress isAnimated value={progress} size="lg" colorScheme={themeColor} />
        <Text fontSize="md">{progress}% completed</Text>
        <Text fontSize="md">{statusMessage}</Text>
      </VStack>
    </ReusableModal>
  );
};

export default ImportProgressModal;
